import React from 'react'

import { IconWeb } from '../../../assets/images/componentsSvg/IconWeb'
import { useAllSiteMetadata } from '@legal/shared/hooks/useAllSiteMetadata'

export const TopBarContent: React.FC = () => {
  const { sitePhone, siteSchedule } = useAllSiteMetadata()
  return (
    <p>
      Contact us whenever you need it! &emsp;
      <strong>
        <IconWeb />
        {sitePhone}
        &emsp;
      </strong>
      {siteSchedule}
    </p>
  )
}
