import React from 'react'

import { CustomerPayment } from '../CustomerPayment'

export const useCustomerPaymentYears = (invoices: CustomerPayment[], currentYear: number) => {
  const initYears = (): {
    label: number
    value: number
  }[] => {
    if (!invoices.length) return []
    const firstInvoiceYear = new Date(invoices[0].paidAt).getFullYear()
    const years = []
    for (let year = firstInvoiceYear; year <= currentYear; year++) {
      years.push({ label: year, value: year })
    }
    return years
  }
  const [years, setYears] = React.useState(initYears())

  React.useEffect(() => {
    setYears(initYears())
  }, [invoices, currentYear])

  return { years }
}
