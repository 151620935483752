import { type Customer } from '../../domain'
import { type CustomerData } from '../dataObject'
import { CustomerDataToCustomer } from '../adapters/CustomerDataToCustomer'
import { ENDPOINTS } from './Endpoints'
import { get } from '@legal/shared/HttpClient'

export async function getCustomer(): Promise<Customer> {
  const result = await get<CustomerData>(ENDPOINTS.CUSTOMER, true)
  return CustomerDataToCustomer(result)
}
