export const ENDPOINTS = {
  CREATE_APPLICATION: 'application',
  DELETE_APPLICATION: (applicationId: string) => `application/${applicationId}`,
  DOWNLOAD_APPLICATION_PDF: (applicationId: string) => `application/${applicationId}/pdf`,
  DUPLICATE_APPLICATION: (applicationId: string) => `application/${applicationId}/duplicate`,
  FIND_APPLICATION: (applicationId: string) => `application/${applicationId}`,
  GET_APPLICATIONS: 'customer/applications',
  GET_APPLICATION_SKU: (applicationId: string) => `application/${applicationId}/sku`,
  GET_APPLICATION_STATUS: (applicationId: string) => `application/${applicationId}/status`,
  GET_HTML_PREVIEW: (applicationId: string) => `application/${applicationId}/html_preview`,
  GET_INFO_DATALAYER: (applicationId: string) => `application/${applicationId}/datalayer`,
  UPDATE_APPLICATION_DATA: (applicationId: string) => `application/${applicationId}/data`,
  UPDATE_APPLICATION_FORM: (applicationId: string) => `application/${applicationId}/form`,
  UPDATE_APPLICATION_NAME: (applicationId: string) => `application/${applicationId}/name`
}
