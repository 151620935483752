import { ENDPOINTS } from './Endpoints'
import { PricingPlan } from '../../../../services/constants/pricingTest'
import { type Subscription } from '../../domain'
import { type SubscriptionData } from '../dataObjects'
import { SubscriptionDataToSubscription } from '../adapters'
import { get } from '@legal/shared/HttpClient'
import { pricingId } from '@legal/shared/data/constants/pricingId'

export async function findSubscriptionTypeByCode(tokenRequired: boolean): Promise<Subscription[]> {
  const subscriptionsCode = pricingId.default

  const subscriptionsData = await get<SubscriptionData[]>(
    ENDPOINTS.FIND_SUBSCRIPTTION_TYPE_BY_CODE(subscriptionsCode),
    tokenRequired
  )
  const data = subscriptionsData.map(SubscriptionDataToSubscription)

  const orderedPlans = data.sort((a, b) => {
    const order = [
      PricingPlan.sevenDayAccess,
      PricingPlan.month,
      PricingPlan.weekly,
      PricingPlan.year,
      PricingPlan.monthPro
    ]
    return order.indexOf(a.type.name) - order.indexOf(b.type.name)
  })

  return orderedPlans
}
