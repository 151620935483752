import { logError, logInfo } from '@legal/shared/logger'
import { CustomerRepositoryApi } from '../infra'

export async function getGeoLocationUseCase({
  successCallback,
  errorCallback,
  finallyCallback
}: {
  successCallback: (countryCode?: string) => void
  errorCallback?: (error: unknown) => void
  finallyCallback?: () => void
}): Promise<void> {
  await CustomerRepositoryApi.getGeoLocation()
    .then((countryCode) => {
      logInfo('GetGeoLocationUseCase')
      successCallback(countryCode)
    })
    .catch((error: Error | undefined) => {
      logError('GetGeoLocationUseCase', undefined, error)
      errorCallback?.(error)
    })
    .finally(() => {
      finallyCallback?.()
    })
}
