import { CustomerBillingData } from '../dataObject'
import { CustomerPayment } from '../../domain'

export function CustomerBillingDataToCustomerPayments(data: CustomerBillingData): CustomerPayment[] {
  return data.payments.map((payment) => ({
    id: payment.id,
    paidAt: new Date(payment.paidAt),
    amount: payment.amount,
    subscriptionTypeName: payment.subscriptionTypeName
  }))
}
