import { logError, logInfo } from '@legal/shared/logger'
import { type ApiError } from '@legal/shared/HttpClient'
import { Customer } from '../domain'
import { CustomerRepositoryApi } from '../infra'
import { UpdateCustomerRequest } from './interfaces'

export async function updateCustomerUseCase({
  successCallback,
  errorCallback,
  finallyCallback,
  request
}: {
  successCallback: (newToken: string) => void
  errorCallback?: (error: ApiError) => void
  finallyCallback?: () => void
  request: UpdateCustomerRequest
}): Promise<void> {
  const customer: Partial<Customer> = {
    firstName: request.firstName,
    lastName: request.lastName,
    email: request.email,
    password: request.password,
    company: {
      sector: request.companySector,
      size: request.companySize
    },
    type: request.type
  }
  await CustomerRepositoryApi.update(customer)
    .then((newToken) => {
      logInfo('UpdateCustomerUseCase')
      successCallback(newToken)
    })
    .catch((error: ApiError) => {
      logError(`UpdateCustomerUseCase: ${error?.message}`)
      errorCallback?.(error)
    })
    .finally(() => {
      finallyCallback?.()
    })
}
