/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useState } from 'react'
import classnames from 'classnames'

import { Button } from '@npm_leadtech/legal-lib-components/Button'
import { MenuItems } from '@npm_leadtech/legal-lib-components/MenuItems'
import { type PrivateAreaContentProps } from './PrivateAreaContentProps'
import { Tooltip } from '@npm_leadtech/legal-lib-components/Tooltip'
import { TopBar } from '@npm_leadtech/legal-lib-components/TopBar'
import { TopBarMobileContent } from '../TopBarMobileContent'
import { useConfigs } from '@legal/core/configs'
import { useIsMobile } from '@legal/shared/hooks'

import AddDoc from '../../../assets/images/componentsSvg/newdoc-24p-menu.svg'
import NewDoc from '../../../assets/images/componentsSvg/newdoc-24-p.svg'
import RatafiaIcon from '../../../assets/images/componentsSvg/ratafia-24px.svg'
import Upload from '../../../assets/images/componentsSvg/upload-doc.svg'

import './PrivateAreaContent.scss'

export const PrivateAreaContent: React.FC<PrivateAreaContentProps> = ({
  children,
  customClass,
  title,
  wide = false,
  activeMenuOption,
  applicationsLoaded,
  documentUploadFileDialog,
  uploadFileProcess,
  ratafiaClicked,
  myDocumentsPageData,
  newDocumentModal
}): React.ReactElement => {
  const [dragStatus, setDragStatus] = React.useState('')
  const { IS_JONSNOW, PA_UPLOAD_DOCUMENT_ENABLED } = useConfigs()

  const isMobile = useIsMobile(0)
  const bodyClasses = classnames('private-area-content__body', {
    'private-area-content__body--wide': wide,
    'private-area-content__body--regular': !wide
  })

  const [isTooltipVisible, setTooltipIsVisible] = useState(false)

  const actionButtonOptions = []

  actionButtonOptions.push({
    content: myDocumentsPageData?.newDocumentMobileButtonText ?? '',
    image: <NewDoc />,
    onClick: () => {
      newDocumentModal?.changeModalState()
      setTooltipIsVisible(false)
    },
    className: 'new-document-action-button',
    dataQa: 'new_document_action_button'
  })

  actionButtonOptions.push({
    content: myDocumentsPageData?.uploadMobileButtonText ?? '',
    image: <Upload />,
    onClick: () => {
      documentUploadFileDialog?.()
      setTooltipIsVisible(false)
    },
    className: 'upload-document-action-button',
    dataQa: 'upload_document_action_button'
  })

  if (ratafiaClicked !== undefined) {
    actionButtonOptions.push({
      content: myDocumentsPageData?.lawGeniusButtonText ?? '',
      image: <RatafiaIcon />,
      onClick: () => {
        ratafiaClicked()
        setTooltipIsVisible(false)
      },
      className: 'ratafia-action-button',
      dataQa: 'ratafia_action_button'
    })
  }

  const tooltipActionMenu = <MenuItems list={actionButtonOptions}></MenuItems>

  const handleDrop = React.useCallback((event) => {
    if (!PA_UPLOAD_DOCUMENT_ENABLED || activeMenuOption !== 0) return
    setDragStatus('')
    event.preventDefault()
    const droppedFiles = event.dataTransfer.files
    if (droppedFiles.length) {
      const file = droppedFiles[0]
      uploadFileProcess(file)
    }
  }, [])

  const handleDragOver = React.useCallback((event) => {
    if (!PA_UPLOAD_DOCUMENT_ENABLED || activeMenuOption !== 0) return
    setDragStatus('dragging')
    event.preventDefault()
  }, [])

  const handleDragOut = React.useCallback((event) => {
    if (!PA_UPLOAD_DOCUMENT_ENABLED || activeMenuOption !== 0) return
    setDragStatus('')
  }, [])

  return (
    <div
      className={`private-area-content ${customClass ?? ''} ${dragStatus}`}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragLeave={handleDragOut}
    >
      {!IS_JONSNOW && isMobile && (
        <TopBar>
          <TopBarMobileContent />
        </TopBar>
      )}
      <div className='private-area-content__header'>
        {title ?
          <h1 className='private-area-content__title'>{title}</h1>
        : null}
        {PA_UPLOAD_DOCUMENT_ENABLED && !isMobile && applicationsLoaded && activeMenuOption === 0 ?
          <div className='private-area-content__header-actions'>
            {ratafiaClicked !== undefined && (
              <Button
                noLink
                givenClass={'header-action ratafia-header-button'}
                onClick={ratafiaClicked}
                color='secondary'
                label={myDocumentsPageData?.lawGeniusButtonText ?? ''}
                dataQa='ratafia_header_button'
              >
                <RatafiaIcon />
              </Button>
            )}
            <Button
              noLink
              givenClass={'header-action upload-document-header-button'}
              onClick={documentUploadFileDialog}
              color='secondary'
              label={myDocumentsPageData?.uploadButtonText ?? ''}
              dataQa='upload_document_header_button'
            >
              <Upload />
            </Button>
            <Button
              noLink
              givenClass={'header-action new-document-header-button'}
              onClick={() => {
                newDocumentModal?.changeModalState()
              }}
              color='primary'
              label={myDocumentsPageData?.newDocumentButtonText ?? ''}
              dataQa='new_document_header_button'
            >
              <NewDoc />
            </Button>
            <Tooltip
              content={tooltipActionMenu}
              allowHTML={true}
              interactive={true}
              visible={isTooltipVisible}
              onClickOutside={() => setTooltipIsVisible(false)}
              position='bottom'
              trigger='click'
            >
              <div className='tooltip-action-menu'>
                <Button
                  noLink
                  givenClass={'header-action action-menu-header-button'}
                  onClick={() => {
                    setTooltipIsVisible(!isTooltipVisible)
                  }}
                  color='primary'
                  label={myDocumentsPageData?.actionButtonText ?? ''}
                  dataQa='action_menu_header_button'
                >
                  <AddDoc />
                </Button>
              </div>
            </Tooltip>
          </div>
        : null}
      </div>
      <div className={bodyClasses}>{children}</div>
    </div>
  )
}
