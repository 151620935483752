import { isCardExpired, isCardExpiringThisMonth } from '@legal/creditCard'
import { CustomerBillingData } from '../dataObject'
import { CustomerCard } from '../../domain'

export function CustomerBillingDataToCustomerCard(data: CustomerBillingData): CustomerCard {
  return {
    createdAt: new Date(data.card.createdAt),
    expirationYear: data.card.expirationYear,
    expirationMonth: data.card.expirationMonth,
    isExpired: isCardExpired(data.card.expirationMonth, data.card.expirationYear),
    isExpirationMonth: isCardExpiringThisMonth(data.card.expirationMonth, data.card.expirationYear),
    updateUrl: data.card.updateUrl ?? undefined,
    number: data.card.number,
    brand: data.card.brand
  }
}
