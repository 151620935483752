import {
  AMPLITUDE_TEST_AB_VERSION,
  sendAmplitudeData,
  setAmplitudeUserId,
  setAmplitudeUserProperties
} from '@legal/shared/amplitude'
import { type CustomerAuthGoogleRequest, type CustomerAuthGoogleResponse } from './interfaces'
import { logError, logInfo } from '@legal/shared/logger'
import { CustomerRepositoryApi } from '../infra'
import { GoogleRedirectCookie } from '../../../services/storage/cookies/GoogleRedirectCookie'
import { UserCookie } from '../../../services/storage/cookies/UserCookie'

export async function customerAuthGoogleUseCase({
  successCallback,
  errorCallback,
  finallyCallback,
  request
}: {
  successCallback?: (response: CustomerAuthGoogleResponse) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorCallback?: (error: any) => void
  finallyCallback?: () => void
  request: CustomerAuthGoogleRequest
}): Promise<void> {
  const user = new UserCookie()
  if (user.token) {
    logError('CustomerAuthGoogleUseCase', { error: 'User already authenticated' })
    return
  }
  if (request.redirectUrl) {
    const googleRedirectCookie = new GoogleRedirectCookie()
    googleRedirectCookie.googleRedirect = request.redirectUrl
  }
  await CustomerRepositoryApi.authGoogle({
    code: request.code,
    state: request.state,
    applicationId: request.applicationId,
    subscriptionId: request.subscriptionId
  })
    .then((response) => {
      if (response.authorizationUrl) sendAmplitudeLoginGoogleData(response.authorizationUrl, request.formName)
      if (response.customerId) setAmplitudeUserId(response.customerId)
      logInfo('CustomerAuthGoogleUseCase')
      successCallback?.(response)
    })

    .catch((error: Error | undefined) => {
      logError('CustomerAuthGoogleUseCase', undefined, error)
      errorCallback?.(error)
    })
    .finally(() => {
      finallyCallback?.()
    })
}

const sendAmplitudeLoginGoogleData = (authorizationUrl: string, formName?: string): void => {
  const eventProps = {
    login_type: 'google',
    initial_document_type: formName ?? null,
    version: AMPLITUDE_TEST_AB_VERSION.PRODUCTION
  }
  sendAmplitudeData('complete_registration', eventProps)
  setAmplitudeUserProperties('login_type', 'google')
  if (typeof window === 'undefined') return
  window.location.replace(authorizationUrl)
}
