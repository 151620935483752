import { graphql, useStaticQuery } from 'gatsby'

export const useQueryChatContent = (): Queries.STRAPI_CHAT_CONTENT => {
  const data = useStaticQuery(graphql`
    query strapiChatContent {
      strapiChatContent {
        businessHours {
          dayOfWeek
          from {
            hour
            minute
          }
          to {
            hour
            minute
          }
        }
        chatContent {
          heading
          headersChat
          configActions
          submitLabel
          textBanner
          inputEmail {
            label
            errorMessages {
              error
            }
          }
          inputName {
            label
            errorMessages {
              error
            }
          }
        }
      }
    }
  `)
  return data.strapiChatContent as Queries.STRAPI_CHAT_CONTENT
}
