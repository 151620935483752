import React from 'react'

import { ActiveSubscriptionLastStep } from '../ActiveSubscriptionLastStep'
import { type LastStepRendererProps } from './LastStepRendererProps'
import { PrintAndDownload } from '../../molecules'
import { replaceValues } from 'src/services/utils/replaceAll'

export const LastStepRenderer: React.FC<LastStepRendererProps> = ({
  formContext,
  step,
  previousStepForm,
  getDataFromStep,
  applicationId,
  template,
  formType,
  formSubType,
  draftActive,
  benefitsListToShow,
  changeWindowsLocation,
  loadingDataToPaymentPage,
  version,
  callbackBottomOverlay,
  printAndDownload
}) => {
  if (formContext.hasSubscriptionActive) {
    return (
      <ActiveSubscriptionLastStep
        step={step}
        previousStep={previousStepForm}
        getDataFromStep={getDataFromStep}
        applicationId={applicationId}
      />
    )
  }

  return (
    <PrintAndDownload
      title={replaceValues(printAndDownload?.item, { FORM_NAME: formContext.formName ?? '' }) ?? ''}
      template={template}
      formType={formType}
      formSubType={formSubType}
      draftActive={draftActive}
      benefitsList={benefitsListToShow}
      handleContinue={changeWindowsLocation}
      subscription={formContext.subscription}
      loading={formContext.loadingSubscription}
      loadingDataToPaymentPage={loadingDataToPaymentPage}
      version={version}
      callbackBottomOverlay={callbackBottomOverlay}
    />
  )
}
