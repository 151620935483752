import { useConfigs } from '@legal/core/configs'
import { useQueryAllSiteMetadata } from '../data/graphql/queries/useQueryAllSiteMetadata'
import { useQueryCommonsText } from '../data/graphql/queries/useQueryCommonsText'

export interface SiteMetadataInfoItemTextOrImage {
  text: string
  activeText: boolean
  image: string
}

export interface SiteMetadataInfo {
  siteName: string
  SITE_NAME: string
  web_name: string
  title: string
  siteUrl: string
  monthYear: string
  siteEmail: string
  sitePhone: string
  site_phone: string
  SITE_PHONE: string
  siteSchedule: string
  siteScheduleMobile: string
  subscriptionType1: string
  priceSubscriptionType1: string
  subscriptionType2: string
  priceSubscriptionType2: string
  subscriptionType3: string
  priceSubscriptionType3: string
  subscriptionPeriodDays: string
  morRegistry: string
  initialPeriodFee: string
  subscriptionInitialTrialPeriodDays: string
  companyName: SiteMetadataInfoItemTextOrImage
  site_company: SiteMetadataInfoItemTextOrImage
  companyNameTwo: SiteMetadataInfoItemTextOrImage
  site_company_two: SiteMetadataInfoItemTextOrImage
  companyNameThree: SiteMetadataInfoItemTextOrImage
  site_company_three: SiteMetadataInfoItemTextOrImage
  siteCif: SiteMetadataInfoItemTextOrImage
  site_cif: SiteMetadataInfoItemTextOrImage
  siteEinTwo: SiteMetadataInfoItemTextOrImage
  site_ein_two: SiteMetadataInfoItemTextOrImage
  siteEinThree: SiteMetadataInfoItemTextOrImage
  site_ein_three: SiteMetadataInfoItemTextOrImage
  siteAddress: SiteMetadataInfoItemTextOrImage
  site_address: SiteMetadataInfoItemTextOrImage
  siteAddressTwo: SiteMetadataInfoItemTextOrImage
  site_address_two: SiteMetadataInfoItemTextOrImage
  siteAddressTwoFirstPart: SiteMetadataInfoItemTextOrImage
  siteAddressTwoSecondPart: SiteMetadataInfoItemTextOrImage
  site_address_two_first_part: SiteMetadataInfoItemTextOrImage
  site_address_two_second_part: SiteMetadataInfoItemTextOrImage
  feeCurrency: string
}

export const useAllSiteMetadata = (): SiteMetadataInfo => {
  const {
    STRAPI_CONFIG: { DOMAIN }
  } = useConfigs()
  const allSiteMetadata = useQueryAllSiteMetadata()
  const strapiCommonsText = useQueryCommonsText()
  const findByDomainData = allSiteMetadata.find(
    ({ node }: { node: Queries.STRAPI_SITE_METADATA }) => node.domain?.name === DOMAIN
  )

  return {
    siteName: findByDomainData?.node.siteName ?? '',
    SITE_NAME: findByDomainData?.node.siteName ?? '',
    web_name: findByDomainData?.node.siteUrl ?? '',
    title: findByDomainData?.node.siteName ?? '',
    siteUrl: findByDomainData?.node.siteUrl ?? '',
    monthYear: findByDomainData?.node.monthYear ?? '',
    siteEmail: findByDomainData?.node.siteEmail ?? '',
    sitePhone: findByDomainData?.node.sitePhone ?? '',
    site_phone: findByDomainData?.node.sitePhone ?? '',
    SITE_PHONE: findByDomainData?.node.sitePhone ?? '',
    siteSchedule: findByDomainData?.node.siteSchedule ?? '',
    siteScheduleMobile: findByDomainData?.node.siteScheduleMobile ?? '',
    subscriptionType1: findByDomainData?.node.subscriptionType1 ?? '',
    priceSubscriptionType1: findByDomainData?.node.priceSubscriptionType1 ?? '',
    subscriptionType2: findByDomainData?.node.subscriptionType2 ?? '',
    priceSubscriptionType2: findByDomainData?.node.priceSubscriptionType2 ?? '',
    subscriptionType3: findByDomainData?.node.subscriptionType3 ?? '',
    priceSubscriptionType3: findByDomainData?.node.priceSubscriptionType3 ?? '',
    subscriptionPeriodDays: findByDomainData?.node.subscriptionPeriodDays ?? '',
    morRegistry: findByDomainData?.node.morRegistry ?? '',
    initialPeriodFee: findByDomainData?.node.initialPeriodFee ?? '',
    subscriptionInitialTrialPeriodDays: findByDomainData?.node.subscriptionInitialTrialPeriodDays ?? '',
    companyName: {
      text: findByDomainData?.node.companyName?.text ?? '',
      activeText: findByDomainData?.node.companyName?.activeText ?? true,
      image: findByDomainData?.node.companyName?.image?.localFile?.url ?? ''
    },
    site_company: {
      text: findByDomainData?.node.companyName?.text ?? '',
      activeText: findByDomainData?.node.companyName?.activeText ?? true,
      image: findByDomainData?.node.companyName?.image?.localFile?.url ?? ''
    },
    companyNameTwo: {
      text: findByDomainData?.node.companyNameTwo?.text ?? '',
      activeText: findByDomainData?.node.companyNameTwo?.activeText ?? true,
      image: findByDomainData?.node.companyNameTwo?.image?.localFile?.url ?? ''
    },
    site_company_two: {
      text: findByDomainData?.node.companyNameTwo?.text ?? '',
      activeText: findByDomainData?.node.companyNameTwo?.activeText ?? true,
      image: findByDomainData?.node.companyNameTwo?.image?.localFile?.url ?? ''
    },
    companyNameThree: {
      text: findByDomainData?.node.companyNameThree?.text ?? '',
      activeText: findByDomainData?.node.companyNameThree?.activeText ?? true,
      image: findByDomainData?.node.companyNameThree?.image?.localFile?.url ?? ''
    },
    site_company_three: {
      text: findByDomainData?.node.companyNameThree?.text ?? '',
      activeText: findByDomainData?.node.companyNameThree?.activeText ?? true,
      image: findByDomainData?.node.companyNameThree?.image?.localFile?.url ?? ''
    },
    siteCif: {
      text: findByDomainData?.node.siteCif?.text ?? '',
      activeText: findByDomainData?.node.siteCif?.activeText ?? true,
      image: findByDomainData?.node.siteCif?.image?.localFile?.url ?? ''
    },
    site_cif: {
      text: findByDomainData?.node.siteCif?.text ?? '',
      activeText: findByDomainData?.node.siteCif?.activeText ?? true,
      image: findByDomainData?.node.siteCif?.image?.localFile?.url ?? ''
    },
    siteEinTwo: {
      text: findByDomainData?.node.siteEinTwo?.text ?? '',
      activeText: findByDomainData?.node.siteEinTwo?.activeText ?? true,
      image: findByDomainData?.node.siteEinTwo?.image?.localFile?.url ?? ''
    },
    site_ein_two: {
      text: findByDomainData?.node.siteEinTwo?.text ?? '',
      activeText: findByDomainData?.node.siteEinTwo?.activeText ?? true,
      image: findByDomainData?.node.siteEinTwo?.image?.localFile?.url ?? ''
    },
    siteEinThree: {
      text: findByDomainData?.node.siteEinThree?.text ?? '',
      activeText: findByDomainData?.node.siteEinThree?.activeText ?? true,
      image: findByDomainData?.node.siteEinThree?.image?.localFile?.url ?? ''
    },
    site_ein_three: {
      text: findByDomainData?.node.siteEinThree?.text ?? '',
      activeText: findByDomainData?.node.siteEinThree?.activeText ?? true,
      image: findByDomainData?.node.siteEinThree?.image?.localFile?.url ?? ''
    },
    siteAddress: {
      text: findByDomainData?.node.siteAddress?.text ?? '',
      activeText: findByDomainData?.node.siteAddress?.activeText ?? true,
      image: findByDomainData?.node.siteAddress?.image?.localFile?.url ?? ''
    },
    site_address: {
      text: findByDomainData?.node.siteAddress?.text ?? '',
      activeText: findByDomainData?.node.siteAddress?.activeText ?? true,
      image: findByDomainData?.node.siteAddress?.image?.localFile?.url ?? ''
    },
    siteAddressTwo: {
      text: findByDomainData?.node.siteAddressTwo?.text ?? '',
      activeText: findByDomainData?.node.siteAddressTwo?.activeText ?? true,
      image: findByDomainData?.node.siteAddressTwo?.image?.localFile?.url ?? ''
    },
    site_address_two: {
      text: findByDomainData?.node.siteAddressTwo?.text ?? '',
      activeText: findByDomainData?.node.siteAddressTwo?.activeText ?? true,
      image: findByDomainData?.node.siteAddressTwo?.image?.localFile?.url ?? ''
    },
    siteAddressTwoFirstPart: {
      text: findByDomainData?.node.siteAddressTwoFirstPart?.text ?? '',
      activeText: findByDomainData?.node.siteAddressTwoFirstPart?.activeText ?? true,
      image: findByDomainData?.node.siteAddressTwoFirstPart?.image?.localFile?.url ?? ''
    },
    site_address_two_first_part: {
      text: findByDomainData?.node.siteAddressTwoFirstPart?.text ?? '',
      activeText: findByDomainData?.node.siteAddressTwoFirstPart?.activeText ?? true,
      image: findByDomainData?.node.siteAddressTwoFirstPart?.image?.localFile?.url ?? ''
    },
    siteAddressTwoSecondPart: {
      text: findByDomainData?.node.siteAddressTwoSecondPart?.text ?? '',
      activeText: findByDomainData?.node.siteAddressTwoSecondPart?.activeText ?? true,
      image: findByDomainData?.node.siteAddressTwoSecondPart?.image?.localFile?.url ?? ''
    },
    site_address_two_second_part: {
      text: findByDomainData?.node.siteAddressTwoSecondPart?.text ?? '',
      activeText: findByDomainData?.node.siteAddressTwoSecondPart?.activeText ?? true,
      image: findByDomainData?.node.siteAddressTwoSecondPart?.image?.localFile?.url ?? ''
    },
    feeCurrency: strapiCommonsText.currencyCode
  }
}
