/* eslint-disable @typescript-eslint/no-explicit-any */

import { Button } from '@npm_leadtech/legal-lib-components/Button'
import React from 'react'
import { Spinner } from '@npm_leadtech/legal-lib-components/Spinner'
import { TextInput } from '@npm_leadtech/legal-lib-components/TextInput'
import { navigate } from 'gatsby'

import { FormatHTMLAstChildren } from '../FormatHTMLAstChildren'
import { UserCookie } from '../../../services/storage/cookies/UserCookie'
import { updateAutoGeneratedPasswordUseCase } from '@legal/customer'
import { useApplicationCookie } from '@legal/shared/hooks'
import { useQuerySharedComponentsFromPaymentPage } from '@legal/shared/data/graphql/queries/useQuerySharedComponentFromPaymentPage'
import { useToastsCustom } from '../../../services/hooks/useToastsCustom'

interface CreatePasswordProps {
  createPasswordData: Record<string, any>
  isRatafiaPage: boolean
}

export const CreatePasswordForm: React.FC<CreatePasswordProps> = ({ createPasswordData, isRatafiaPage = false }) => {
  const [loadingCallApi, setLoadingCallApi] = React.useState(false)
  const { signUp } = useQuerySharedComponentsFromPaymentPage()
  const { addToastCustom } = useToastsCustom()
  const { applicationCookie } = useApplicationCookie()

  const [formData, setFormData] = React.useState({
    password: {
      value: '',
      isValid: true,
      error: createPasswordData.minCharactersError
    },
    confirmPassword: {
      value: '',
      isValid: true,
      error: createPasswordData.passwordMatchError
    }
  })

  const onChange = (e): void => {
    const target = e.target
    const value = target.value
    const name = target.name
    const tempForm = { ...formData }

    tempForm[name].value = value

    setFormData(tempForm)
  }

  const resetErrors = (e): void => {
    const target = e.target
    const name = target.name
    const tempForm = { ...formData }

    tempForm[name].isValid = true
    setFormData(tempForm)
  }

  const submitForm = async (e): Promise<void> => {
    e.preventDefault()
    setLoadingCallApi(true)
    if (!loadingCallApi) {
      const isValid = validate()

      if (isValid) {
        const userCookie = new UserCookie()

        await updateAutoGeneratedPasswordUseCase({
          request: {
            password: formData.password.value,
            isRatafiaPage,
            formName: applicationCookie?.form?.name
          },
          successCallback: (newToken) => {
            userCookie.token = newToken
            navigate('/document-actions/')
          },
          errorCallback: () => {
            setLoadingCallApi(false)
            addToastCustom({
              title: createPasswordData.changePasswordErrorToast.title,
              autoDismiss: true,
              type: createPasswordData.changePasswordErrorToast.type,
              text: createPasswordData.changePasswordErrorToast.text
            })
          }
        })
      } else {
        setLoadingCallApi(false)
      }
    }
  }
  const validate = (): boolean => {
    let validData = true
    const tempForm = { ...formData }
    if (tempForm.password.value.length < 8) {
      tempForm.password.isValid = false
      setFormData(tempForm)
      validData = false
    }
    if (tempForm.confirmPassword.value.length < 8) {
      // eslint-disable-next-line react-compiler/react-compiler
      tempForm.confirmPassword.isValid = false
      tempForm.confirmPassword.error = createPasswordData.minCharactersError
      setFormData(tempForm)
      validData = false
    } else {
      if (tempForm.password.value !== tempForm.confirmPassword.value) {
        tempForm.confirmPassword.isValid = false
        tempForm.confirmPassword.error = createPasswordData.passwordMatchError
        setFormData(tempForm)
        validData = false
      }
    }
    return validData
  }

  return (
    <>
      <form onSubmit={submitForm}>
        <TextInput
          type={'password'}
          placeholder={createPasswordData.passwordInputField.placeholder}
          maxLength={'16'}
          value={formData.password.value}
          name={'password'}
          label={createPasswordData.passwordInputField.label}
          validate={formData.password.isValid}
          errorMessage={formData.password.error}
          required={false}
          isValidGroup={true}
          onChange={onChange}
          onClick={resetErrors}
        />
        <TextInput
          type={'password'}
          placeholder={''}
          maxLength={'16'}
          value={formData.confirmPassword.value}
          name={'confirmPassword'}
          label={createPasswordData.repeatPasswordInputField.label}
          validate={formData.confirmPassword.isValid}
          errorMessage={formData.confirmPassword.error}
          required={false}
          isValidGroup={true}
          onChange={onChange}
          onClick={resetErrors}
        />
        <div className='box--form__body__footer'>
          <Button
            primary
            label={createPasswordData.createAccountText}
            givenClass='box--form__button--main'
            noLinkNoFunc
            dataQa={'createAccount'}
          />
          {loadingCallApi && (
            <div className='spinner-container'>
              <Spinner className={'spinner--primary'} />
            </div>
          )}
          {signUp?.termsAndConditions?.data?.childMarkdownRemark?.htmlAst && (
            <FormatHTMLAstChildren
              htmlAstChildren={
                signUp.termsAndConditions.data.childMarkdownRemark.htmlAst.children as
                  | React.ReactNode
                  | React.ReactNode[]
              }
            />
          )}
        </div>
      </form>
    </>
  )
}
