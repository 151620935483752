import { CustomerBilling } from '../../domain'
import { CustomerBillingData } from '../dataObject'
import { CustomerBillingDataToCustomerBilling } from '../adapters/CustomerBillingDataToCustomerBilling'
import { ENDPOINTS } from './Endpoints'
import { get } from '@legal/shared/HttpClient'

export async function getCustomerBilling(): Promise<CustomerBilling> {
  const result = await get<CustomerBillingData>(ENDPOINTS.CUSTOMER_BILLING, true)
  return CustomerBillingDataToCustomerBilling(result)
}
