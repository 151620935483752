import { Accordion, useStateDataAccordionItem } from '@npm_leadtech/legal-lib-components/Accordion'
import React, { useCallback } from 'react'
import { CountDownMessage } from '@npm_leadtech/legal-lib-components/CountDownMessage'
import { Feedback } from '@npm_leadtech/legal-lib-components/Feedback'
import { Spinner } from '@npm_leadtech/legal-lib-components/Spinner'

import { EmailIformation, TextUnderPaymentForm } from '../../molecules'
import { PSPGateway } from '../PSPGateway'
import { PaymentCookie } from '../../../services/storage/cookies/PaymentCookie'
import { PaymentFormLoader } from '../PaymentFormLoader'
import { type PaymentGatewayProps } from './PaymentGatewayProps'

import { UserCookie } from '../../../services/storage/cookies/UserCookie'
import { useCountDown } from '../../../services/hooks/useCountDown'
import { useQuerySharedComponentsFromPaymentPage } from '@legal/shared/data/graphql'
import './PaymentGateway.scss'

import {
  PRICING_DEAL_PLAN_NAMES_TYPE,
  useQueryCommonsText
} from '@legal/shared/data/graphql/queries/useQueryCommonsText'
import { pricingPlanNamesTransformToJson } from '@legal/price'

const AccordionFirstItemRightContent = (): React.JSX.Element => {
  const { state } = useStateDataAccordionItem(0)
  return (
    <>
      {state.isLoading && (
        <div className='accordion-first-right-content'>
          <Spinner className='spinner--neutral' />
        </div>
      )}
      {state.message && <span className='message-first-right-item'>{state.message}</span>}
    </>
  )
}

export const PaymentGateway: React.FC<PaymentGatewayProps> = ({
  applicationId,
  htmlAstChildren,
  hasPaymentError = false,
  paymentGatewayData,
  paymentProvider,
  providersCustomMessages,
  pspGatewayData,
  subscriptionDisplayPrice,
  subscriptionTypeId,
  subscriptionTypeName,
  transactionInYourAccountAppearAs,
  trialSevenDays,
  validatorsI18n,
  timeoutModal,
  callbackUserCreate
}) => {
  const strapiCommonsText = useQueryCommonsText()
  const pricingPlanNames = pricingPlanNamesTransformToJson(strapiCommonsText.pricingDealPlanNames)

  const { subscriptions } = useQuerySharedComponentsFromPaymentPage()
  const time = useCountDown()
  const userCookie = new UserCookie()

  const resetPaymentForm = useCallback((): void => {
    const paymentCookie = new PaymentCookie()
    paymentCookie.clear()
    location.reload()
  }, [])

  const longBillingText =
    subscriptions?.find((sub) => sub?.sku?.toLocaleLowerCase() == subscriptionTypeName?.toLocaleLowerCase())
      ?.longBillingText ?? ''

  if (hasPaymentError) {
    const children = (
      <Feedback
        button={{
          label: paymentGatewayData?.feedBackError.btnLabel ?? '',
          onClick: resetPaymentForm,
          noLink: true,
          dataQa: 'btn-feedback-error'
        }}
        title={paymentGatewayData?.feedBackError.title}
        theme='error'
        text={paymentGatewayData?.feedBackError.text}
        large={false}
        fluid={false}
      />
    )
    return <PaymentFormLoader>{children}</PaymentFormLoader>
  }

  let defaultActiveIndex = userCookie.token ? 1 : 0
  const isMonthlyPlan = subscriptionTypeName === pricingPlanNames[PRICING_DEAL_PLAN_NAMES_TYPE.monthly].text
  defaultActiveIndex = isMonthlyPlan ? 0 : defaultActiveIndex
  const data = [
    {
      children: (
        <EmailIformation
          validatorsI18n={validatorsI18n}
          paymentGatewayData={paymentGatewayData}
          callbackUserCreate={callbackUserCreate}
        />
      ),
      title: paymentGatewayData?.accordionFirstElementTitle,
      accordionRightContent: <AccordionFirstItemRightContent />
    },
    {
      children: (
        <PSPGateway
          applicationId={applicationId}
          providersCustomMessages={providersCustomMessages}
          paymentProvider={paymentProvider}
          subscriptionTypeId={subscriptionTypeId}
          subscriptionTypeName={subscriptionTypeName}
          pspGatewayData={pspGatewayData}
          validatorsI18n={validatorsI18n}
          paymentGatewayData={paymentGatewayData}
          timeoutModal={timeoutModal}
        />
      ),
      title: paymentGatewayData?.accordionSecondElementTitle,
      accordionRightContent: <></>
    }
  ]

  if (isMonthlyPlan) {
    data.shift()
  }

  return (
    <>
      <div className='payment-gateway'>
        <CountDownMessage text={pspGatewayData?.countDownMessage} time={time} centered={true} />
        <Accordion data={data} defaultHeightItem={200} defaultActiveIndex={defaultActiveIndex} />
        <TextUnderPaymentForm
          htmlAstChildren={htmlAstChildren}
          subscriptionDisplayPrice={subscriptionDisplayPrice}
          subscriptionTypeName={subscriptionTypeName}
          transactionInYourAccountAppearAs={transactionInYourAccountAppearAs}
          trialSevenDays={trialSevenDays}
          longBillingText={longBillingText}
        />
      </div>
    </>
  )
}
