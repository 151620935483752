import { toDate } from '@legal/shared/utils'

export function isCardExpiringThisMonth(expirationMonth: string, expirationYear: string): boolean {
  if (!expirationMonth || !expirationYear) return false
  const month = parseInt(expirationMonth)
  const year = parseInt(expirationYear)
  const currentDate = toDate()
  const currentMonth = currentDate.getMonth() + 1
  const currentYear = currentDate.getFullYear()
  return currentYear === year && currentMonth === month
}
