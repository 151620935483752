import { SubscriptionStatus, SubscriptionTypeName } from '@legal/shared/data'
import { CustomerSubscription } from '../CustomerSubscription'

export function getActiveCustomerSubscriptions(subscriptions: CustomerSubscription[]): CustomerSubscription[] {
  return subscriptions.filter(
    (subscription) =>
      subscription.status === SubscriptionStatus.ACTIVE &&
      subscription.type.name !== SubscriptionTypeName.SINGLE_DOCUMENT
  )
}
