/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'

import { type Customer, getCustomerUseCase } from '@legal/customer'
import {
  getTransactionStatusNoAuthUseCase,
  getTransactionStatusUseCase,
  updateTransactionStatusUseCase
} from '@legal/transaction'
import { PaymentCookie } from '../storage/cookies/PaymentCookie'
import { ProductCookie } from '../storage/cookies/ProductCookie'
import { UserCookie } from '../storage/cookies/UserCookie'
import payTimers from '@legal/shared/data/json/payTimers.json'
import { useInterval } from './useInterval'
import { useIstestAB8814 } from './useIstestAB8814'

export const usePaymentProcessingLogic = (setTransactionStatusToPaying = true): string => {
  const [pollingCounter, setPollingCounter] = React.useState(0)
  const [tokens, setTokens] = React.useState({
    transactionId: '',
    userToken: ''
  })
  const [transactionStatus, setTransactionStatus] = React.useState('created')
  const userCookie = new UserCookie()
  const paymentCookie = new PaymentCookie()
  const productCookie = new ProductCookie()
  const [isTestAB8814, loadTestAB8814] = useIstestAB8814({
    product: productCookie.product
  })
  const getTransactionStatusErrorCallback = ({ status }: { status: number }): void => {
    if (status === 404) setTransactionStatus('error')
  }

  React.useEffect(
    function mountingComponent() {
      const fetchData = async () => {
        if (loadTestAB8814) {
          const tempTokens = { ...tokens }
          if (typeof window === 'undefined') return
          const transactionId = paymentCookie.transactionId
          tempTokens.userToken = userCookie.token ?? ''
          if (transactionId) tempTokens.transactionId = transactionId
          setTokens(tempTokens)
          if (!transactionId) return
          if (userCookie.token) {
            await getTransactionStatusUseCase({
              transactionId,
              successCallback: (status: string) => {
                setTransactionStatus(status)
                if (status === 'created' && setTransactionStatusToPaying && userCookie.token)
                  updateTransactionStatusInDatabase(transactionId)
              },
              errorCallback: getTransactionStatusErrorCallback
            })
          } else if (transactionId && isTestAB8814) {
            await getTransactionStatusNoAuthUseCase({
              transactionId,
              successCallback: (result) => {
                if (result.loginRequired && result.email) {
                  userCookie.email = result.email
                  setTransactionStatus('user-login')
                } else if (result.token) {
                  getCustomer(result.token, result.status)
                }
              },
              errorCallback: getTransactionStatusErrorCallback
            })
          }
        }
      }
      fetchData()
    },
    [loadTestAB8814]
  )

  useInterval(
    () => {
      if (pollingCounter < payTimers['number-of-tries']) {
        if (transactionStatus === 'paying' || transactionStatus === 'created') {
          checkTransactionStatus()
        }
      } else {
        setTransactionStatus('timeout')
      }
    },
    pollingCounter <= payTimers['number-of-tries'] ? payTimers['polling-delay'] : null
  )

  const updateTransactionStatusInDatabase = async (transactionId: string): Promise<void> => {
    await updateTransactionStatusUseCase({
      transactionId,
      successCallback: () => {
        setTransactionStatus('paying')
      },
      errorCallback: (e) => {
        setTransactionStatus('error')
      }
    })
  }

  async function getCustomer(token: string, status: string): Promise<void> {
    await getCustomerUseCase({
      successCallback: (customer) => {
        getCustomerSuccessCallback(token, status, customer)
      }
    })
  }

  const checkTransactionStatus = async (): Promise<void> => {
    if (!paymentCookie.transactionId) return
    if (isTestAB8814 && !tokens.userToken) {
      await getTransactionStatusNoAuthUseCase({
        transactionId: paymentCookie.transactionId,
        successCallback: (result) => {
          if (result.token) getCustomer(result.token, result.status)
          setPollingCounter(pollingCounter + 1)
        },
        errorCallback: getTransactionStatusErrorCallback
      })
      return
    }
    if (!tokens.transactionId || !tokens.userToken) return
    await getTransactionStatusUseCase({
      transactionId: paymentCookie.transactionId,
      successCallback: (status: string) => {
        setTransactionStatus(status)
        setPollingCounter(pollingCounter + 1)
      },
      errorCallback: getTransactionStatusErrorCallback
    })
  }

  const getCustomerSuccessCallback = (userToken: string, status: string, customer: Customer): void => {
    if (status === 'created' && setTransactionStatusToPaying) {
      const transactionId = paymentCookie.transactionId
      if (transactionId) updateTransactionStatusInDatabase(transactionId)
    }
    if (customer.isAutoGenerated) {
      userCookie.email = customer.email
      userCookie.token = userToken
      setTransactionStatus(status)
    } else {
      userCookie.email = customer.email
      setTransactionStatus('user-login')
    }
  }

  return transactionStatus
}
