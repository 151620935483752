import { type CreateCustomerRequest, type CreateCustomerResponse } from './interfaces'
import { logError, logInfo } from '@legal/shared/logger'
import { type ApiError } from '@legal/shared/HttpClient'
import { CustomerRepositoryApi } from '../infra'
import { UserCookie } from '../../../services/storage/cookies/UserCookie'
import { setAmplitudeUserId } from '@legal/shared/amplitude'

export async function createCustomerUseCase({
  successCallback,
  errorCallback,
  finallyCallback,
  request
}: {
  successCallback: (response: CreateCustomerResponse) => void
  errorCallback?: (error?: ApiError) => void
  finallyCallback?: () => void
  request: CreateCustomerRequest
}): Promise<void> {
  const user = new UserCookie()
  if (user.token) {
    logError('CreateCustomerUseCase', { error: 'User already authenticated' })
    return
  }
  await CustomerRepositoryApi.create(request)
    .then((response) => {
      response.isInProgress = response.status === 'in_progress'
      response.isNew = response.status === 'new'
      if (response.customerId) setAmplitudeUserId(response.customerId)
      logInfo('CreateCustomerUseCase')
      successCallback(response)
    })

    .catch((error: ApiError | undefined) => {
      logError(`CreateCustomerUseCase: ${error?.message}`)
      errorCallback?.(error)
    })
    .finally(() => {
      finallyCallback?.()
    })
}
