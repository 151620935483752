/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Children } from 'react'
import { navigate } from 'gatsby'

import { DocumentErrors, uploadDocumentUseCase } from '@legal/document'
import { type ModalProps, useModal } from '../../../services/hooks/useModal'
import { Loading } from '../../molecules'
import { NewDocumentModal } from '../../molecules/NewDocumentModal'
import { PrivateAreaContent } from '../PrivateAreaContent'
import { type PrivateAreaLayoutProps } from './PrivateAreaLayoutProps'
import { SideMenu } from '../../organisms'
import { SubscriptionCookie } from '../../../services/storage/cookies/SubscriptionCookie'
import { UserCookie } from '../../../services/storage/cookies/UserCookie'
import { useApplicationCookie } from '@legal/shared/hooks'
import { useToastsCustom } from '../../../services/hooks/useToastsCustom'
import './PrivateAreaLayout.scss'

export const PrivateAreaLayout: React.FC<PrivateAreaLayoutProps> = ({
  customClass,
  title,
  activeMenuOption,
  sideBarIdentifier,
  wide = false,
  applicationsLoaded,
  fetchUploadedDocuments,
  setUploadingFile,
  ratafiaClicked,
  data,
  children
}) => {
  const [loading, updateLoading] = React.useState<boolean>(true)
  const { clearApplicationCookie } = useApplicationCookie()
  const { addToastCustom } = useToastsCustom()
  const newDocumentModal: ModalProps = useModal()

  const userCookie = new UserCookie()
  const subscriptionCookie = new SubscriptionCookie()
  const myDocumentsPageData = data?.strapiMyDocumentsPage

  React.useEffect(() => {
    window.localStorage.setItem('isEditing', '')
    handleAuthentication()
  }, [])

  const handleAuthentication = (): void => {
    if (!userCookie.token) {
      clearApplicationCookie()
      subscriptionCookie.clear()
      navigate('/login')
    } else {
      updateLoading(false)
    }
  }

  const showWrongUpload = (): void => {
    addToastCustom({
      autoDismiss: false,
      type: 'error',
      text: myDocumentsPageData?.documentsSection?.uploadErrorToast?.text
    })
  }

  const showWrongUploadMimetype = (): void => {
    addToastCustom({
      autoDismiss: false,
      type: 'error',
      text: myDocumentsPageData?.documentsSection?.uploadWrongToast?.text
    })
  }

  const showWrongUploadSize = (): void => {
    addToastCustom({
      autoDismiss: false,
      type: 'error',
      text: 'File’s too large. Maximum size is 20MB.'
    })
  }

  const documentUploadFileDialog = (): void => {
    const input = document.createElement('input')
    input.type = 'file'
    input.accept = '.pdf,.doc,.docx'
    input.onchange = (e: Event) => {
      const file = e.target?.files[0]
      uploadFileProcess(file)
    }
    input.click()
  }

  const uploadFileProcess = async (file: File): Promise<void> => {
    setUploadingFile?.(file.name)
    await uploadDocumentUseCase({
      request: file,
      successCallback: () => {
        if (fetchUploadedDocuments) {
          fetchUploadedDocuments()
          return
        }
        navigate('/my-account/my-documents/#uploaded')
      },
      errorCallback: (error?: string) => {
        if (error === DocumentErrors.MAX_WEIGHT) showWrongUploadSize()
        if (error === DocumentErrors.ERROR_FORMAT) showWrongUploadMimetype()
        setUploadingFile?.('')
        showWrongUpload()
      }
    })
  }

  const ChildrenComponent = (): any[] => {
    const elements = []
    Children.forEach(children, (child, index) => {
      if (child) {
        elements.push(React.cloneElement(child, { modal: newDocumentModal, key: `child-${index}` }))
      }
    })
    return elements
  }

  const getChildren = ChildrenComponent()

  if (loading) return <Loading />

  return (
    <div className='private-area'>
      {newDocumentModal.modalOpen && <NewDocumentModal closeFunction={newDocumentModal.closeModal} />}
      <SideMenu
        activeMenuOption={activeMenuOption}
        newDocumentModal={newDocumentModal}
        sideBarIdentifier={sideBarIdentifier}
        documentUploadFileDialog={documentUploadFileDialog}
        ratafiaClicked={ratafiaClicked}
        data={data}
      />
      <PrivateAreaContent
        title={title}
        wide={wide}
        customClass={customClass}
        activeMenuOption={activeMenuOption}
        applicationsLoaded={applicationsLoaded}
        documentUploadFileDialog={documentUploadFileDialog}
        uploadFileProcess={uploadFileProcess}
        ratafiaClicked={ratafiaClicked}
        myDocumentsPageData={myDocumentsPageData}
        newDocumentModal={newDocumentModal}
      >
        {getChildren}
      </PrivateAreaContent>
    </div>
  )
}
