import { CreateCustomerWithSubscriptionRequest } from '../../application'
import { ENDPOINTS } from './Endpoints'
import { post } from '@legal/shared/HttpClient'

export async function createCustomerWithSubscription({
  email,
  subscriptionId,
  applicationId
}: CreateCustomerWithSubscriptionRequest): Promise<{ token: string; customerId: string }> {
  const body = { email, password: '', subscriptionId, applicationId }
  const result = await post<{
    token: string
    customerId: string
  }>(ENDPOINTS.CUSTOMER_WITH_SUBSCRIPTION, body)
  return { token: result.token, customerId: result.customerId }
}
