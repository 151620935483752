import { CustomerBillingData } from '../dataObject'
import { CustomerRefund } from '../../domain'

export function CustomerBillingDataToCustomerRefunds(data: CustomerBillingData): CustomerRefund[] {
  return data.refunds.map((refund) => ({
    id: refund.id,
    amount: refund.amount,
    paidAt: new Date(refund.paidAt),
    subscriptionTypeName: refund.subscriptionTypeName
  }))
}
