import React from 'react'

import { getCustomerBillingUseCase } from '@legal/customer'

export const useBilling = (): {
  loadingBilling: boolean
  hasSubscriptionActive: boolean
} => {
  const [hasSubscriptionActive, setHasSubscriptionActive] = React.useState<boolean>(false)
  const [loadingBilling, setLoadingBilling] = React.useState<boolean>(true)

  React.useEffect(() => {
    async function getBillingInfo() {
      await getCustomerBillingUseCase({
        successCallback: (customerBilling) => {
          setHasSubscriptionActive(customerBilling.hasActiveSubscription)
          setLoadingBilling(false)
        }
      })
    }
    getBillingInfo()
  }, [])

  return {
    loadingBilling,
    hasSubscriptionActive
  }
}
