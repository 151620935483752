import { BottomOverlay } from '@npm_leadtech/legal-lib-components/BottomOverlay'
import { MenuItems } from '@npm_leadtech/legal-lib-components/MenuItems'
import React from 'react'

import { type DocumentActionsNewMenuProps } from './DocumentActionsNewMenuProps'
import NewDoc from '../../../assets/images/componentsSvg/newdoc-24-p.svg'
import RatafiaIcon from '../../../assets/images/componentsSvg/ratafia-24px.svg'
import Upload from '../../../assets/images/componentsSvg/upload-doc.svg'
import { useConfigs } from '@legal/core/configs'

import '../DocumentActionsMenu/DocumentActionsMenu.scss'

export const DocumentActionsNewMenu: React.FC<DocumentActionsNewMenuProps> = ({
  newApplication,
  uploadDocument,
  openMenu,
  setOpenMenu,
  ratafiaClicked,
  sideMenuData
}) => {
  const { PA_UPLOAD_DOCUMENT_ENABLED, RATAFIA_CONFIG } = useConfigs()

  const bottomOverlayOptions = [
    {
      className: 'bottom-overlay-new-document',
      content: sideMenuData.newDocumentMobileButtonText,
      image: <NewDoc />,
      onClick: () => {
        newApplication?.()
        setOpenMenu(false)
      }
    }
  ]

  if (PA_UPLOAD_DOCUMENT_ENABLED) {
    bottomOverlayOptions.push({
      className: 'bottom-overlay-upload',
      content: sideMenuData.uploadMobileButtonText,
      image: <Upload />,
      onClick: () => {
        uploadDocument?.()
        setOpenMenu(false)
      }
    })
  }

  if (RATAFIA_CONFIG.ENABLED && ratafiaClicked) {
    bottomOverlayOptions.push({
      className: 'bottom-overlay-ratafia',
      content: sideMenuData.lawGeniusButtonText,
      image: <RatafiaIcon />,
      onClick: () => {
        ratafiaClicked?.()
        setOpenMenu(false)
      }
    })
  }

  const bottomOverlayBody = <MenuItems className={'overlay-list-my-docs'} list={bottomOverlayOptions} />

  return (
    <BottomOverlay
      open={openMenu}
      body={bottomOverlayBody}
      onClose={() => {
        setOpenMenu(false)
      }}
    ></BottomOverlay>
  )
}
