import { AMPLITUDE_TEST_AB_VERSION, sendAmplitudeData } from '@legal/shared/amplitude'
import { logError, logInfo } from '@legal/shared/logger'
import { type ApiError } from '@legal/shared/HttpClient'
import { CustomerRepositoryApi } from '../infra'

export async function updateAutoGeneratedPasswordUseCase({
  successCallback,
  errorCallback,
  finallyCallback,
  request
}: {
  successCallback: (newToken: string) => void
  errorCallback?: (error?: ApiError) => void
  finallyCallback?: () => void
  request: { password: string; isRatafiaPage?: boolean; formName?: string }
}): Promise<void> {
  await CustomerRepositoryApi.updateAutoGeneratedPassword(request.password)
    .then((newToken) => {
      sendAmplitudeEvent(request.isRatafiaPage, request.formName)
      logInfo('UpdateAutoGeneratedPasswordUseCase')
      successCallback(newToken)
    })
    .catch((error: ApiError | undefined) => {
      logError(`UpdateAutoGeneratedPasswordUseCase: ${error?.message}`)
      errorCallback?.(error)
    })
    .finally(() => {
      finallyCallback?.()
    })
}

function sendAmplitudeEvent(isRatafiaPage?: boolean, formName?: string): void {
  const eventData =
    isRatafiaPage ?
      { type: 'ai_user_created', props: {} }
    : {
        type: 'complete_registration',
        props: {
          login_type: 'email',
          initial_document_type: formName ?? null,
          version: AMPLITUDE_TEST_AB_VERSION.PRODUCTION
        }
      }
  sendAmplitudeData(eventData.type, eventData.props)
}
