import React from 'react'

import { ContactForm } from '../../form'
import { type HelpCenterColumnsPageProps } from './HelpCenterColumnsPageProps'
import { HelpCenterContactBox } from '../../molecules'
import helpCenterJson from '@legal/shared/data/json/help-center.json'
import { useAllSiteMetadata } from '@legal/shared/hooks/useAllSiteMetadata'
import './HelpCenterColumns.scss'

export const HelpCenterColumns: React.FC<HelpCenterColumnsPageProps> = ({ data }) => {
  const { siteEmail, sitePhone, siteSchedule } = useAllSiteMetadata()
  return (
    <div className='help-center-container'>
      <div className='help-center-left-container'>
        <HelpCenterContactBox
          title={data.contactUsSection.title}
          phone={sitePhone}
          schedule={siteSchedule}
          email={siteEmail}
          text={data.contactUsSection.mailInformation}
          mobileGtmTag='clicked_phone_helpcenter'
        />
      </div>
      <div className='divider' />
      <div className='help-center-right-container'>
        <p className='send-message-text'>{data.sendUsMessageSection.title}</p>
        <ContactForm json={helpCenterJson} data={data.sendUsMessageSection} />
      </div>
    </div>
  )
}
