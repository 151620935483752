import { logError, logInfo } from '@legal/shared/logger'
import { CustomerRepositoryApi } from '../infra'

export async function getLastPaymentMessageUseCase({
  successCallback,
  errorCallback,
  finallyCallback
}: {
  successCallback: (message: string) => void
  errorCallback?: (error: unknown) => void
  finallyCallback?: () => void
}): Promise<void> {
  await CustomerRepositoryApi.getLastPaymentMessage()
    .then((message) => {
      logInfo('GetLastPaymentMessageUseCase')
      successCallback(message)
    })
    .catch((error: Error | undefined) => {
      logError('GetLastPaymentMessageUseCase', undefined, error)
      errorCallback?.(error)
    })
    .finally(() => {
      finallyCallback?.()
    })
}
