import React from 'react'

import { type SeoProps } from './SeoProps'
import { useAllSiteMetadata } from '@legal/shared/hooks/useAllSiteMetadata'
import { useConfigs } from '@legal/core/configs'
import { useNavbarMicroformat } from './useNavbarMicroformat'

export const Seo: React.FC<SeoProps> = ({ title, children, hasNavbarMicroformat = false }) => {
  const { STRAPI_CONFIG, HAS_TRUSTPILOT } = useConfigs()

  const { SITE_NAME } = useAllSiteMetadata()
  const navbarMicroformat = useNavbarMicroformat()

  return (
    <>
      <html lang={STRAPI_CONFIG.LOCALE} data-theme={STRAPI_CONFIG.DOMAIN.toLowerCase()} />
      <title>
        {title} | {SITE_NAME}
      </title>
      <meta name='robots' content='noindex, nofollow' />
      {hasNavbarMicroformat && <script type='application/ld+json'>{JSON.stringify(navbarMicroformat)}</script>}
      {HAS_TRUSTPILOT && (
        <script
          type='text/javascript'
          src='//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js'
          async
        ></script>
      )}
      {children}
    </>
  )
}
