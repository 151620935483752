import React from 'react'

import { BillingRefundProps } from './BillingRefundProps'
import { formatDate } from '@legal/shared/utils'
import getFormattedAmount from '../../../modules/shared/utils/currency/getFormattedAmount'
import { useQueryCommonsText } from '@legal/shared/data/graphql/queries/useQueryCommonsText'
import './BillingRefund.scss'

export const BillingRefund: React.FC<BillingRefundProps> = ({ amount, id, paidAt, subscriptionTypeName }) => {
  const { currrencySymbol } = useQueryCommonsText()
  return (
    <div key={id} className='refund-content'>
      <span>
        {formatDate({ date: paidAt, format: 'MMMM, YYYY' }).split(' ').reverse().join(' ')} - {subscriptionTypeName}
      </span>
      <span>{getFormattedAmount({ amount, currrencySymbol })}</span>
    </div>
  )
}
