/* eslint-disable @typescript-eslint/no-explicit-any */

import { Button } from '@npm_leadtech/legal-lib-components/Button'
import { DocumentStatus } from '@npm_leadtech/legal-lib-components/DocumentStatus'
import React from 'react'
import { TextInput } from '@npm_leadtech/legal-lib-components/TextInput'

import { DocumentActionsMenu, Loading } from '../../molecules'
import DocIcon from '../../../assets/images/componentsSvg/doc-24-upload.svg'
import Download from '../../../assets/images/componentsSvg/save-24-px.svg'
import { type UploadDocumentCardProps } from './UploadedDocumentCardProps'
import { formatDate } from '@legal/shared/utils'
import { useConfigs } from '@legal/core/configs'

export const UploadedDocumentCard: React.FC<UploadDocumentCardProps> = ({
  name,
  applicationId,
  updatedAt,
  uploadingFile,
  previewAction,
  downloadAction,
  editAction,
  ratafiaClicked,
  updateDocumentName,
  deleteClicked,
  data,
  setOpenMenu,
  openMenu
}) => {
  const { RATAFIA_CONFIG } = useConfigs()
  const [editingName, setEditingName] = React.useState<boolean>(false)
  const [docName, setDocName] = React.useState<string>(name.split('.').slice(0, -1).join())
  const inputNameRef = React.useRef(null)
  const format = name.split('.')[name.split('.').length - 1].toLowerCase()
  const isPDF = format === 'pdf'
  const formattedName = docName.length > 39 ? docName.substring(0, 38) + '...' : `${docName}.${format}`
  // TODO: Replaced for API attribute
  const isAIReviewed = RATAFIA_CONFIG.ENABLED

  const formatText = data.strapiMyDocumentsPage?.documentsSection?.myDocumentCard?.updatedAtFormatText
  const occurrences = /\[(.*?)]/.exec(formatText)
  const updatedAtFormatted =
    updatedAt && occurrences ?
      formatDate({ date: updatedAt, format: formatText }).replace(',', ` ${occurrences[1]}`)
    : null

  React.useEffect(() => {
    if (editingName) {
      inputNameRef?.current?.focus()
    }
  }, [editingName])

  const onBlurFormName = (e: any): void => {
    if (editAction) {
      editAction(applicationId, `${e.target.value}.${format}`).then(() => {
        setEditingName(false)
        if (updateDocumentName) updateDocumentName(applicationId, `${e.target.value}.${format}`)
      })
    }
  }

  const onChangeFormName = (e: any): void => {
    const target = e.target
    const value = target.value
    if (value.length < 75) {
      setDocName(value)
    }
  }

  return (
    <div className={`document-card ${uploadingFile ? 'uploading' : ''}`}>
      <div className='document-card__wrapper'>
        <div className='document-card__wrapper__info'>
          <div className='document-card__wrapper__info__icon'>
            <DocIcon />
          </div>
          <div className='document-card__wrapper__info__general'>
            {!editingName && (
              <p className='sans-serif --medium document-card__wrapper__info__general__name document-name'>
                {formattedName}
              </p>
            )}
            {editingName && (
              <TextInput
                type={'text'}
                placeholder={''}
                givenClass='editing-document-name'
                value={docName}
                required={false}
                isValidGroup={true}
                maxlength={100}
                validate={true}
                onBlur={onBlurFormName}
                onChange={onChangeFormName}
                ref={inputNameRef}
              />
            )}
            <p className='document-card__wrapper__info__general__state sans-serif'>{format.toUpperCase()}</p>
            {updatedAtFormatted && (
              <time dateTime={updatedAtFormatted} className='sans-serif'>
                {updatedAtFormatted}
              </time>
            )}
          </div>
          {isAIReviewed && (
            <div className='document-card__wrapper__info__status'>
              <DocumentStatus
                type='ai_reviewed'
                statusCompletedText={data?.strapiMyDocumentsPage?.documentsSection?.myDocumentCard?.statusCompletedText}
                statusNewText={data?.strapiMyDocumentsPage?.documentsSection?.myDocumentCard?.statusNewText}
                statusInProgressText={
                  data?.strapiMyDocumentsPage?.documentsSection?.myDocumentCard?.statusInProgressText
                }
                statusAIReviewedText={
                  data?.strapiMyDocumentsPage?.documentsSection?.myDocumentCard?.statusAIReviewedText
                }
              />
            </div>
          )}
        </div>
        <div className='document-card__wrapper__mobile-separator'></div>
        <div className='document-card__wrapper__action-buttons'>
          <div className='document-card__wrapper__action-buttons__button'>
            {!uploadingFile && (
              <Button
                noLink
                onClick={() => {
                  if (downloadAction) downloadAction(applicationId, `${docName}.${format}`)
                }}
                functionParameters={applicationId}
                color='secondary3'
                label={data.strapiMyDocumentsPage?.documentsSection?.myDocumentCard?.downloadText}
                dataQa='download_document_button'
              >
                <Download />
              </Button>
            )}
          </div>
          {!uploadingFile && (
            <DocumentActionsMenu
              editNameClicked={() => {
                setEditingName(true)
              }}
              previewClicked={() => previewAction?.(applicationId, `${docName}.${format}`)}
              deleteClicked={() => {
                deleteClicked(applicationId, true)
              }}
              deleteAction
              previewAction={isPDF}
              ratafiaAction={RATAFIA_CONFIG.ENABLED}
              isOwnDocument={false}
              ratafiaClicked={() => {
                ratafiaClicked?.({
                  document_property: 'Client',
                  document_type: docName
                })
              }}
              data={data}
              setOpenMenu={setOpenMenu}
              openMenu={openMenu}
            />
          )}
          {uploadingFile && (
            <div className='document-actions__list'>
              <div className='tooltip-menu__item'>
                <Loading />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
