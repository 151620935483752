/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */

import React from 'react'
import ReactDOMServer from 'react-dom/server'
import { navigate } from 'gatsby'

import {
  type Subscription,
  createCustomerSubscriptionUseCase,
  createSubscriptionNoAuthUseCase,
  getSubscriptionUseCase,
  upgradeSubscriptionUseCase
} from '@legal/subscription'
import { FormContext } from '../../../../services/utils/contexts'
import { SubscriptionCookie } from '../../../../services/storage/cookies/SubscriptionCookie'
import { UserCookie } from '../../../../services/storage/cookies/UserCookie'
import { getFormUrlParameters } from '@legal/shared/utils'
import { logError } from '@legal/shared/logger'
import { stringSlugify } from '../../../../services/utils/stringSlugify'
import { updateApplicationDataUseCase } from '@legal/application'
import { useApplicationCookie } from '@legal/shared/hooks'

export const useTransformAndSendHtml = (): {
  transformAndSendHtml: ({ lastStepData, activeTestABVersion }: { lastStepData: any; activeTestABVersion: any }) => void
  loadingDataToPaymentPage: boolean
} => {
  const [loadingDataToPaymentPage, setLoadingDataToPaymentPage] = React.useState(false)
  const [userCookie] = React.useState(new UserCookie())
  const { template, formType, formSubType, formStateName } = React.useContext(FormContext)
  const [urlParameters] = React.useState(getFormUrlParameters({ formType, formStateName, formSubType }))
  const { applicationCookie } = useApplicationCookie()

  const getSubscriptionCallback = async (
    subscriptionResponse: Subscription,
    currentSubscriptionId: string
  ): Promise<void> => {
    const subscriptionCookie = new SubscriptionCookie()
    if (subscriptionResponse.price?.id === subscriptionCookie.subscriptionTypeId) {
      createSubscriptionSuccessCallback(subscriptionResponse.id, subscriptionResponse.token)
    } else {
      if (!userCookie.token) return
      await upgradeSubscriptionUseCase({
        request: {
          currentSubscriptionId,
          subscriptionPriceId: subscriptionCookie.subscriptionTypeId ?? ''
        },
        successCallback: (response) => {
          createSubscriptionSuccessCallback(response.subscriptionId, response.token)
        },
        errorCallback: createSubscriptionErrorCallback
      })
    }
  }

  const updateApplicationSuccess = (): void => {
    const subscriptionCookie = new SubscriptionCookie()
    const data = {
      subscriptionPriceId: subscriptionCookie.subscriptionTypeId,
      applicationId: applicationCookie?.id
    }
    if (userCookie.token) {
      if (subscriptionCookie.subscriptionId) {
        getSubscriptionUseCase({
          request: { subscriptionId: subscriptionCookie.subscriptionId },
          successCallback: (response) => {
            getSubscriptionCallback(response, subscriptionCookie.subscriptionId)
          }
        })
      } else {
        createCustomerSubscriptionUseCase({
          request: {
            subscriptionPriceId: data.subscriptionPriceId,
            applicationId: data.applicationId
          },
          successCallback: createSubscriptionSuccessCallback,
          errorCallback: createSubscriptionErrorCallback
        })
      }
    } else {
      if (!data.applicationId) return
      createSubscriptionNoAuthUseCase({
        request: {
          applicationId: data.applicationId,
          subscriptionPriceId: data.subscriptionPriceId
        },
        successCallback: (response) => {
          createSubscriptionSuccessCallback(response.subscriptionId, response.token)
        },
        errorCallback: createSubscriptionErrorCallback
      })
    }
  }

  const updateApplicationError = (): void => {
    if (userCookie.token) {
      navigate('/document-actions/', {
        state: {
          toast: {
            type: 'error',
            title: 'Oh no!',
            text: 'Something went wrong and we couldn’t save your changes, try again'
          }
        }
      })
    }
  }

  const transformAndSendHtml = ({
    lastStepData,
    activeTestABVersion
  }: {
    lastStepData: any
    activeTestABVersion: string
  }): void => {
    setLoadingDataToPaymentPage(true)
    if (!applicationCookie?.id) {
      console.error('applicationId undefined')
      logError(`applicationId undefined in useTransformAndSendHtml`)
      return
    }
    updateApplicationDataUseCase({
      applicationId: applicationCookie.id,
      request: {
        step: lastStepData.step.slug,
        lastStep: true,
        data: lastStepData.data,

        html: ReactDOMServer.renderToStaticMarkup(template),
        testAbIdentifier: activeTestABVersion
      },
      successCallback: updateApplicationSuccess,
      errorCallback: updateApplicationError
    })
  }

  const createSubscriptionSuccessCallback = (subscriptionId: string, token?: string): void => {
    const userCookie = new UserCookie()
    const subscriptionCookie = new SubscriptionCookie()
    if (subscriptionId) subscriptionCookie.subscriptionId = subscriptionId
    if (token) userCookie.token = token
    const navigateUrl = `/payment?${urlParameters}&subs-type=${stringSlugify(subscriptionCookie.subscriptionTypeName)}`
    void navigate(navigateUrl, {
      state: {
        applicationId: applicationCookie?.id,
        subscriptionId: subscriptionCookie.subscriptionId,
        subscriptionTypeId: subscriptionCookie.subscriptionTypeId,
        formName: applicationCookie?.form?.name
      }
    })
  }

  const createSubscriptionErrorCallback = (): void => {
    const userCookie = new UserCookie()
    if (userCookie.token) {
      navigate('/document-actions/', {
        state: {
          toast: {
            type: 'error',
            title: 'Oh no!',
            text: 'Something went wrong and we couldn’t save your changes, try again'
          }
        }
      })
    }
  }

  return {
    transformAndSendHtml,
    loadingDataToPaymentPage
  }
}
