import { Application, ApplicationActions, ApplicationPaymentStatus } from '../../domain'
import { ApplicationsData } from '../dataObjects'

export function ApplicationsDataToApplication(data: ApplicationsData): Application[] {
  return data.applications.items.map((application) => {
    return {
      id: application.applicationId,
      name: application.name,
      subscriptionId: application.subscriptionId ?? undefined,
      subscriptionTypeId: application.subscriptionTypeId ?? undefined,
      reference: application.reference,
      form: {
        type: application.formType,
        url: getFormUrl(application.formUrl, application.formType, application.state),
        name: application.formTypeName
      },
      state: application.state,
      updatedAt: application.updatedAt,
      status: application.status as ApplicationPaymentStatus,
      pdfDownloadedAt: application.pdfDownloadedAt ?? undefined,
      actions: application.actions as ApplicationActions,
      isCompleted: [
        ApplicationPaymentStatus.COMPLETE,
        ApplicationPaymentStatus.DOWNLOADED,
        ApplicationPaymentStatus.PDF_PROCESSING,
        ApplicationPaymentStatus.PDF_GENERATED
      ].includes(application.status as ApplicationPaymentStatus)
    }
  })
}

function getFormUrl(formUrl: string, formType: string, state: string): string {
  const url = `/${formUrl}/form/?product=${formType.toLowerCase().split(' ').join('-')}`
  return state === formType ? url : `${url}&type=${state.toLowerCase().split(' ').join('-')}`
}
