/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button } from '@npm_leadtech/legal-lib-components/Button'
import { DocumentStatus } from '@npm_leadtech/legal-lib-components/DocumentStatus'
import React from 'react'
import { navigate } from 'gatsby'

import { ApplicationPaymentStatus, getPaymentUrl, updateApplicationNameUseCase } from '@legal/application'
import {
  type Subscription,
  type UpgradeSubscriptionResponse,
  createCustomerSubscriptionUseCase,
  getSubscriptionTypeByCodeUseCase,
  getSubscriptionTypeUpgradeUseCase,
  upgradeSubscriptionUseCase
} from '@legal/subscription'
import DocIcon from '../../../assets/images/componentsSvg/doc-24-p.svg'
import { DocumentActionsMenu } from '../../molecules'
import { type DocumentCardProps } from './DocumentCardProps'

import Download from '../../../assets/images/componentsSvg/save-24-px.svg'
import { PaymentCookie } from '../../../services/storage/cookies/PaymentCookie'
import { SubscriptionCookie } from '../../../services/storage/cookies/SubscriptionCookie'
import { TextInput } from '../../form'
import { formatDate } from '@legal/shared/utils'
import { stringSlugify } from '../../../services/utils/stringSlugify'
import { useConfigs } from '@legal/core/configs'
import './DocumentCard.scss'

export const DocumentCard: React.FC<DocumentCardProps> = ({
  applicationId,
  isCompleted = false,
  actions,
  subscriptionId,
  formType = '',
  name,
  formTypeName,
  updatedAt,
  state,
  status,
  downloadPdf,
  editClicked,
  deleteClicked,
  onEditApplicationName,
  previewClicked,
  duplicateClicked,
  ratafiaClicked,
  currentSubscription,
  canUpgradeAccount,
  data
}) => {
  const { RATAFIA_CONFIG } = useConfigs()
  const slugyFormType = stringSlugify(formType)
  const [editingFormName, setEditingFormName] = React.useState<boolean>(false)
  const [formName, setFormName] = React.useState<string>(name)
  const [originalFormName, setOriginalFormName] = React.useState<string>(name)
  const inputFormNameRef = React.useRef(null)
  const [subscriptionName, setSubscriptionName] = React.useState<string>()
  const paymentCookie = new PaymentCookie()
  let actionButton = null

  React.useEffect(() => {
    if (!editingFormName) return
    if (inputFormNameRef?.current) inputFormNameRef.current.focus()
  }, [editingFormName])

  const onChangeFormName = React.useCallback((e: any): void => {
    const { value } = e.target
    if (value.length < 40) setFormName(value)
  }, [])

  const onBlurFormName = React.useCallback(async (e: any): Promise<void> => {
    await updateApplicationNameUseCase({
      applicationId,
      name: e.target.value,
      successCallback: () => {
        setOriginalFormName(e.target.value)

        onEditApplicationName(applicationId, e.target.value)
      },
      errorCallback: () => {
        setFormName(originalFormName)
      },
      finallyCallback: () => {
        setEditingFormName(false)
      }
    })
  }, [])

  const getSubscriptionTypeByCodeSuccess = (subscriptions: Subscription[]): void => {
    const subscriptionCookie = new SubscriptionCookie()
    subscriptionCookie.subscriptionId = currentSubscription
    subscriptionCookie.subscriptionTypeId = subscriptions[0].id
    setSubscriptionName(subscriptions[0].type.name)
    async function createCustomer() {
      await createCustomerSubscriptionUseCase({
        request: {
          subscriptionPriceId: subscriptions[0].id,
          applicationId
        },
        successCallback: createCustomerSubscriptionSuccessCallback
      })
    }
    createCustomer()
  }

  const createCustomerSubscriptionSuccessCallback = (subscriptionId: string): void => {
    const subscriptionCookie = new SubscriptionCookie()
    if (subscriptionId) subscriptionCookie.subscriptionId = subscriptionId
    const formUrl = getPaymentUrl(formType, subscriptionName, state)
    navigate(formUrl, {
      state: {
        applicationId,
        subscriptionId,
        subscriptionTypeId: subscriptionCookie.subscriptionTypeId ?? null,
        formName: formTypeName
      }
    })
  }

  const upgradeAccount = async (): Promise<void> => {
    if (currentSubscription) {
      await getSubscriptionTypeUpgradeUseCase({
        successCallback: (response) => {
          if (response[0]?.type?.name) {
            changeToNewSubscription(response[0].type.name, response[0].id)
          }
        }
      })
    } else {
      await getSubscriptionTypeByCodeUseCase({ successCallback: getSubscriptionTypeByCodeSuccess, tokenRequired: true })
    }
  }

  const upgradeSubscriptionCallback = (
    response: UpgradeSubscriptionResponse,
    cookie: SubscriptionCookie,
    subName: string
  ): void => {
    const subscriptionCookie = new SubscriptionCookie()
    if (response.subscriptionId) cookie.subscriptionId = response.subscriptionId
    const formUrl = getPaymentUrl(formType, subName, state)
    navigate(formUrl, {
      state: {
        applicationId,
        subscriptionId,
        subscriptionTypeId: subscriptionCookie.subscriptionTypeId ?? null,
        formName: formTypeName
      }
    })
  }

  const changeToNewSubscription = async (subName: string, currentSubType: string): Promise<void> => {
    const subscriptionCookie = new SubscriptionCookie()
    subscriptionCookie.subscriptionId = currentSubscription
    subscriptionCookie.subscriptionTypeId = currentSubType

    await upgradeSubscriptionUseCase({
      request: {
        currentSubscriptionId: currentSubscription,
        subscriptionPriceId: currentSubType
      },
      successCallback: (response) => {
        upgradeSubscriptionCallback(response, subscriptionCookie, subName)
      }
    })
  }

  let continueActive = false

  if (actions?.pay && canUpgradeAccount) {
    actionButton = (
      <Button
        noLink
        onClick={upgradeAccount}
        color='secondary3'
        label={data.strapiMyDocumentsPage?.documentsSection?.myDocumentCard?.upgradeAccountText}
        dataQa='upgrade_account_button'
      />
    )
  } else if (actions?.download) {
    actionButton = (
      <Button
        noLink
        onClick={downloadPdf}
        functionParameters={applicationId}
        color='secondary3'
        label={data.strapiMyDocumentsPage?.documentsSection?.myDocumentCard?.downloadText}
        dataQa='download_document_button'
      >
        <Download />
      </Button>
    )
  } else if (actions?.edit) {
    continueActive = true
    actionButton = (
      <Button
        givenClass='edit-button'
        noLink
        onClick={() => {
          editClicked(applicationId, 'Continue Editing')
        }}
        functionParameters={applicationId}
        color='secondary3'
        label={data.strapiMyDocumentsPage?.documentsSection?.myDocumentCard?.continueEditingText}
        dataQa='edit_document_button'
      />
    )
  } else if (!actions?.pay) paymentCookie.clear()

  const formatText = data.strapiMyDocumentsPage?.documentsSection?.myDocumentCard?.updatedAtFormatText
  const occurrences = /\[(.*?)]/.exec(formatText)
  const updatedAtFormatted =
    updatedAt && occurrences ?
      formatDate({ date: updatedAt, format: formatText }).replace(',', ` ${occurrences[1]}`)
    : undefined

  return (
    <div className='document-card'>
      <div className='document-card__wrapper'>
        <div className='document-card__wrapper__info'>
          <div className='document-card__wrapper__info__icon'>
            <DocIcon />
          </div>
          <div className='document-card__wrapper__info__general'>
            {!editingFormName && (
              <p className='sans-serif --medium document-card__wrapper__info__general__name document-name'>
                {formName}
              </p>
            )}
            {editingFormName && (
              <TextInput
                type={'text'}
                placeholder={''}
                givenClass='editing-document-name'
                value={formName}
                required={false}
                isValidGroup={true}
                maxlength={100}
                validate={true}
                onBlur={onBlurFormName}
                onChange={onChangeFormName}
                ref={inputFormNameRef}
              />
            )}
            <p className='document-card__wrapper__info__general__state sans-serif'>{state}</p>
            <time dateTime={updatedAtFormatted} className='sans-serif'>
              {updatedAtFormatted}
            </time>
          </div>
          <div className='document-card__wrapper__info__status'>
            <DocumentStatus
              type={RATAFIA_CONFIG.ENABLED ? ApplicationPaymentStatus.AI_REVIEWED : status}
              isCompleted={isCompleted}
              statusCompletedText={
                data?.strapiMyDocumentsPage?.documentsSection?.myDocumentCard?.statusCompletedText ?? ''
              }
              statusNewText={data?.strapiMyDocumentsPage?.documentsSection?.myDocumentCard?.statusNewText ?? ''}
              statusInProgressText={
                data?.strapiMyDocumentsPage?.documentsSection?.myDocumentCard?.statusInProgressText ?? ''
              }
              statusAIReviewedText={
                data?.strapiMyDocumentsPage?.documentsSection?.myDocumentCard?.statusAIReviewedText ?? ''
              }
            />
          </div>
        </div>
        <div className='document-card__wrapper__mobile-separator'></div>
        <div className='document-card__wrapper__action-buttons'>
          <div className='document-card__wrapper__action-buttons__button'>{actionButton}</div>
          <DocumentActionsMenu
            formType={slugyFormType}
            editClicked={() => {
              editClicked(applicationId, 'Dashboard edit')
            }}
            editNameClicked={() => {
              setEditingFormName(true)
            }}
            deleteClicked={() => {
              deleteClicked(applicationId)
            }}
            deleteAction={actions?.delete ?? false}
            duplicateClicked={() => {
              duplicateClicked(applicationId)
            }}
            duplicateAction={actions?.duplicate}
            previewClicked={() => {
              previewClicked(applicationId)
            }}
            previewAction={actions?.preview ?? false}
            editActive={!continueActive}
            ratafiaAction={RATAFIA_CONFIG.ENABLED}
            ratafiaClicked={ratafiaClicked}
            data={data}
          />
        </div>
      </div>
    </div>
  )
}
