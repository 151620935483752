/* eslint-disable @typescript-eslint/no-unused-expressions */
import React from 'react'

import { Button } from '@npm_leadtech/legal-lib-components/Button'
import { type PriceContentProps } from './PriceContentProps'
import { Spinner } from '@npm_leadtech/legal-lib-components/Spinner'
import { Subscription } from '@legal/subscription'
import { SubscriptionCookie } from '../../../services/storage/cookies/SubscriptionCookie'
import { useApplicationCookie } from '@legal/shared/hooks'
import { useQueryFormPage } from '@legal/shared/data/graphql'
import './PriceContent.scss'

export const PriceContent: React.FC<PriceContentProps> = ({
  callbackBottomOverlay,
  handleContinue,
  currrencySymbol,
  currencyCode,
  subscription,
  setSubscription,
  subscriptionsType,
  subscriptionTypeName,
  loadingDataToPaymentPage,
  hideMobileButtons
}) => {
  const { applicationCookie } = useApplicationCookie()
  const { priceContent } = useQueryFormPage()
  const initialAmount = parseFloat(subscription?.initialAmount).toFixed(2)
  const [integer, decimal] = initialAmount.split('.')
  const lastStepButton =
    applicationCookie?.id && !loadingDataToPaymentPage ?
      <Button noLink disabled={loadingDataToPaymentPage} onClick={handleContinue} dataQa={'completeApplication'}>
        {priceContent?.item}
      </Button>
    : <Button noLink disabled={loadingDataToPaymentPage} onClick={() => {}}>
        <Spinner className={'spinner--primary'} />
      </Button>

  React.useEffect(() => {
    callbackBottomOverlay?.(lastStepButton)
    subscriptionsType && setSubscriptionInfo(subscriptionsType[0])
  }, [])

  const setSubscriptionInfo = (subscriptionType: Subscription) => {
    const subscriptionCookie = new SubscriptionCookie()
    subscriptionCookie.subscriptionTypeId = subscriptionType?.id
    subscriptionCookie.subscriptionTypeName = subscriptionType?.type?.name
    setSubscription(subscriptionType)
  }

  return (
    <div className={`price-content ${hideMobileButtons ? 'hide-mobile-buttons' : ''}`}>
      <div className='price-content__price-and-type'>
        <p className='price-content__price-and-type__price'>
          {currrencySymbol}
          {decimal === '00' ? integer : initialAmount}{' '}
          <span className='price-content__price-and-type__price__currency'>{currencyCode}</span>
        </p>
        <p className='price-content__price-and-type__type'>{subscriptionTypeName}</p>
      </div>
      <div className='price-content__buttons'>{lastStepButton}</div>
    </div>
  )
}
