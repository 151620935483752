import { InfoBox } from '@npm_leadtech/legal-lib-components/InfoBox'
import React from 'react'

import { InfoBoxUnderCardProps } from './InfoBoxUnderCardProps'
import { oldCardWasUsedForPayment } from '../../../services/utils/billingHelperFunctions'

export const InfoBoxUnderCard: React.FC<InfoBoxUnderCardProps> = ({
  subscriptions,
  lastPaymentMessage,
  lastPayment,
  card
}) => {
  if (!card || !subscriptions) return null
  if (card.isExpirationMonth) {
    return (
      <InfoBox type='warning'>
        <p>
          <strong>Your card expires soon.</strong> Your old credit card will be used for payment unless the details are
          updated.
        </p>
      </InfoBox>
    )
  }
  if (lastPaymentMessage === 'change_payment_card' && card.isExpired) {
    return (
      <InfoBox type='error'>
        <p>
          <strong>Your credit card has expired.</strong> Please, update your payment method within 25 days or your
          subscription will be canceled.
        </p>
      </InfoBox>
    )
  }
  if (card.isExpired) {
    return (
      <InfoBox type='error'>
        <p>
          <strong>Your credit card has expired.</strong> Please, update a new one.
        </p>
      </InfoBox>
    )
  }
  if (oldCardWasUsedForPayment(card, lastPaymentMessage, lastPayment)) {
    return (
      <InfoBox type='warning'>
        <p>Your payment information is not valid. Your old credit card was used for payment.</p>
      </InfoBox>
    )
  }
  return null
}
