import { Disclaimer } from '@npm_leadtech/legal-lib-components/Disclaimer'
import React from 'react'

import { type DisclaimerWrapperProps } from './DisclaimerWrapperProps'
import { FormatHTMLAstChildren } from '../../molecules/FormatHTMLAstChildren'
import { replaceValues } from '../../../services/utils/replaceAll'
import { useAllDisclaimer } from '@legal/shared/hooks'
import { useConfigs } from '@legal/core/configs'

const WWW = 'www'

export const DisclaimerWrapper: React.FC<DisclaimerWrapperProps> = ({
  givenClass,
  environment,
  disclaimerLinks = false
}) => {
  const disclaimerContent = useAllDisclaimer()
  const {
    SITE_METADATA: { SITE_URL }
  } = useConfigs()

  const CURRENT_YEAR = new Date().getFullYear().toString()

  const simpleCopyRightText: string =
    disclaimerContent?.copyrightText?.data?.childMarkdownRemark?.htmlAst?.children[0]?.children
      .find((child) => child?.value?.includes('CURRENT_YEAR'))
      .value.split('|')[1]

  const disclaimerContentClone = JSON.parse(
    JSON.stringify(disclaimerContent?.copyrightText?.data?.childMarkdownRemark?.htmlAst?.children)
  )

  const disclaimerContentCloneMobile = disclaimerContentClone?.map((child) => {
    return {
      ...child,
      children: child.children.filter((_child) => !_child?.value?.includes('CURRENT_YEAR'))
    }
  })

  return (
    <Disclaimer
      givenClass={givenClass}
      text={disclaimerContent?.text ?? ''}
      linksUrl={environment}
      disclaimerLinks={disclaimerLinks}
      siteUrl={SITE_URL}
      complexCopyRightMobile={
        disclaimerContentCloneMobile && (
          <FormatHTMLAstChildren
            givenClassLink='grey-color'
            CURRENT_YEAR={CURRENT_YEAR}
            WWW={WWW}
            htmlAstChildren={disclaimerContentCloneMobile as React.ReactNode | React.ReactNode[]}
          />
        )
      }
      complexCopyRight={
        disclaimerContent?.copyrightText?.data?.childMarkdownRemark?.htmlAst && (
          <FormatHTMLAstChildren
            givenClassLink='grey-color'
            CURRENT_YEAR={CURRENT_YEAR}
            WWW={WWW}
            htmlAstChildren={
              disclaimerContent.copyrightText.data.childMarkdownRemark.htmlAst.children as
                | React.ReactNode
                | React.ReactNode[]
            }
          />
        )
      }
      simpleCopyRight={simpleCopyRightText ? replaceValues(simpleCopyRightText, { CURRENT_YEAR, WWW, SITE_URL }) : ''}
    />
  )
}
