interface StrapiConfig {
  DOMAIN: string
  LOCALE: string
  URL: string
  TOKEN: string
}

interface ApiConfg {
  API_PREFIX: string
  WS_PREFIX: string
  HTTP_SECRET_KEY: string
}

interface SiteMetadataConfig {
  SITE_URL: string
}

interface DatadogConfig {
  CLIENT_TOKEN: string
  SITE: string
  SERVICE: string
  SOURCEMAPS_PATH: string
  ENVIRONMENT: string
}

interface MacropayConfig {
  KEY: string
  ENV: string
}

interface FreshchatConfig {
  FRESHCHAT: boolean
  SRC: string
  WIDGET_ID: string
  TOKEN: string
  HOST: string
}

interface PaddleConfig {
  VENDOR_KEY: number
}

interface AwsConfig {
  TARGET_BUCKET_NAME: string
  REGION: string
}

interface GoogleConfig {
  TAG_MANAGER_ID: string
}

interface AmplitudeConfig {
  KEY: string
}

interface PricingIdsConfig {
  default: string
}
interface RatafiaConfig {
  ENABLED: boolean
}

export interface IConfig {
  TARGET_ADDRESS: string
  APP_SUBDOMAIN: string
  DOMAIN: string
  PRODUCTION_VIEW_MODE: boolean
  IS_JONSNOW: boolean
  HAS_COOKIE_MESSAGE: boolean
  HAS_GRAV_SITEMAP: boolean
  HAS_TRUSTPILOT: boolean
  COUNTRY: 'US' | 'GB' | 'CA' | 'AU'
  ASSETS_URL: string
  IS_LOCAL_ENV: boolean
  IS_STAGEPRODUCT_ENV: boolean
  API_CONFIG: ApiConfg
  PRICING_IDS: PricingIdsConfig
  SITE_METADATA: SiteMetadataConfig
  MACROPAY_CONFIG: MacropayConfig
  DATADOG_CONFIG: DatadogConfig
  FRESHCHAT_CONFIG: FreshchatConfig
  PADDLE_CONFIG: PaddleConfig
  PA_UPLOAD_DOCUMENT_ENABLED: boolean
  STRAPI_CONFIG: StrapiConfig
  AWS_CONFIG: AwsConfig
  GOOGLE_CONFIG: GoogleConfig
  AMPLITUDE_CONFIG: AmplitudeConfig
  RATAFIA_CONFIG: RatafiaConfig
  HOTJAR_ID: number
}

export function getConfigs(): IConfig {
  return {
    HAS_COOKIE_MESSAGE: process.env.HAS_COOKIE_MESSAGE === 'true',
    TARGET_ADDRESS: process.env.TARGET_ADDRESS ?? 'https://legal-contracts.com',
    APP_SUBDOMAIN: process.env.APP_SUBDOMAIN ?? 'https://app.legal-contracts.com',
    DOMAIN: process.env.DOMAIN ?? 'legal-contracts.com',
    PRODUCTION_VIEW_MODE: process.env.PRODUCTION_VIEW_MODE === 'true',
    IS_JONSNOW: process.env.IS_JONSNOW === 'true',
    HAS_GRAV_SITEMAP: process.env.HAS_GRAV_SITEMAP === 'true',
    HAS_TRUSTPILOT: process.env.HAS_TRUSTPILOT === 'true',
    COUNTRY: (process.env.COUNTRY as 'US' | 'GB' | 'CA' | 'AU') ?? 'US',
    ASSETS_URL: process.env.ASSETS_URL ?? 'https://assets.local.1elegal.net',
    IS_LOCAL_ENV: process.env.GATSBY_ACTIVE_ENV?.includes('local') ?? false,
    IS_STAGEPRODUCT_ENV: isStageProductEnv ?? false,
    HOTJAR_ID: Number(process.env.HOTJAR_ID ?? 192392),
    API_CONFIG: {
      API_PREFIX: process.env.API_PREFIX ?? 'https://local-api.legal-contracts.com/api',
      WS_PREFIX: process.env.WS_PREFIX ?? 'https://local-api.legal-contracts.com/ws',
      HTTP_SECRET_KEY: process.env.HTTP_SECRET_KEY ?? ''
    },
    PRICING_IDS: {
      default: process.env.PRICING_ID_DEFAULT ?? 'UO3PJ2'
    },
    SITE_METADATA: {
      SITE_URL: process.env.SITE_URL ?? 'legal-contracts.com'
    },
    MACROPAY_CONFIG: {
      KEY: process.env.MACROPAY_FRONT_API_KEY ?? '',
      ENV: process.env.MACROPAY_FRONT_ENV ?? 'test'
    },
    DATADOG_CONFIG: {
      CLIENT_TOKEN: process.env.DATADOG_CLIENT_TOKEN ?? '',
      SITE: process.env.DATADOG_SITE ?? '',
      SERVICE: process.env.DATADOG_SERVICE ?? 'legal-app',
      SOURCEMAPS_PATH: process.env.SOURCEMAPS_PATH ?? './static',
      ENVIRONMENT: process.env.DATADOG_ENVIRONMENT ?? 'dev'
    },
    FRESHCHAT_CONFIG: {
      FRESHCHAT: process.env.FRESHCHAT === 'true',
      SRC: process.env.FRESHCHAT_SRC ?? '//eu.fw-cdn.com/11260794/416863.js',
      WIDGET_ID: process.env.FRESHCHAT_WIDGET_ID ?? 'bb1757ed-76e3-46d9-91aa-c8865991684f',
      TOKEN: process.env.FRESHCHAT_TOKEN ?? '5174afc2-b055-41c9-949c-22e934a87e41',
      HOST: process.env.FRESHCHAT_HOST ?? 'https://wchat.eu.freshchat.com'
    },
    PADDLE_CONFIG: {
      VENDOR_KEY: process.env.PADDLE_VENDOR_KEY ? +process.env.PADDLE_VENDOR_KEY : 0
    },
    PA_UPLOAD_DOCUMENT_ENABLED: process.env.PA_UPLOAD_DOCUMENT_ENABLED === 'true',
    STRAPI_CONFIG: {
      DOMAIN: process.env.STRAPI_DOMAIN ?? 'LawDistrict',
      LOCALE: process.env.STRAPI_LOCALE ?? 'en-US',
      URL: process.env.STRAPI_URL ?? '',
      TOKEN: process.env.STRAPI_TOKEN ?? ''
    },
    AWS_CONFIG: {
      TARGET_BUCKET_NAME: process.env.TARGET_BUCKET_NAME ?? '',
      REGION: process.env.AWS_REGION ?? ''
    },
    GOOGLE_CONFIG: {
      TAG_MANAGER_ID: process.env.TAG_MANAGER_ID ?? 'GTM-0000000'
    },
    AMPLITUDE_CONFIG: {
      KEY: process.env.AMPLITUDE_KEY ?? ''
    },
    RATAFIA_CONFIG: {
      ENABLED: process.env.RATAFIA === 'true'
    }
  }
}

const isStageProductEnv =
  process.env.GATSBY_ACTIVE_ENV?.includes('front-preview') ||
  process.env.GATSBY_ACTIVE_ENV?.includes('front-app-dev') ||
  process.env.GATSBY_ACTIVE_ENV?.includes('stage') ||
  process.env.GATSBY_ACTIVE_ENV?.includes('environment-product')
