import React from 'react'

import { BillingRefund } from '../../molecules'
import { type BillingRefundsProps } from './BillingRefundsProps'
import { Section } from '../Section'
import './BillingRefunds.scss'

export const BillingRefunds: React.FC<BillingRefundsProps> = ({ refunds = [] }) => {
  if (refunds.length === 0) return null

  return (
    <Section title='Refunds' className='section--list-item'>
      <div className='card-info refund-card'>
        {refunds.map((refund) => (
          <BillingRefund key={refund.id} {...refund} />
        ))}
      </div>
    </Section>
  )
}
