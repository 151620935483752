import React from 'react'

import { customerAuthGoogleUseCase } from '@legal/customer'

export const useDoGoogleSignInCallback = ({
  isModal = false,
  formName
}: {
  isModal?: boolean
  formName?: string
}): {
  doGoogleSignIn: () => Promise<void>
} => {
  const doGoogleSignIn = React.useCallback(async (): Promise<void> => {
    let redirectUrl
    if (isModal) {
      redirectUrl = typeof window !== 'undefined' ? window.location.pathname : ''
    }
    await customerAuthGoogleUseCase({
      request: {
        redirectUrl,
        formName
      }
    })
  }, [formName, isModal])

  return { doGoogleSignIn }
}
