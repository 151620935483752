import { GetApplicationsRequest, GetApplicationsResponse } from './interfaces'
import { LIMIT_FETCH, OFFSET_FETCH } from '../domain'
import { logError, logInfo } from '@legal/shared/logger'
import { ApplicationRepositoryApi } from '../infra'

export async function getApplicationsUseCase({
  successCallback,
  errorCallback,
  finallyCallback,
  request
}: {
  successCallback: (applications: GetApplicationsResponse) => void
  errorCallback?: (error?: Error) => void
  finallyCallback?: () => void
  request: GetApplicationsRequest
}): Promise<void> {
  await ApplicationRepositoryApi.getAll({ ...request, limit: LIMIT_FETCH, offset: OFFSET_FETCH })
    .then((applications: GetApplicationsResponse) => {
      logInfo(`GetApplicationsUseCase`)
      successCallback(applications)
    })

    .catch((error: Error | undefined) => {
      logError(`GetApplicationsUseCase`, undefined, error)
      errorCallback?.(error)
    })
    .finally(() => {
      finallyCallback?.()
    })
}
