/* eslint-disable @typescript-eslint/no-unused-vars */

import React from 'react'
import { navigate } from 'gatsby'

import { AMPLITUDE_TEST_AB_VERSION, sendAmplitudeData } from '@legal/shared/amplitude'
import { Layout } from '../../layout'
import { LoginModal } from '../LoginModal'
import { PaymentCookie } from '../../../services/storage/cookies/PaymentCookie'
import { PricingComponent } from '../PricingComponent'
import { type PricingPageTemplateProps } from './PricingPageTemplateProps'
import { ReferrerCookie } from '../../../services/storage/cookies/ReferrerCookie'
import { RegisterModal } from '../RegisterModal'
import { SubscriptionCookie } from '../../../services/storage/cookies/SubscriptionCookie'
import { setCookiePolicyFunc } from '../../../services/utils/setCookiePolicyFunc'
import { stringSlugify } from '../../../services/utils/stringSlugify'
import { useApplicationCookie } from '@legal/shared/hooks'
import { useConfigs } from '@legal/core/configs'
import { useModal } from '../../../services/hooks/useModal'

export const PricingPageTemplate: React.FC<PricingPageTemplateProps> = ({ pricingComponentProps, location }) => {
  const { TARGET_ADDRESS } = useConfigs()
  const { applicationCookie, setApplicationCookieId, setApplicationCookieFormName } = useApplicationCookie()
  const subscriptionCookie = new SubscriptionCookie()
  const paymentCookie = new PaymentCookie()
  const referrerCookie = new ReferrerCookie()

  const loginModal = useModal(true)
  const registerModal = useModal(true)

  if (location.state) {
    if (location.state.applicationId) setApplicationCookieId(location.state.applicationId)
    if (location.state.subscriptionId) subscriptionCookie.subscriptionId = location.state.subscriptionId

    if (location.state.formName) setApplicationCookieFormName(location.state.formName)
  }

  React.useEffect(() => {
    if (!applicationCookie || (!applicationCookie.id && !location.state)) {
      setCookiePolicyFunc()
    } else if (paymentCookie.paymentStatus === 'paying') navigate('/my-account/my-documents/')
  }, [])

  React.useEffect(() => {
    const initialDocumentType = applicationCookie?.form?.name
    const eventProps = {
      initial_document_type: stringSlugify(initialDocumentType) ?? null,
      source: referrerCookie.getReferrer(),
      version: AMPLITUDE_TEST_AB_VERSION.PRODUCTION
    }
    sendAmplitudeData('show_pricing_page', eventProps)
  }, [])

  const toggleOpenRegisterCloseLogin = (): void => {
    loginModal.changeModalState()
    registerModal.changeModalState()
  }

  const toggleOpenLoginCloseRegister = (): void => {
    loginModal.changeModalState()
    registerModal.changeModalState()
  }

  const modalComponent = (
    <>
      {registerModal.modalOpen && (
        <RegisterModal
          toggleOpenLoginCloseRegister={toggleOpenLoginCloseRegister}
          isRegisterModal
          closeFunction={registerModal.closeModal}
          onToggleModal={registerModal.changeModalState}
          loginModalOpen={loginModal.modalOpen}
          urlRedirect={registerModal.urlRedirect}
          changeUrlRedirect={registerModal.changeUrlRedirect}
        />
      )}
      {loginModal.modalOpen && (
        <LoginModal
          toggleOpenRegisterCloseLogin={toggleOpenRegisterCloseLogin}
          isLoginModal
          closeFunction={loginModal.closeModal}
          onToggleModal={loginModal.changeModalState}
          registerModalOpen={registerModal.modalOpen}
          isTestAB8814={true}
        />
      )}
    </>
  )

  return (
    <div>
      <Layout
        noFooter={true}
        hideNavbar={false}
        disclaimerLinks={true}
        environment={TARGET_ADDRESS}
        toggleRegister={registerModal.changeModalState}
        changeUrlRedirect={registerModal.changeUrlRedirect}
        footerContactBar={true}
        hideDesktopNavbar={true}
        isTestAB8814={true}
        desktopNavbarPhone
      >
        {modalComponent}
        <PricingComponent {...pricingComponentProps} />
      </Layout>
    </div>
  )
}
