/* eslint-disable react-compiler/react-compiler */

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button } from '@npm_leadtech/legal-lib-components/Button'
import { ContactBox } from '@npm_leadtech/legal-lib-components/ContactBox'
import { Link } from 'gatsby'
import React from 'react'

import Help from '../../../assets/images/svg/help_24px.svg'
import HelpOpened from '../../../assets/images/svg/help_opened_24px.svg'
import { LoginContext } from '../../../services/utils/contexts'
import { type MobilePaneProps } from './MobilePaneProps'
import { NavbarItems } from '../NavbarItems'
import Personal from '../../../assets/images/svg/personal_24px.svg'
import PersonalOpened from '../../../assets/images/svg/personal_opened_24px.svg'
import PhoneIcon from '../../../assets/images/componentsSvg/phone_24px.svg'
import { UserCookie } from '../../../services/storage/cookies/UserCookie'
import { replaceValues } from '../../../services/utils/replaceAll'
import { useAllSiteMetadata } from '@legal/shared/hooks/useAllSiteMetadata'
import { useConfigs } from '@legal/core/configs'
import { useQueryMobilePane } from '@legal/shared/data/graphql'
import './MobilePane.scss'

export const MobilePane: React.FC<MobilePaneProps> = ({
  headerHasNoProducts,
  isOpen,
  mobileHideGoToDashboardButton,
  registerModalOpen,
  toggleLogin,
  toggleRegister,
  loginModalOpen,
  isURLForm = false,
  baseLinkForTestAB,
  tokenActive,
  mobileHideDocumentsList,
  mobileHideResources,
  mobileNavbarPhone = false
}) => {
  const { sitePhone, siteSchedule } = useAllSiteMetadata()
  const { IS_JONSNOW } = useConfigs()
  const { text, close, labelLogIn, labelCallUs, labelCreateFreeAccount, labelGoToDashboard } = useQueryMobilePane()
  const [token, setToken] = React.useState<string>('')
  const [isMenuOpen] = React.useState<boolean>(false)
  const [isMenuHelpOpen, setIsMenuHelpOpen] = React.useState<boolean>(false)
  const [isMenuPersonalOpen, setIsMenuPersonalOpen] = React.useState<boolean>(false)

  React.useEffect(() => {
    handleBodyScroll()
  }, [isMenuHelpOpen, isMenuPersonalOpen])

  const handleBodyScroll = (): void => {
    const disableScroll = isMenuHelpOpen || isMenuPersonalOpen
    if (disableScroll) {
      document.body.style.overflow = 'hidden'
      document.body.style.position = 'fixed'
    } else {
      document.body.style.overflow = 'visible'
      document.body.style.position = 'relative'
      document.body.style.width = '100%'
    }
  }

  const userCookie = new UserCookie()

  const toggleMenuHelpOpen = (): void => {
    setIsMenuHelpOpen(!isMenuHelpOpen)
    setIsMenuPersonalOpen(false)
    isOpen(!isMenuOpen)
  }

  const toggleMenuPersonalOpen = (): void => {
    setIsMenuPersonalOpen(!isMenuPersonalOpen)
    setIsMenuHelpOpen(false)
    isOpen(!isMenuOpen)
  }

  const onClose = (): void => {
    setIsMenuHelpOpen(false)
    setIsMenuPersonalOpen(false)
    isOpen(!isMenuOpen)
  }

  React.useEffect(() => {
    const tokenCookie = userCookie.token ?? ''
    setToken(tokenCookie)
  }, [])

  let userActions
  if (token) {
    userActions = !mobileHideGoToDashboardButton && (
      <div className={'navigation-pane__user-menu'}>
        <p className={'navigation-pane__user-name'}>
          {replaceValues(text, { USER_EMAIL: userCookie.email ? userCookie.email.split('@')[0] : '' })}
        </p>
        <Button
          givenClass={'navigation-pane__button'}
          link={'/my-account/my-documents'}
          LinkComponent={Link}
          label={labelGoToDashboard}
        />
      </div>
    )
  } else {
    userActions =
      !mobileHideGoToDashboardButton && registerModalOpen === undefined && loginModalOpen === undefined ?
        <div className={'navigation-pane__user-menu'}>
          <Button givenClass='login-button' color='tertiary' label={labelLogIn} link={'/login'} LinkComponent={Link} />
          <Button
            givenClass='go-to-free-account-button'
            label={labelCreateFreeAccount}
            link={'/register'}
            LinkComponent={Link}
          />
        </div>
      : <div className={'navigation-pane__user-menu'}>
          <Button
            givenClass='login-button'
            color='tertiary'
            label={labelLogIn}
            onClick={() => {
              toggleLogin()
              toggleMenuPersonalOpen()
            }}
            noLink
          />
          <Button
            label={labelCreateFreeAccount}
            onClick={() => {
              toggleRegister()
              toggleMenuPersonalOpen()
            }}
            noLink
          />
        </div>
  }

  const buttonConditional = React.useContext(LoginContext)

  return (
    <nav
      itemScope
      itemType='https://schema.org/SiteNavigationElement'
      className={`navigation-pane --is-mobile ${headerHasNoProducts ? '--no-products' : ''}`}
    >
      {mobileNavbarPhone && (
        <div className='navigation-pane__phone'>
          <Button
            color='tertiary2'
            size='S'
            label={labelCallUs}
            dataQa='mobile-call-us-button'
            link={`tel:${sitePhone}`}
          >
            <PhoneIcon />
          </Button>
        </div>
      )}
      {!mobileNavbarPhone && (
        <>
          <div
            onClick={toggleMenuHelpOpen}
            className={`navigation-pane__trigger sans-serif --small ${!isMenuHelpOpen && 'white-background'}`}
          >
            <img src={!isMenuHelpOpen ? Help : HelpOpened} alt='' />
          </div>
          <div
            onClick={toggleMenuPersonalOpen}
            className={`navigation-pane__trigger sans-serif --small ${!isMenuPersonalOpen && 'white-background'}`}
          >
            <img src={!isMenuPersonalOpen ? Personal : PersonalOpened} alt='' />
          </div>
          <div
            className={`navigation-pane__wrapper ${isMenuHelpOpen || isMenuPersonalOpen ? 'full-width' : 'width-auto'}`}
          >
            <div className={'navigation-pane__background'}>
              {isMenuHelpOpen && (
                <>
                  <NavbarItems
                    headerHasNoProducts={headerHasNoProducts}
                    isURLForm={isURLForm}
                    baseLinkForTestAB={baseLinkForTestAB}
                    tokenActive={tokenActive}
                    isMenuHelp={isMenuHelpOpen}
                    // testAB8814
                    toggleRegister={toggleRegister}
                    mobileHideDocumentsList={mobileHideDocumentsList}
                    mobileHideResources={mobileHideResources}
                    // fin testab 8814
                  />
                  {!IS_JONSNOW && (
                    <div className={'navigation-pane__contact'}>
                      <ContactBox mobile phone={sitePhone} schedule={siteSchedule} dataQa='contact-box-menu-mobile' />
                    </div>
                  )}
                  <div className='close-container'>
                    <div onClick={onClose}>{close}</div>
                  </div>
                </>
              )}
              {isMenuPersonalOpen && (
                <>
                  {!buttonConditional && userActions}
                  <div className='close-container'>
                    <div onClick={onClose}>{close}</div>
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </nav>
  )
}
