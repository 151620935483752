import { logError, logInfo } from '@legal/shared/logger'
import { CustomerBilling } from '../domain'
import { CustomerRepositoryApi } from '../infra'
import { UserCookie } from '../../../services/storage/cookies/UserCookie'

export async function getCustomerBillingUseCase({
  successCallback,
  errorCallback,
  finallyCallback
}: {
  successCallback: (response: CustomerBilling) => void
  errorCallback?: (error?: Error) => void
  finallyCallback?: () => void
}): Promise<void> {
  const userCookie = new UserCookie()
  if (!userCookie.token) return
  await CustomerRepositoryApi.getBilling()
    .then((response) => {
      logInfo('getCustomerBillingUseCase')
      successCallback(response)
    })

    .catch((error: Error | undefined) => {
      logError('getCustomerBillingUseCase', undefined, error)
      errorCallback?.(error)
    })
    .finally(() => {
      finallyCallback?.()
    })
}
