/* eslint-disable react-compiler/react-compiler */

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button } from '@npm_leadtech/legal-lib-components/Button'
import React from 'react'
import { Toggle } from '@npm_leadtech/legal-lib-components/Toggle'

import { AMPLITUDE_TEST_AB_VERSION, sendAmplitudeData } from '@legal/shared/amplitude'
import { type Application, findApplicationByUuidUseCase, updateApplicationDataUseCase } from '@legal/application'
import { FormContext, OverlayContext } from '../../../../services/utils/contexts'
import { Layout, Modal } from '../../../layout'
import { Loading, NavBar, TooltipModal } from '../../../molecules'
import { createPreFormDataToSend, setPrefillParameterInForm } from '../../../../services/utils/preForm/preForm'
import { getLastStepBeforeResumingApplication, getSectionList, validator } from '../utilities'
import { useActions, useHashFirsTime } from '../hooks'
import { useApplicationCookie, useIsMobile } from '@legal/shared/hooks'
import Eye from '../../../../assets/images/componentsSvg/eye-24-px.svg'
import { type FormTemplateDefaultProps } from './FormTemplateDefaultProps'
import { FormToTemplate } from '../../../../services/form/FormToTemplate'
import { LoginModal } from '../../LoginModal'
import { MultiStepForm } from '../../../form'
import PlusCircle from '../../../../assets/images/svg/plus-circle.svg'
import { PreformTypeFormCookie } from '../../../../services/storage/cookies/PreformTypeFormCookie'
import { ReferrerCookie } from '../../../../services/storage/cookies/ReferrerCookie'
import { RegisterModal } from '../../RegisterModal'
import { TemplateContainer } from '../../TemplateContainer'
import { UserCookie } from '../../../../services/storage/cookies/UserCookie'
import { cloneObjectOrArray } from '../../../../services/utils/cloneObjectOrArray/cloneObjectOrArray'
import { getNoRenderedFields } from '../../../../services/form/getNoRenderedFields'
import { getParamUrlValue } from '../../../../services/utils/paramsUrl'
import { initState } from '../constants'
import { newStepStructure } from '../../../../services/form/newStepStructure'
import { normalizeFormJson } from '../../../../services/form/normalizeFormJson'
import { replaceAll } from '../../../../services/utils/replaceAll'
import { transformAndSendHtml } from '../../../../services/form/transformAndSendHtml/transformAndSendHtml'
import { useConfigs } from '@legal/core/configs'
import { useModal } from '../../../../services/hooks/useModal'
import './FormTemplateDefault.scss'

const reducer = (prevState, updatedProperty): any => ({
  ...prevState,
  ...updatedProperty
})

export const FormTemplateDefault: React.FC<FormTemplateDefaultProps> = ({ data, uri, title, version }) => {
  const { API_CONFIG, IS_LOCAL_ENV, IS_STAGEPRODUCT_ENV, TARGET_ADDRESS } = useConfigs()
  const [customGoToStepObject, setCustomGoToStep] = React.useState(null)
  const [recoveredLastStep, setRecoveredLastStep] = React.useState(null)
  const itemChangedKey = React.useRef(1)
  const oldNoRenderFields = React.useRef()
  const [showOverlay, setOverlayState] = React.useState(false)
  const [disabledPreviewScroll, setDisabledPreviewScroll] = React.useState(false)
  const { modalOpen, changeModalState, closeModal } = useModal()
  const loginModal = useModal(true)
  const registerModal = useModal(true)
  const isMobile = useIsMobile(0)
  const tooltipModal = useModal(isMobile)
  const referrerCookie = new ReferrerCookie()
  const [loading, isLoaded] = React.useState(true)
  const [loadForm, setLoadForm] = React.useState(false)
  const [tooltipModalContent, setTooltipModalContent] = React.useState(null)
  const {
    applicationCookie,
    setApplicationCookieFormDriveId,
    clearApplicationCookie,
    setApplicationCookieFromEdit,
    setApplicationCookieContinueFromHome
  } = useApplicationCookie()

  const [preFormDataToSendApplication, setPreFormDataToSendApplication] = React.useState(null)
  const formData = data.strapiForm

  const formJson = normalizeFormJson(formData as Queries.StrapiFormDataFragment)
  const tempNoRenderBuffer = getNoRenderedFields(formJson.plainJson, formJson.structureJson)
  const initialTemplate = FormToTemplate(
    formData.form_template,
    formJson.plainJson,
    tempNoRenderBuffer,
    '',
    false,
    itemChangedKey
  )
  const [state, updateState] = React.useReducer(reducer, { ...initState, template: initialTemplate })
  const { form, originalForm, structure, template, noRenderFields, toggleGroupNames, loadedData } = state
  const stepsData = newStepStructure(form, structure, originalForm)
  const { groupValidator, stepValidator, fieldValidator } = validator({ stepsData, updateState, form })
  const { isHashInUrlFirstTime } = useHashFirsTime()
  const userCookie = new UserCookie()

  if (applicationCookie?.continueFromHome) {
    setApplicationCookieFormDriveId(formData?.driveId ?? undefined)
  }

  React.useEffect(() => {
    const formToRender = Object.keys(form).length === 0 && form.constructor === Object ? formJson.plainJson : form
    const tempNoRender = getNoRenderedFields(formToRender, formJson.structureJson)

    updateState({
      template: FormToTemplate(formData.form_template, formToRender, tempNoRender, '', toggleGroupNames, itemChangedKey)
    })
  }, [toggleGroupNames])

  React.useEffect(() => {
    const preformTypeFormCookie = new PreformTypeFormCookie()
    let urlProductActive = getParamUrlValue('product') ?? ''
    urlProductActive = urlProductActive.trim()
    const preformTypeFormData = preformTypeFormCookie.readItem(urlProductActive)
    let newFormJson = cloneObjectOrArray(formJson)
    newFormJson = setPrefillParameterInForm(newFormJson, preformTypeFormData)
    if (!preformTypeFormData?.firstStepSkipped) {
      setPreFormDataToSendApplication(createPreFormDataToSend(newFormJson))
    }
    const tempNoRender = getNoRenderedFields(newFormJson.plainJson, newFormJson.structureJson)
    oldNoRenderFields.current = tempNoRender
    const parsedTemplate = FormToTemplate(
      formData.form_template,
      newFormJson.plainJson,
      tempNoRender,
      '',
      toggleGroupNames,
      itemChangedKey
    )

    updateState({
      form: newFormJson.plainJson,
      originalForm,
      structure: newFormJson.structureJson,
      template: parsedTemplate,
      noRenderFields: tempNoRender,
      loadedData: true
    })

    setLoadForm(true)
  }, [])

  React.useEffect(() => {
    if (version === 'default' || undefined) {
      const eventProps = {
        document_type: formType,
        document_subtype: formSubType,
        application_ID: applicationCookie?.id,
        source: referrerCookie.getReferrer(),
        version: AMPLITUDE_TEST_AB_VERSION.PRODUCTION
      }
      sendAmplitudeData('start_form', eventProps)
    }
  }, [])

  const findApplicationByUuidSuccess = async (application: Application): Promise<void> => {
    const preformTypeFormCookie = new PreformTypeFormCookie()
    let urlProductActive = getParamUrlValue('product') ?? ''
    urlProductActive = urlProductActive.trim()
    const preformTypeFormData = preformTypeFormCookie.readItem(urlProductActive)
    const firstStepSkipped = preformTypeFormData?.firstStepSkipped
    const tempForm = { ...form }
    if (application.data === undefined) return

    Object.keys(application.data).forEach((step) => {
      Object.keys(application.data[step]).forEach((field) => {
        if (tempForm[field]) {
          if (!firstStepSkipped && preformTypeFormData?.items[field]) {
            tempForm[field].value = preformTypeFormData.items[field].value
          } else {
            tempForm[field].value = application.data[step][field]
          }
        }
      })
    })

    if (!firstStepSkipped && preformTypeFormData?.items) {
      const dataStepToSend = {}

      Object.keys(preformTypeFormData.items).forEach((item) => {
        dataStepToSend[item] = preformTypeFormData.items[item].value
      })
      await updateApplicationDataUseCase({
        applicationId: applicationCookie?.id,
        request: {
          data: dataStepToSend,
          step: stepsData?.newStepStructure?.steps[0]?.slug
        },
        finallyCallback: () => {}
      })
    }

    toggleFormRefresh(tempForm)
    if (applicationCookie?.fromEdit ?? applicationCookie?.continueFromHome) {
      const lastStep = getLastStepBeforeResumingApplication(
        application.data,
        newStepStructure(form, structure, originalForm).newStepStructure
      )
      setRecoveredLastStep(lastStep)
      setApplicationCookieFromEdit(undefined)
      setApplicationCookieContinueFromHome(undefined)
    }
  }

  React.useEffect(() => {
    const fectData = async (): Promise<void> => {
      if (!loadForm) return
      if (!applicationCookie?.id) {
        isLoaded(false)
        return
      }
      await findApplicationByUuidUseCase({
        applicationId: applicationCookie.id,
        successCallback: findApplicationByUuidSuccess,
        finallyCallback: () => {
          isLoaded(false)
        }
      })
    }
    fectData()
  }, [loadedData, loadForm])

  React.useEffect(() => {
    if (tooltipModalContent === null) return
    if (!tooltipModal.modalOpen) {
      tooltipModal.changeModalState()
    }
  }, [tooltipModalContent])

  const resetCookies = (): void => {
    clearApplicationCookie()
  }

  const sectionList = getSectionList(stepsData)

  const submitForm = (lastStepData): void => {
    if (API_CONFIG.API_PREFIX)
      transformAndSendHtml(template, lastStepData, formType, formSubType, formStateName, applicationCookie?.id)
  }

  const sectionsActivated: boolean | null = formData?.activateSections
  const { handleChange, toggleFormRefresh } = useActions({
    itemChangedKey,
    toggleGroupNames,
    formData,
    updateState,
    groupValidator,
    form,
    oldNoRenderFields,
    structure,
    state,
    resetCookies,
    applicationCookie,
    userCookie
  })
  // Update current step and structure for the template to use with scrolling (called from currently rendered step)
  const updateCurrentStepAndStructure = (step, updatedStepStructure): void => {
    updateState({
      currentStep: step,
      currentStructure: updatedStepStructure
    })
  }

  const customGoToStep = (goToStep): void => {
    setCustomGoToStep({ goToStep })
  }
  const stepsCount = stepsData.newStepStructure.steps.length

  const openOverlay = (): void => {
    setOverlayState(true)
  }

  const closeOverlay = (): void => {
    setOverlayState(false)
  }

  const toggleNames = (): void => {
    updateState({ toggleGroupNames: !toggleGroupNames })
  }

  const toogleEnablePreviewScroll = (): void => {
    setDisabledPreviewScroll(!disabledPreviewScroll)
  }

  const RefreshButton = <Toggle toggleCheck={toggleGroupNames} toggleAction={toggleNames} label='Hide/Show' />

  const buttonEnablePreviewScroll = (
    <Toggle
      toggleCheck={disabledPreviewScroll}
      toggleAction={toogleEnablePreviewScroll}
      label='Enable/Disable Preview scroll'
    />
  )

  const productName = formData?.formType?.typeId
  const formType = productName?.toLowerCase().split(' ').join('-')

  const formStateName = formData?.selectState ? formData?.selectState?.name?.toLowerCase().split(' ').join('-') : null

  const urlState = replaceAll(formData?.name?.toLowerCase() ?? '', ' ', '-')
  const subtypeState = replaceAll(urlState, `-${formType}`, '')
  const formSubType = formData?.subtype ? formData?.subtype?.subtype?.toLowerCase().split(' ').join('-') : subtypeState

  const toggleOpenRegisterCloseLogin = (): void => {
    loginModal.changeModalState()
    registerModal.changeModalState()
  }

  const toggleOpenLoginCloseRegister = (): void => {
    loginModal.changeModalState()
    registerModal.changeModalState()
  }

  const tabletPreviewButton = {
    previewButton: (
      <Button
        color='secondary3'
        size='S'
        givenClass='form__header__button'
        noLink
        onClick={changeModalState}
        label='Preview'
      >
        <Eye />
      </Button>
    )
  }

  // It can happen that the number of steps refreshes before currentStep, causing the latter to fall outside the array of steps.
  // We check this to avoid temporarily rendering the navbar in that case
  const currentStepSmallerThanAllSteps = stepsData.newStepStructure.steps ? state.currentStep <= stepsCount : false

  const [isBillOfSaleTitle, setIsBillOfSaleTitle] = React.useState(false)
  React.useEffect(() => {
    const url = window ? window.location.href : ''
    const urlContainBoS = url.includes('bill-of-sale')
    setIsBillOfSaleTitle(urlContainBoS)
  }, [])

  const billOfSaleTitle = 'Make your sale final and legal'

  const MainComponent =
    !loading && loadForm ?
      <FormContext.Provider
        value={{
          handleChange,
          form,
          structure,
          formUrl: formData?.url,
          driveId: formData?.driveId,
          steps: formData?.stepsForm || null,
          state: formData?.selectState ? formData.selectState.value : null,
          sectionsActivated,
          closeTooltipModal: tooltipModal.closeModal,
          setTooltipModalContent,
          formName: formData?.name,
          formStateName,
          formType,
          validation: fieldValidator,
          noRenderFields,
          submitForm,
          stepValidator,
          updateCurrentStepAndStructure,
          isValidGroup: groupValidator,
          toggleGroupNames,
          originalForm,
          uri,
          userCookie,
          resetCookies,
          customGoToStep,
          customGoToStepFunc: customGoToStepObject ? customGoToStepObject.goToStep : null,
          fromEdit: applicationCookie?.fromEdit,
          template
        }}
      >
        <OverlayContext.Provider value={showOverlay}>
          {registerModal.modalOpen && (
            <RegisterModal
              toggleOpenLoginCloseRegister={toggleOpenLoginCloseRegister}
              isRegisterModal
              closeFunction={registerModal.closeModal}
              onToggleModal={registerModal.changeModalState}
              loginModalOpen={loginModal.modalOpen}
              component={<TemplateContainer template={template} />}
            />
          )}
          {loginModal.modalOpen && (
            <LoginModal
              toggleOpenRegisterCloseLogin={toggleOpenRegisterCloseLogin}
              isLoginModal
              closeFunction={loginModal.closeModal}
              onToggleModal={loginModal.changeModalState}
              registerModalOpen={registerModal.modalOpen}
              component={<TemplateContainer template={template} />}
            />
          )}
          <TooltipModal
            modalOpen={tooltipModal.modalOpen}
            closeFunction={tooltipModal.closeModal}
            title={tooltipModalContent?.title}
            text={tooltipModalContent?.text}
          />
          <div id='form__template__wrapper' className='flex form__template__wrapper'>
            {state.currentStructure.steps && currentStepSmallerThanAllSteps && (
              <NavBar
                customGoToStep={customGoToStepObject}
                allSteps={stepsData.newStepStructure.steps}
                currentStep={state.currentStep}
                sectionsActivated={sectionsActivated}
                sections={sectionList}
                formData={form}
                stepValidator={stepValidator}
                isValidGroup={groupValidator}
                submitForm={submitForm}
                onClose={closeOverlay}
                documentTitle={title}
                {...tabletPreviewButton}
              />
            )}
            <div className={'form__template__wrapper__inner'}>
              <div className={'form__template__wrapper__inner__nopadding'}>
                <div className='form__layout'>
                  <div className={'form__template__wrapper__inner__subtitle'}>
                    <div className='form__header__headings'>
                      <h1 id='form__title' className={'form__title serif'}>
                        {isBillOfSaleTitle ? billOfSaleTitle : title}
                      </h1>
                    </div>
                    <div className='form__header__options'>
                      <Button
                        color='secondary3'
                        size='S'
                        givenClass='form__header__button'
                        noLink
                        onClick={changeModalState}
                        label='Preview'
                      >
                        <Eye />
                      </Button>
                      <div className='navbar-toggle' onClick={openOverlay}>
                        <span className='navbar-toggle__text'>{`Completed ${state.currentStep}/${stepsCount}`}</span>
                        <img className='navbar-toggle__icon' src={PlusCircle} width='24' height='24' alt='' />
                      </div>
                    </div>
                  </div>
                </div>
                {(IS_LOCAL_ENV || IS_STAGEPRODUCT_ENV) && (
                  <div className='control-panel'>
                    {RefreshButton}
                    {buttonEnablePreviewScroll}
                  </div>
                )}
                <div className={'form__template__isolate'}>
                  <div className={'form-container'}>
                    <MultiStepForm
                      formType={formType}
                      formSubType={formSubType}
                      form={form}
                      isHashInUrlFirstTime={isHashInUrlFirstTime}
                      preFormDataToSendApplication={preFormDataToSendApplication}
                      recoveredLastStep={recoveredLastStep ?? undefined}
                    />
                  </div>
                  <TemplateContainer
                    template={template}
                    documentType={formType}
                    categoryProduct={formSubType}
                    dinamicScroll={!disabledPreviewScroll}
                  />
                </div>
              </div>
            </div>
          </div>

          {modalOpen && (
            <Modal title={title} closeFunction={closeModal}>
              <TemplateContainer template={template} />
            </Modal>
          )}
        </OverlayContext.Provider>
      </FormContext.Provider>
    : <Loading />

  return (
    <div className='formtemplate'>
      <Layout
        hideMobileHeader={true}
        headerFullWidth={true}
        headerBackground={'white'}
        headerHasNoProducts={true}
        showMobileMicroFooter={true}
        noFooter={true}
        toggleRegister={registerModal.changeModalState}
        toggleLogin={loginModal.changeModalState}
        registerModalOpen={registerModal.modalOpen}
        loginModalOpen={loginModal.modalOpen}
        environment={TARGET_ADDRESS}
        disclaimerLinks={true}
        mobileHideResources={true}
      >
        {MainComponent}
      </Layout>
    </div>
  )
}
