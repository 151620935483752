import { logError, logInfo } from '@legal/shared/logger'
import { type ApiError } from '@legal/shared/HttpClient'
import { CustomerRepositoryApi } from '../infra'
import { ResetPasswordRequest } from './interfaces'
import { UserCookie } from '../../../services/storage/cookies/UserCookie'

export async function resetPasswordUseCase({
  successCallback,
  errorCallback,
  finallyCallback,
  request
}: {
  successCallback: () => void
  errorCallback?: (error?: ApiError) => void
  finallyCallback?: () => void
  request: ResetPasswordRequest
}): Promise<void> {
  const user = new UserCookie()
  if (user.token) {
    logError('ResetPasswordUseCase', { error: 'User already authenticated' })
    return
  }
  await CustomerRepositoryApi.resetPassword(request.urlToken, request.password)
    .then(() => {
      logInfo('ResetPasswordUseCase')
      successCallback()
    })

    .catch((error: ApiError | undefined) => {
      logError(`ResetPasswordUseCase: ${error?.message}`)
      errorCallback?.(error)
    })
    .finally(() => {
      finallyCallback?.()
    })
}
