/* eslint-disable @typescript-eslint/no-explicit-any */

import { Button } from '@npm_leadtech/legal-lib-components/Button'
import React from 'react'

import Form from '../../../templates/FormTemplate'
import { Modal } from '../../layout'
import { TextInput } from '../../form'
import { type UpdatePasswordModalProps } from './UpdatePasswordModalProps'
import { UserCookie } from '../../../services/storage/cookies/UserCookie'
import { getProvidersErrorMessage } from '@legal/shared/utils/getProvidersMessages'
import { updatePasswordUseCase } from '@legal/customer'
import './UpdatePasswordModal.scss'

export const UpdatePasswordModal: React.FC<UpdatePasswordModalProps> = ({
  closeFunction,
  updateSnackbar,
  changePasswordData,
  endpointsErrors
}) => {
  const errorMessagesCurrentPassword = changePasswordData.currentPassword?.errorMessages?.internal.content
  const errorMessagesCurrentPasswordJson: { default: string; error0: string } =
    errorMessagesCurrentPassword && JSON.parse(errorMessagesCurrentPassword)

  const errorMessagesnewPassword = changePasswordData.newPassword?.errorMessages?.internal.content
  const errorMessagesNewPasswordJson: { default: string; code0: string } =
    errorMessagesnewPassword && JSON.parse(errorMessagesnewPassword)

  const errorMessagesConfirmPassword = changePasswordData.confirmPassword?.errorMessages?.internal.content
  const errorMessagesConfirmPasswordJson: { default: string; code0: string } =
    errorMessagesConfirmPassword && JSON.parse(errorMessagesConfirmPassword)

  const [form, setForm] = React.useState({
    current_password: {
      value: '',
      validate: true,
      isValidGroup: true,
      name: 'current_password',
      type: 'password',
      error: errorMessagesCurrentPasswordJson.default ?? '',
      required: false
    },
    new_password: {
      value: '',
      validate: true,
      isValidGroup: true,
      name: 'new_password',
      type: 'password',
      error: errorMessagesNewPasswordJson.default ?? '',
      required: false
    },
    confirm_new_password: {
      value: '',
      validate: true,
      isValidGroup: true,
      name: 'confirm_new_password',
      type: 'password',
      error: errorMessagesConfirmPasswordJson.default ?? '',
      required: true
    }
  })

  const userCookie = new UserCookie()

  const updateField = (event): void => {
    const formData = { ...form }

    formData[event.target.name].value = event.target.value
    formData[event.target.name].validate = true
    formData[event.target.name].isValidGroup = true

    setForm(formData)

    if (!form.confirm_new_password.validate) {
      formValidator()
    }
  }

  const formValidator = (): boolean => {
    const formData = { ...form }
    let validate = true

    Object.keys(formData).forEach(function (objectKey) {
      const eventMock = {
        target: {
          name: objectKey,
          value: formData[objectKey].value
        }
      }

      if (!fieldValidator(eventMock)) {
        validate = false
      }
    })

    return validate
  }

  const fieldValidator = (event): any => {
    const objectKey = event.target.name
    const value = event.target.value
    const formData = { ...form }

    if (value.length === 0 || value.length < 8) {
      formData[objectKey].validate = false
      formData[objectKey].error = errorMessagesCurrentPasswordJson.default ?? ''
    } else if (objectKey === 'confirm_new_password' && value !== formData.new_password.value) {
      formData.new_password.validate = false
      formData.new_password.error = errorMessagesNewPasswordJson.code0 ?? ''

      formData.confirm_new_password.validate = false
      formData.confirm_new_password.error = errorMessagesNewPasswordJson.code0 ?? ''
    } else {
      formData.new_password.validate = true
      formData.confirm_new_password.validate = true
    }

    setForm(formData)

    return formData[objectKey].validate
  }

  const submitHandle = async (e): Promise<void> => {
    e.preventDefault()
    const validate = formValidator()
    const formData = { ...form }
    if (!validate) return
    await updatePasswordUseCase({
      request: {
        newPassword: form.new_password.value,
        oldPassword: form.current_password.value
      },
      successCallback: (newToken) => {
        if (newToken) userCookie.token = newToken
        closeFunction()
        updateSnackbar()
      },
      errorCallback: (error) => {
        if (error?.code === '412') {
          const errorMessage412 = getProvidersErrorMessage(endpointsErrors ?? [], '412') ?? ''
          formData.current_password.validate = false
          formData.current_password.error = errorMessage412
          setForm(formData)
        }
      }
    })
  }

  return (
    <Modal
      as={Form}
      onSubmit={(e) => submitHandle(e)}
      title={changePasswordData.title ?? 'Change Password'}
      size='XS'
      type='default'
      closeFunction={closeFunction}
    >
      <p className='modal--update-password__body__text'>{changePasswordData.description}</p>
      <form className='modal--update-password__form' onSubmit={submitHandle}>
        <TextInput
          name='current_password'
          onChange={updateField}
          onBlur={fieldValidator}
          errorMessage={form.current_password.error}
          validate={form.current_password.validate}
          value={form.current_password.value}
          type={form.current_password.type}
          label={changePasswordData.currentPassword?.label ?? ''}
          isValidGroup={form.current_password.isValidGroup}
        />
        <TextInput
          name='new_password'
          onChange={updateField}
          onBlur={fieldValidator}
          placeholder={changePasswordData.newPassword?.placeholder ?? ''}
          errorMessage={form.new_password.error}
          validate={form.new_password.validate}
          value={form.new_password.value}
          type={form.current_password.type}
          label={changePasswordData.newPassword?.label ?? ''}
          isValidGroup={form.current_password.isValidGroup}
        />
        <TextInput
          name='confirm_new_password'
          onChange={updateField}
          onBlur={fieldValidator}
          errorMessage={form.confirm_new_password.error}
          validate={form.confirm_new_password.validate}
          value={form.confirm_new_password.value}
          type={form.current_password.type}
          label={changePasswordData.confirmPassword?.label ?? ''}
          isValidGroup={form.current_password.isValidGroup}
        />
        <div className={'buttons__wrapper'}>
          <Button
            color='secondary2'
            onClick={closeFunction}
            noLink
            dataQa={'cancel-button'}
            label={changePasswordData.ctaCancel ?? ''}
          />
          <Button
            color='primary3'
            noLinkNoFunc
            dataQa={'save-changes-button'}
            label={changePasswordData.ctaSave ?? ''}
          />
        </div>
      </form>
    </Modal>
  )
}
