import { BillCard } from '@npm_leadtech/legal-lib-components/BillCard'
import { Button } from '@npm_leadtech/legal-lib-components/Button'
import React from 'react'
import { SearchSelect } from '@npm_leadtech/legal-lib-components/SearchSelect'

import { useCustomerPaymentYears, useFilteredInvoices } from '@legal/customer'
import { type BillingDocumentsProps } from './BillingDocumentsProps'
import { Section } from '../Section'
import getFormattedAmount from '../../../modules/shared/utils/currency/getFormattedAmount'
import { useQueryCommonsText } from '@legal/shared/data/graphql/queries/useQueryCommonsText'
import './BillingDocuments.scss'

export const BillingDocuments: React.FC<BillingDocumentsProps> = ({ invoices, filterInvoices }) => {
  const [collapsed, updateListState] = React.useState(true)
  const { filteredInvoices } = useFilteredInvoices(invoices, collapsed)
  const { currrencySymbol } = useQueryCommonsText()
  const currentYear = new Date().getFullYear()
  const { years } = useCustomerPaymentYears(invoices, currentYear)
  const filterDocuments = React.useCallback(
    ({
      target: {
        value: { value: selectedYear }
      }
    }): void => {
      filterInvoices({ invoices, selectedYear })
    },
    [invoices, filterInvoices]
  )

  const toggleList = React.useCallback((): void => {
    updateListState(!collapsed)
  }, [collapsed])

  if (!invoices.length) return null

  return (
    <Section
      title='Billing Documents'
      className='section--list-item'
      optionalComponent={
        years.length > 1 && (
          <SearchSelect
            onChange={filterDocuments}
            customClass='search-select--bare search-select--small'
            items={years}
            name='billing_year'
            value={currentYear}
            validate
            isValidGroup
          />
        )
      }
    >
      {filteredInvoices.length > 0 ?
        <>
          <ul>
            {filteredInvoices.map((invoice) => (
              <li key={invoice.id} className='billing-documents__document'>
                <BillCard
                  date={invoice.parsedDate}
                  amount={getFormattedAmount({ amount: invoice.amount, currrencySymbol })}
                  subscriptionType={invoice.subscriptionTypeName}
                ></BillCard>
              </li>
            ))}
          </ul>
          {invoices.length > 4 && (
            <div className='billing-documents__footer'>
              <Button
                color='tertiary'
                noLink
                label={collapsed ? 'Show more' : 'Show less'}
                onClick={toggleList}
                dataQa='billing-documents-footer'
              />
            </div>
          )}
        </>
      : <div>No billing documents exist for the selected year</div>}
    </Section>
  )
}
