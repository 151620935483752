/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable no-console */

import { Button } from '@npm_leadtech/legal-lib-components/Button'
import React from 'react'
import ReactDOMServer from 'react-dom/server'
import classNames from 'classnames'

import { AMPLITUDE_TEST_AB_VERSION, sendAmplitudeData } from '@legal/shared/amplitude'
import { type UpdateApplicationDataRequest, updateApplicationDataUseCase } from '@legal/application'
import { FormContext } from '../../../services/utils/contexts'
import { GetFormsByName } from '@legal/form'
import { Group } from './Group'
import { LastStepRenderer } from '../LastStepRenderer'
import { type MainFormStepsMessiProps } from './MainFormStepsMessiProps'
import { PreformTypeFormCookie } from '../../../services/storage/cookies/PreformTypeFormCookie'
import { arrayCompareEqual } from '../../../services/utils/arrayCompare'
import { getParamUrlValue } from '../../../services/utils/paramsUrl'
import { parseLabel } from '../../../services/form/parse'
import { referenceHandler } from '../../../services/form/referenceHandler'
import { useApplicationCookie } from '@legal/shared/hooks'
import { useConfigs } from '@legal/core/configs'
import { useQueryFormPage } from '@legal/shared/data/graphql'
import { useTransformAndSendHtml } from './hooks/useTransormAndSendHtml'
import './MainFormStepsMessi.scss'

export const MainFormStepsMessi: React.FC<MainFormStepsMessiProps> = ({
  currentStep,
  totalSteps,
  goToStep,
  previousStep,
  nextStep,
  handleStepActive,
  structure,
  formType,
  formSubType,
  formStateName,
  disabledNextStep = false,
  isHashInUrlFirstTime,
  hashKey,
  indexItem,
  callbackBottomOverlay,
  recoveredLastStep,
  changeRecoveredLastStep = false,
  handleChangeRecoveredLastStep,
  activeForms,
  typeFormId,
  showPreviewLastStep = false,
  benefitsListToShow,
  setIsLastStepActive,
  setFaqs,
  faqs,
  template,
  applicationId,
  version,
  activeTestABVersion
}) => {
  const { API_CONFIG } = useConfigs()
  const {
    applicationCookie,
    setApplicationCookieLastStep,
    setApplicationCookieFormUrl,
    setApplicationCookieFormName,
    setApplicationCookieFormDriveId
  } = useApplicationCookie()
  const { multiStepFormWizard, printAndDownload } = useQueryFormPage()
  const formContext = React.useContext(FormContext)
  const [form] = React.useState(formContext.form)
  const [stepKey] = React.useState(currentStep - 1)
  const { transformAndSendHtml, loadingDataToPaymentPage } = useTransformAndSendHtml()
  const preFormNextStep = (): void => {
    if (window.location.search.includes('&')) {
      goToStep(2)
    }
  }

  const urlProductActive = getParamUrlValue('product')?.trim() ?? ''

  React.useEffect(() => {
    const preformTypeFormCookie = new PreformTypeFormCookie()
    const preformTypeFormData = preformTypeFormCookie.readItem(urlProductActive)
    formContext.updateCurrentStepAndStructure(currentStep, structure)
    if (isHashInUrlFirstTime()) {
      if (preformTypeFormData && !preformTypeFormData.firstStepSkipped) {
        preformTypeFormData.firstStepSkipped = true
        preformTypeFormCookie.newItem(urlProductActive, preformTypeFormData)
      }
      preFormNextStep()
      formContext.customGoToStep(goToStep)
    } else {
      if (preformTypeFormData && !preformTypeFormData.firstStepSkipped) {
        preformTypeFormData.firstStepSkipped = true
        preformTypeFormCookie.newItem(urlProductActive, preformTypeFormData)
        preFormNextStep()
      } else {
        formContext.customGoToStep(goToStep)
      }
    }
    if (formContext.formName) setApplicationCookieFormName(formContext.formName)
    handleStepActive?.(indexItem)
  }, [])

  React.useEffect(() => {
    if (typeFormId) {
      GetFormsByName.Execute({ name: encodeURI(typeFormId) })
        .then((response) => {
          createArrayStates(response.result)
        })
        .catch((err: unknown) => {
          console.log(err)
        })
    }
  }, [])

  React.useEffect(() => {
    if (recoveredLastStep && !changeRecoveredLastStep) {
      goToStep(recoveredLastStep)

      handleChangeRecoveredLastStep(true)
    }
  }, [recoveredLastStep])

  React.useEffect(() => {
    const navStepButtons = !isLastStep ? renderButtons : null
    callbackBottomOverlay(navStepButtons)
  }, [])

  const createArrayStates = (originalStatesArray): void => {
    if (originalStatesArray) {
      const arrayStates = []

      originalStatesArray.map((item) => {
        const activeForm = activeForms.find((form) => form.url === item.slugUrl)
        if (activeForm) {
          const newState = item.name.replace('Power of Attorney', '')
          arrayStates.push({ label: newState, value: newState.trim().replace(' ', '-').toLowerCase() })
        }
      })
    }
  }

  const nextStepForm = (): void => {
    if (!disabledNextStep) {
      nextStepHandler()
      if (formContext.stepValidator(stepKey) && formContext.isValidGroup(stepKey)) {
        nextStep()
        window.scrollTo(0, 0)
        const eventProps = {
          document_type: formType,
          document_subtype: formSubType ?? formStateName ?? '',
          application_ID: applicationCookie?.id,
          section_name: hashKey,
          version: AMPLITUDE_TEST_AB_VERSION.PRODUCTION
        }
        sendAmplitudeData('complete_section', eventProps)
      }
    }

    if (hashKey === 'state') {
      const eventPropsState = {
        document_subtype: formSubType ?? formStateName ?? '',
        application_ID: applicationCookie?.id
      }
      sendAmplitudeData('start_state', eventPropsState)
    }
  }

  // istest8814
  const checkFieldRlaType = (dataToSend): void => {
    const { fieldName } = dataToSend.data
    if (dataToSend.stepNumber === 3 && dataToSend.data[fieldName]) {
      const eventProps = {
        rla_type: dataToSend.data[fieldName],
        version: AMPLITUDE_TEST_AB_VERSION.PRODUCTION
      }
      sendAmplitudeData('step_typerla', eventProps)
    }
  }
  // fin testab 8814

  const nextStepHandler = async (stepData = step): Promise<void> => {
    setApplicationCookieLastStep(currentStep)
    const request: UpdateApplicationDataRequest = {
      data: getDataFromStep(stepData),
      step: hashKey,
      stepNumber: currentStep,
      html: ReactDOMServer.renderToStaticMarkup(formContext.template),
      testAbIdentifier: activeTestABVersion
    }
    checkFieldRlaType(request)
    if (applicationCookie?.form?.driveId !== formContext.driveId) {
      setApplicationCookieFormDriveId(formContext.driveId)
    }
    if (!applicationCookie?.id) return
    await updateApplicationDataUseCase({
      applicationId: applicationCookie.id,
      request,
      successCallback: () => {
        setApplicationCookieFormUrl(formContext.formUrl)
      }
    })
  }

  const getDataFromStep = (stepData = step): any => {
    let data = {}

    stepData.groups.map((group) => {
      group.fields.map((field) => {
        const fieldName = form[field].name
        const fieldValue = form[field].value
        data[fieldName] = fieldValue
        data.fieldName = fieldName
      })
    })

    if (stepData.groups.length === 0) {
      data = {
        isTestAB8814: true
      }
    }

    return data
  }

  const previousStepForm = (): void => {
    previousStep()
    window.scrollTo(0, 0)
  }

  const changeWindowsLocation = (): void => {
    const eventProps = {
      document_type: formType,
      document_subtype: formSubType ?? formStateName ?? '',
      application_ID: applicationCookie?.id ?? applicationId,
      version: AMPLITUDE_TEST_AB_VERSION.PRODUCTION
    }
    sendAmplitudeData('complete_application', eventProps)

    const lastStepData = {
      step,
      data: getDataFromStep()
    }
    if (API_CONFIG.API_PREFIX) {
      transformAndSendHtml({ lastStepData, activeTestABVersion })
    }
  }

  const isLastStep = currentStep >= totalSteps

  const renderButtons = (
    <>
      {currentStep > 1 && (
        <div className={'button-wrapper button__back'}>
          <Button
            color='secondary'
            label={multiStepFormWizard?.btnBackLabel ?? ''}
            noLink
            onClick={previousStepForm}
            functionParameters={''}
            dataQa={'back'}
          />
        </div>
      )}
      {!isLastStep && (
        <div className={'button-wrapper button__next'}>
          <Button
            label={multiStepFormWizard?.btnSaveAndContinueLabel ?? ''}
            noLink
            onClick={nextStepForm}
            functionParameters={''}
            dataQa={'saveAndContinue'}
          />
        </div>
      )}
      {isLastStep && (
        <div className={'button-wrapper button__complete'}>
          <Button
            label={multiStepFormWizard?.btnCompleteAplicationLabel ?? ''}
            noLink
            onClick={changeWindowsLocation}
            dataQa={'completeApplication'}
            functionParameters={''}
          />
        </div>
      )}
    </>
  )

  const renderStep = (step): React.JSX.Element | null => {
    let fieldLabel = step.label
    const Faqs = []
    fieldLabel = parseLabel({ label: fieldLabel, form })

    step?.groups?.forEach((group) => {
      if (referenceHandler(group, form, structure) && group?.faq?.length) {
        Faqs.push(group)
      }
    })

    if (setFaqs) {
      if (!arrayCompareEqual(faqs, Faqs)) {
        setFaqs(Faqs)
      }
    }
    if (setIsLastStepActive) {
      setIsLastStepActive(currentStep === totalSteps)
    }

    const classFormAndFaqs = classNames({
      'form-and-faqs': true,
      '--istestAB8814': true,
      '--last-step': currentStep === totalSteps
    })

    return (
      <section className={classFormAndFaqs}>
        <div className='form-step'>
          {showPreviewLastStep ?
            <LastStepRenderer
              formContext={formContext}
              step={step}
              previousStepForm={previousStepForm}
              getDataFromStep={getDataFromStep}
              applicationId={applicationId}
              template={template}
              formType={formType}
              formSubType={formSubType}
              draftActive={true}
              benefitsListToShow={benefitsListToShow}
              changeWindowsLocation={changeWindowsLocation}
              loadingDataToPaymentPage={loadingDataToPaymentPage}
              version={version}
              callbackBottomOverlay={callbackBottomOverlay}
              printAndDownload={printAndDownload}
            />
          : <>
              <h2 className={'form-step__title sans-serif --large'}>{fieldLabel}</h2>
              {step.groups.length > 0 &&
                step.groups.map((group) => {
                  return <Group key={group.name} group={group} hashKey={hashKey} />
                })}
              <section className={'form-step-buttons'}>
                {renderButtons}
                {!isLastStep && (
                  <div className={'button-wrapper button__skip'}>
                    <Button
                      color='styleless'
                      label={multiStepFormWizard?.btnSkipStepLabel ?? ''}
                      noLink
                      onClick={nextStepForm}
                      dataQa={'skipStep'}
                    />
                  </div>
                )}
              </section>
            </>
          }
        </div>
      </section>
    )
  }

  const step = structure.steps[stepKey]

  return <>{renderStep(step)}</>
}
