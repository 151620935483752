import { logError, logInfo } from '@legal/shared/logger'
import { type ApiError } from '@legal/shared/HttpClient'
import { CustomerRepositoryApi } from '../infra'

export async function updateEmailUseCase({
  successCallback,
  errorCallback,
  finallyCallback,
  request
}: {
  successCallback: (newToken: string) => void
  errorCallback?: (error: ApiError) => void
  finallyCallback?: () => void
  request: string
}): Promise<void> {
  await CustomerRepositoryApi.updateEmail(request)
    .then((newToken) => {
      logInfo('UpdateEmailUseCase')
      successCallback(newToken)
    })
    .catch((error: ApiError) => {
      logError(`UpdateEmailUseCase: ${error?.message}`)
      errorCallback?.(error)
    })
    .finally(() => {
      finallyCallback?.()
    })
}
