import { CreateCustomerRequest, type CreateCustomerResponse } from '../../application'
import { ENDPOINTS } from './Endpoints'
import { post } from '@legal/shared/HttpClient'

export async function createCustomer({
  email,
  password,
  applicationId,
  subscriptionId
}: CreateCustomerRequest): Promise<CreateCustomerResponse> {
  const body = { email, password, applicationId, subscriptionId }
  if (!applicationId) delete body.applicationId
  if (!subscriptionId) delete body.subscriptionId
  return await post<CreateCustomerResponse>(ENDPOINTS.CUSTOMER, body)
}
