import { Customer } from '../../domain'
import { CustomerInfoData } from '../dataObject'

export function CustomerToCustomerData(customer: Partial<Customer>): Partial<CustomerInfoData> {
  return {
    firstName: customer.firstName ?? '',
    lastName: customer.lastName ?? '',
    ...(customer.email && { email: customer.email }),
    ...(customer.password && { password: customer.password }),
    companySector: customer.company?.sector ? customer.company.sector : null,
    companySize: customer.company?.size ? customer.company.size : null,
    ...(customer.type && { type: customer.type })
  }
}
