export const appendHeadScript = (scriptText: string, scriptId: string): boolean => {
  try {
    const existentScript = document.getElementById(scriptId) as HTMLScriptElement | undefined
    const script = existentScript || document.createElement('script')
    script.id = scriptId
    script.innerText = scriptText
    script.crossOrigin = 'anonymous'
    document.head.appendChild(script)

    return true
  } catch {
    return false
  }
}

export const hotjarScript = ({ hotjarID, async = true }: { hotjarID: number; async?: boolean }): void => {
  const hasWindow = typeof window !== 'undefined'

  if (!hasWindow) throw Error('Hotjar depends on window. Window is undefined.')

  const hotjarScriptCode = `(function (c, s, q, u, a, r, e) {c.hj = c.hj || function() {(c.hj.q = c.hj.q || []).push(arguments)};c._hjSettings = { hjid: a };r = s.getElementsByTagName('head')[0];e = s.createElement('script');e.async = ${async};e.src = q + c._hjSettings.hjid + u;r.appendChild(e);})(window, document, 'https://static.hj.contentsquare.net/c/csq-', '.js', ${hotjarID})`
  const isAppended = appendHeadScript(hotjarScriptCode, 'hotjar-init-script')

  if (!isAppended) throw Error('Hotjar initialization failed!')
}
