import { BoxForm } from '@npm_leadtech/legal-lib-components/BoxForm'
import React from 'react'

import { ForgotPasswordModal } from '../ForgotPasswordModal'
import { type LoginModalProps } from './LoginModalProps'
import { SignIn } from '../../molecules'
import { replaceValues } from '../../../services/utils/replaceAll'
import { useAllSiteMetadata } from '@legal/shared/hooks/useAllSiteMetadata'
import { useModal } from '../../../services/hooks/useModal'
import { useQuerySharedComponentsFromPaymentPage } from '@legal/shared/data/graphql'

import './LoginModal.scss'

export const LoginModal: React.FC<LoginModalProps> = ({
  closeFunction,
  toggleOpenRegisterCloseLogin,
  paymentPage,
  noBottomSection = false,
  actionLoginSucess,
  loginNoApplicationId = false,
  isTestAB8814 = false
}) => {
  const { SITE_NAME } = useAllSiteMetadata()
  const data = useQuerySharedComponentsFromPaymentPage()
  const [forgotPassword, setForgotPassword] = React.useState(false)
  const forgotPasswordModal = useModal(true)
  const loginModal = React.useRef<HTMLDivElement | null>(null)

  const togglePasswordModal = (): void => {
    setForgotPassword(!forgotPassword)
  }

  const Form = (
    <SignIn
      togglePasswordModal={togglePasswordModal}
      isModal
      closeModal={closeFunction}
      paymentPage={paymentPage}
      actionLoginSucess={actionLoginSucess}
      loginNoApplicationId={loginNoApplicationId}
      isTestAB8814={isTestAB8814}
    />
  )

  const boxFooter = {
    text: replaceValues(data.loginModal?.footerText, { SITE_NAME }) ?? `New to ${SITE_NAME}?`,
    button: {
      label: data.loginModal?.footerCta,
      onClick: toggleOpenRegisterCloseLogin,
      noLink: true
    }
  }

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handleClickOutside = (event): void => {
    const clickOutOfSelect = loginModal.current && !loginModal.current.contains(event.target)

    if (clickOutOfSelect) {
      closeFunction()
    }
  }

  return (
    <>
      {forgotPassword ?
        <ForgotPasswordModal
          closeModal={closeFunction}
          closePasswordModal={forgotPasswordModal.closeModal}
          togglePasswordModal={togglePasswordModal}
          noBottomSection={noBottomSection}
        />
      : <div className='login-modal'>
          <div ref={loginModal} className='main-container'>
            <div className='box-form-container'>
              <BoxForm
                title={data.loginModal?.title ?? ''}
                body={Form}
                footer={!noBottomSection ? boxFooter : undefined}
                closeInsideModal
                closeModalAndReloadPage={closeFunction}
              />
            </div>
          </div>
        </div>
      }
    </>
  )
}
