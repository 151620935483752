/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { Button } from '@npm_leadtech/legal-lib-components/Button'
import { Feedback } from '@npm_leadtech/legal-lib-components/Feedback'
import React from 'react'
import { navigate } from 'gatsby'

import { FormatHTMLAstChildren } from '../FormatHTMLAstChildren'
import { TextInput } from '../../form'
import { UserCookie } from '../../../services/storage/cookies/UserCookie'
import { getParamUrlValue } from '../../../services/utils/paramsUrl'
import { resetPasswordUseCase } from '@legal/customer'
import './ResetPassword.scss'

interface ResetPasswordProps {
  resetPasswordData: Record<string, any>
}

export const ResetPassword: React.FC<ResetPasswordProps> = ({ resetPasswordData }) => {
  const [formData, setFormData] = React.useState({
    password: {
      value: '',
      validate: true,
      error: resetPasswordData.minCharactersError
    },
    confirmPassword: {
      value: '',
      validate: true,
      error: resetPasswordData.minCharactersError
    }
  })

  const userTokenCookie = new UserCookie()

  const [showError, setShowError] = React.useState(false)
  const [showSuccess, setShowSuccess] = React.useState(false)

  const onChange = (e): void => {
    const target = e.target
    const value = target.value
    const name = target.name
    const tempForm = { ...formData }

    tempForm[name].value = value

    setFormData(tempForm)
  }

  const submitForm = async (): Promise<void> => {
    if (formData.password.value !== formData.confirmPassword.value) {
      const tempForm = { ...formData }
      tempForm.password.error = resetPasswordData.passwordMatchError
      tempForm.password.validate = false
      tempForm.confirmPassword.error = resetPasswordData.passwordMatchError
      tempForm.confirmPassword.validate = false
      setFormData(tempForm)
    } else if (formData.password.value.length < 8) {
      const tempForm = { ...formData }
      tempForm.password.error = resetPasswordData.minCharactersError
      tempForm.password.validate = false
      tempForm.confirmPassword.error = resetPasswordData.minCharactersError
      tempForm.confirmPassword.validate = false
      setFormData(tempForm)
    } else {
      await resetPasswordUseCase({
        request: {
          urlToken: getParamUrlValue('token'),
          password: formData.password.value
        },
        successCallback: () => {
          userTokenCookie.token = null
          setShowSuccess(true)
        },
        errorCallback: () => {
          setShowError(true)
        }
      })
    }
  }

  const resetErrors = (e): void => {
    const target = e.target
    const name = target.name
    const tempForm = { ...formData }
    tempForm[name].validate = true
    setFormData(tempForm)
  }

  const showForm = !showError && !showSuccess

  const resetForm = (): void => {
    setShowError(false)
    setShowSuccess(false)
  }

  const handleGoToLogin = (): void => {
    navigate('/login')
  }

  const feedback = {
    error: {
      theme: resetPasswordData.somethingWrongErrorToast.type,
      title: resetPasswordData.somethingWrongErrorToast.title,
      text: resetPasswordData.somethingWrongErrorToast.text,
      button: {
        label: resetPasswordData.somethingWrongErrorToast.buttonText,
        onClick: resetForm,
        noLink: true
      }
    },
    success: {
      theme: resetPasswordData.successToast.type,
      title: resetPasswordData.successToast.title,
      text: resetPasswordData.successToast.text,
      button: {
        label: resetPasswordData.successToast.buttonText,
        givenClass: 'stretch-button',
        onClick: handleGoToLogin,
        noLink: true
      }
    }
  }

  return (
    <div className='reset-password'>
      {showForm && (
        <div>
          <TextInput
            type={'password'}
            placeholder={resetPasswordData.passwordInputField.placeholder}
            value={formData.password.value}
            name={'password'}
            label={resetPasswordData.passwordInputField.label}
            isValidGroup={true}
            validate={formData.password.validate}
            errorMessage={formData.password.error}
            required={false}
            onChange={onChange}
            onClick={resetErrors}
          />
          <TextInput
            type={'password'}
            placeholder={''}
            maxlength={'16'}
            value={formData.confirmPassword.value}
            name={'confirmPassword'}
            label={resetPasswordData.repeatPasswordInputField.label}
            isValidGroup={true}
            validate={formData.confirmPassword.validate}
            errorMessage={formData.confirmPassword.error}
            required={false}
            onChange={onChange}
            onClick={resetErrors}
          />
          <div className='reset-password__text'>
            <FormatHTMLAstChildren
              htmlAstChildren={
                resetPasswordData.text.data.childMarkdownRemark.htmlAst.children as React.ReactNode | React.ReactNode[]
              }
            />
          </div>
          <Button
            givenClass='box--form__button--main'
            label={resetPasswordData.buttonLabel}
            noLink
            onClick={submitForm}
          />
        </div>
      )}
      {showError && <Feedback {...feedback.error} />}
      {showSuccess && <Feedback {...feedback.success} />}
    </div>
  )
}
