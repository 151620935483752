import { MarketingAuthRequest, MarketingAuthResponse } from './interfaces'
import { logError, logInfo } from '@legal/shared/logger'
import { type ApiError } from '@legal/shared/HttpClient'
import { CustomerRepositoryApi } from '../infra'

export async function marketingAuthUseCase({
  successCallback,
  errorCallback,
  finallyCallback,
  request
}: {
  successCallback: (response: MarketingAuthResponse) => void
  errorCallback?: (error?: ApiError) => void
  finallyCallback?: () => void
  request: MarketingAuthRequest
}): Promise<void> {
  await CustomerRepositoryApi.marketingAuth(request)
    .then((response) => {
      logInfo('MarketingAuthUseCase')
      successCallback(response)
    })
    .catch((error: ApiError | undefined) => {
      logError(`MarketingAuthUseCase: ${error?.message}`, undefined)
      errorCallback?.(error)
    })
    .finally(() => {
      finallyCallback?.()
    })
}
