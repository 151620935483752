/* eslint-disable @typescript-eslint/no-unused-expressions */

import { Button } from '@npm_leadtech/legal-lib-components/Button'
import { Feedback } from '@npm_leadtech/legal-lib-components/Feedback'
import { Link } from 'gatsby'
import React from 'react'

import { AMPLITUDE_TEST_AB_VERSION, sendAmplitudeData } from '@legal/shared/amplitude'
import { type ForgotPasswordProps } from './ForgotPasswordProps'
import { TextInput } from '../../form'
import { customerAuthForgotPasswordUseCase } from '@legal/customer'
import { scrollToRef } from '../../../services/form/scrollToRef'
import { stringSlugify } from '../../../services/utils/stringSlugify'
import { useApplicationCookie } from '@legal/shared/hooks'

import './ForgotPassword.scss'

export const ForgotPassword: React.FC<ForgotPasswordProps> = ({
  isForgotPasswordModal = false,
  togglePasswordModal,
  changeFunction,
  forgotPasswordData
}) => {
  const { applicationCookie } = useApplicationCookie()
  const [status, updateStatus] = React.useState('initial')
  const [formData, setFormData] = React.useState({
    email: {
      value: '',
      validate: true,
      error: forgotPasswordData.invalidEmailError
    }
  })

  const resetStatus = (): void => {
    updateStatus('initial')
  }

  React.useEffect(() => {
    const initialDocumentType = applicationCookie?.form?.name
    const eventProps = {
      initial_document_type: stringSlugify(initialDocumentType) ?? null,
      version: AMPLITUDE_TEST_AB_VERSION.PRODUCTION
    }
    sendAmplitudeData('reset_password', eventProps)
  }, [])

  const feedback = {
    success: {
      theme: forgotPasswordData.successToast.type,
      title: forgotPasswordData.successToast.title,
      text: forgotPasswordData.successToast.text,
      button: {
        label: forgotPasswordData.successToast.buttonText,
        color: 'tertiary',
        givenClass: 'forgot-password-signin',
        link: '/login'
      }
    },
    error: {
      theme: forgotPasswordData.somethingWrongErrorToast.type,
      title: forgotPasswordData.somethingWrongErrorToast.title,
      text: forgotPasswordData.somethingWrongErrorToast.text,
      button: {
        label: forgotPasswordData.somethingWrongErrorToast.buttonText,
        noLink: true,
        onClick: resetStatus
      }
    }
  }

  if (isForgotPasswordModal) {
    feedback.success.button.noLink = true
    feedback.success.button.onClick = togglePasswordModal
    delete feedback.success.button.link
  }

  const onChange = React.useCallback(
    (e) => {
      const target = e.target
      const value = target.value
      const name = target.name
      const tempForm = { ...formData }

      tempForm[name].value = value

      setFormData(tempForm)
    },
    [formData]
  )

  const resetErrors = React.useCallback(
    (e) => {
      const target = e.target
      const name = target.name
      const tempForm = { ...formData }

      tempForm[name].validate = true
      setFormData(tempForm)
    },
    [formData]
  )

  const submitForm = React.useCallback(
    async (e) => {
      e.preventDefault()
      const tempForm = { ...formData }
      let validate = true
      const reEmail = /^([\w][\w+&.-]+)@([\w-]+\.)+([\w-]{2,4})$/
      const isMail = reEmail.test(String(tempForm.email.value).toLowerCase())

      if (tempForm.email.value === '' || !isMail) {
        tempForm.email.validate = false
        setFormData(tempForm)
        scrollToRef('email', 100)
        validate = false
      }

      if (validate) {
        await customerAuthForgotPasswordUseCase({
          request: {
            email: formData.email.value
          },
          successCallback: () => {
            changeFunction ? changeFunction() : updateStatus('success')
          },
          errorCallback: () => {
            scrollToRef('email', 100)
            updateStatus('error')
          }
        })
      }
    },
    [changeFunction, formData]
  )

  return (
    <>
      {status === 'initial' ?
        <form onSubmit={submitForm}>
          <p className='forgot-password__top-text sans-serif --medium'>{forgotPasswordData.emailInstructionsText}</p>
          <TextInput
            type={'text'}
            placeholder={forgotPasswordData.emailInputField.placeholder}
            givenClass='forgot-password__email-input'
            value={formData.email.value}
            name={'email'}
            label={forgotPasswordData.emailInputField.label}
            validate={formData.email.validate}
            errorMessage={formData.email.error}
            required={false}
            isValidGroup={true}
            onChange={onChange}
            onClick={resetErrors}
          />
          <div className='box--form__body__footer'>
            <Button givenClass='box--form__button--main' label={forgotPasswordData.sendEmailButtonText} noLinkNoFunc />
            {isForgotPasswordModal ?
              <Button
                givenClass='forgot-password-signin'
                color='tertiary'
                noLink
                onClick={() => {
                  togglePasswordModal()
                }}
                label={forgotPasswordData.returnButtonText}
              />
            : <Button
                givenClass='forgot-password-signin'
                color='tertiary'
                link={'/login'}
                LinkComponent={Link}
                label={forgotPasswordData.returnButtonText}
              />
            }
          </div>
        </form>
      : <Feedback {...feedback[status]} />}
    </>
  )
}
