import { SubscriptionStatus, SubscriptionTypeName } from '@legal/shared/data'
import { PricingPlan } from '../../../../services/constants/pricingTest'
import { type Subscription } from '../../domain'
import { type SubscriptionData } from '../dataObjects'
import { formatPrice } from '@legal/shared/utils'

export function SubscriptionDataToSubscription(subscriptionData: SubscriptionData): Subscription {
  return {
    id: subscriptionData.subscriptionId ?? subscriptionData.id,
    code: subscriptionData.code || subscriptionData.price?.code,
    displayPrice: subscriptionData.displayPrice || subscriptionData.price?.displayPrice,
    displayPriceFormated: formatPrice(subscriptionData.displayPrice),
    initialAmount: subscriptionData.initialAmount,
    initialAmountFormated: formatPrice(subscriptionData.initialAmount),
    currency: subscriptionData.currency,
    underPricingText: subscriptionData.underPricingText,
    isSevenDayFreeTrial: subscriptionData.name === '7-Day Free Trial',
    isSevenDayAccess: subscriptionData.type.name === SubscriptionTypeName.SEVEN_DAY_ACCESS,
    isBestValue:
      subscriptionData.name === SubscriptionTypeName.BEST_VALUE ||
      subscriptionData.name === SubscriptionTypeName.ONE_YEAR_PRO_SUBSCRIPTION,
    isMonthProValue: subscriptionData.type.name === PricingPlan.monthPro,
    isWeeklyValue: subscriptionData.type.name === PricingPlan.weekly,
    priceValue: subscriptionData.priceValue,
    price: {
      id: subscriptionData.price?.id,
      initialAmount: subscriptionData.price?.initialAmount
    },
    type: {
      name: subscriptionData.type.name as SubscriptionTypeName,
      isBestValue:
        subscriptionData.type.name === SubscriptionTypeName.BEST_VALUE ||
        subscriptionData.type.name === SubscriptionTypeName.ONE_YEAR_PRO_SUBSCRIPTION,
      isSevenDayAccess: subscriptionData.type.name === SubscriptionTypeName.SEVEN_DAY_ACCESS,
      isMonthProValue: subscriptionData.type.name === PricingPlan.monthPro,
      isWeeklyValue: subscriptionData.type.name === PricingPlan.weekly,
      config: {
        pricingPage: {
          content: subscriptionData.type?.config?.pricingPage?.content,
          disclaimer: subscriptionData.type?.config?.pricingPage?.disclaimer,
          displayPrice: {
            value: subscriptionData.type.config.pricingPage?.displayPrice?.value,
            annotation: subscriptionData.type.config.pricingPage?.displayPrice?.annotation
          }
        },
        paymentPage: {
          displayPrice: {
            value: subscriptionData.type.config.paymentPage?.displayPrice?.value
          }
        }
      }
    },
    token: subscriptionData.token,
    isUnsubscribed: subscriptionData.status === SubscriptionStatus.UNSUBSCRIBED
  }
}
