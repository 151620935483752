import * as REQUESTS from './apiRequest'
import { CreateCustomerRequest, CreateCustomerWithSubscriptionRequest } from '../application'
import { CustomerRepository } from '../domain'

export const CustomerRepositoryApi: CustomerRepository = {
  async create(request: CreateCustomerRequest) {
    return await REQUESTS.createCustomer(request)
  },
  async createWithSubscription(request: CreateCustomerWithSubscriptionRequest) {
    return await REQUESTS.createCustomerWithSubscription(request)
  },
  async auth(request) {
    return await REQUESTS.customerAuth(request)
  },
  async forgotPassword(email) {
    return await REQUESTS.customerAuthForgotPassword(email)
  },
  async authGoogle(request) {
    return await REQUESTS.customerAuthGoogle(request)
  },
  async get() {
    return await REQUESTS.getCustomer()
  },
  async resetPassword(urlToken: string, password: string) {
    return await REQUESTS.resetPassword(urlToken, password)
  },
  async updateAutoGeneratedPassword(password: string) {
    return await REQUESTS.updateAutoGeneratedPassword(password)
  },
  async updatePassword(oldPassword: string, newPassword: string) {
    return await REQUESTS.updatePassword(oldPassword, newPassword)
  },
  async getGeoLocation() {
    return await REQUESTS.getGeoLocation()
  },
  async marketingAuth({ applicationId, email }) {
    return await REQUESTS.marketingAuth({ applicationId, email })
  },
  async updateEmail(email) {
    return await REQUESTS.updateEmailCustomer(email)
  },
  async update(data) {
    return await REQUESTS.updateCustomer(data)
  },
  async getBilling() {
    return await REQUESTS.getCustomerBilling()
  },
  async getLastPaymentMessage() {
    return await REQUESTS.getLastPaymentMessage()
  }
}
