import {
  CustomerBilling,
  CustomerSubscription,
  getActiveCustomerSubscriptions,
  getCustomerSubscriptionFilterPayed,
  getLastCustomerPayment,
  getMostRecentInactiveSubscription,
  getOrderedSubscriptions
} from '../../domain'
import { CustomerBillingData } from '../dataObject'
import { CustomerBillingDataToCustomerCard } from './CustomerBillingDataToCustomerCard'
import { CustomerBillingDataToCustomerPayments } from './CustomerBillingDataToCustomerPayments'
import { CustomerBillingDataToCustomerRefunds } from './CustomerBillingDataToCustomerRefunds'
import { CustomerBillingDataToCustomerSubscriptions } from './CustomerBillingDataToCustomerSubscriptions'
import { SubscriptionStatus } from '@legal/shared/data'

export function CustomerBillingDataToCustomerBilling(data: CustomerBillingData): CustomerBilling {
  const subscriptions = getOrderedSubscriptions(CustomerBillingDataToCustomerSubscriptions(data))
  const activeSubscriptions = getActiveCustomerSubscriptions(subscriptions)
  const payments = CustomerBillingDataToCustomerPayments(data)
  return {
    hasActiveSubscription: data.subscriptions.some((subscription) => subscription.status === SubscriptionStatus.ACTIVE),
    token: data.token,
    card: CustomerBillingDataToCustomerCard(data),
    subscriptions,
    activeSubscriptions,
    paidSubscriptions: getCustomerSubscriptionFilterPayed(subscriptions),
    currentSubscriptionId: getCurrentSubscriptionId(activeSubscriptions, subscriptions),
    payments,
    lastPayment: getLastCustomerPayment(payments),
    refunds: CustomerBillingDataToCustomerRefunds(data)
  }
}

function getCurrentSubscriptionId(
  activeSubscriptions: CustomerSubscription[],
  subscriptions: CustomerSubscription[]
): string | undefined {
  const activeSubscription = activeSubscriptions?.[0]
  if (activeSubscription?.id) return activeSubscription.id
  const mostRecentInactiveSubscription = getMostRecentInactiveSubscription(subscriptions)
  return mostRecentInactiveSubscription?.id ? mostRecentInactiveSubscription.id : undefined
}
