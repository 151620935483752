/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { FunctionalityCardsSection } from '@npm_leadtech/legal-lib-components/FunctionalityCardsSection'
import React from 'react'
import { navigate } from 'gatsby'

import { AMPLITUDE_TEST_AB_VERSION, sendAmplitudeData, setAmplitudeIncrement } from '@legal/shared/amplitude'
import {
  Application,
  type PdfEncoded,
  deleteApplicationUseCase,
  downloadApplicationPdfUseCase,
  duplicateApplicationUseCase,
  findApplicationByUuidUseCase,
  getHtmlPreviewByUuidUseCase
} from '@legal/application'
import {
  DOCS_PER_PAGE,
  Document,
  DocumentActions,
  deleteDocumentUseCase,
  findDocumentByApplicationId,
  getDocumentBlobUseCase,
  slicedDocumentsByCurrentPage,
  updateDocumentNameUseCase
} from '@legal/document'
import { DeleteDocumentModal, NewDocumentModal, ReadyDocumentModal } from '../../molecules'
import { useApplicationCookie, useIsMobile, useIsTablet } from '@legal/shared/hooks'
import { DocumentCard } from '../DocumentCard'
import { DocumentsGroup } from '../../atoms/DocumentsGroup'
import { type DocumentsSectionProps } from './DocumentsSectionProps'
import { Modal } from '../../layout'
import { ModalPreview } from '../../template'
import { UploadZone } from '../../molecules/UploadZone/UploadZone'
import { UploadedDocumentCard } from '../UploadedDocumentCard'
import { cardFunctionalityFromStrapi } from '@legal/shared/adapters'
import { downloadPdfHelper } from '../../../services/utils/downloadPdfHelper'
import { isMobileDevice } from '../../../services/utils/isMobileDevice'
import { openPreviewPDF } from '@legal/shared/utils'
import { stringSlugify } from '../../../services/utils/stringSlugify'
import { useAllSiteMetadata } from '@legal/shared/hooks/useAllSiteMetadata'
import { useConfigs } from '@legal/core/configs'
import { useModal } from '../../../services/hooks/useModal'
import './DocumentsSection.scss'

export const DocumentsSection: React.FC<DocumentsSectionProps> = ({
  initialApplications,
  fetchAndSetApplications,
  currentSubscription,
  ratafiaClicked,
  uploadedDocuments,
  uploadingFile,
  updateDocumentName,
  deleteDocument,
  customer,
  data
}) => {
  const {
    applicationCookie,
    replaceApplicationCookie,
    setApplicationCookieIdToDownload,
    setApplicationCookieIsAutomaticallyDownload,
    clearApplicationCookie
  } = useApplicationCookie()
  const { PA_UPLOAD_DOCUMENT_ENABLED, RATAFIA_CONFIG } = useConfigs()
  const { SITE_NAME } = useAllSiteMetadata()
  const [applications, setApplications] = React.useState<Application[]>([...initialApplications])
  const [completedApplications, setCompletedApplications] = React.useState<Application[]>([])
  const [inProgressApplications, setInProgressApplications] = React.useState<Application[]>([])
  const [deleteFunction, setDeleteFunction] = React.useState<() => Promise<void> | void>()
  const [deleteModal, updateDeleteModal] = React.useState({ show: false, documentName: '' })
  const [previewModal, setPreviewModal] = React.useState<{
    show: boolean
    documentName?: string
    documentType?: string
    documentStateOrSubType?: string
    htmlPreview?: string
  }>({ show: false })
  const [formName, setFormName] = React.useState<string>(applicationCookie?.form?.name ?? '')
  const [reference, setReference] = React.useState<string>()
  const [currentPage, setCurrentPage] = React.useState([1, 1, 1])
  const storedApplicationId = applicationCookie?.id ?? ''
  const applicationIdToDownload = applicationCookie?.idToDownload ?? ''
  const readyDocumentModal = useModal()
  const newDocumentModal = useModal()
  const isMobile = useIsMobile(0)
  const isTablet = useIsTablet()

  React.useEffect(() => {
    setApplications([...initialApplications])
  }, [initialApplications])

  React.useEffect(() => {
    if (readyDocumentModal && isMobileDevice()) {
      readyDocumentModal.changeModalState()
    }
  }, [])

  React.useEffect(() => {
    setCompletedApplications(applications.filter((application) => application.isCompleted))
    setInProgressApplications(applications.filter((application) => !application.isCompleted))
  }, [applications])

  const downloadApplicationPdfSuccess = (pdfEncoded?: PdfEncoded): void => {
    if (!pdfEncoded) return
    downloadPdfHelper({
      base64Pdf: pdfEncoded.content,
      applicationName: formName,
      applicationReference: reference,
      tab: null,
      action: 'download'
    })
  }

  const fetchAndDownloadPdf = async (applicationId: string): Promise<void> => {
    const application = findApplicationById(applicationId)
    if (applicationId) {
      await findApplicationByUuidUseCase({
        applicationId,
        successCallback: (application) => {
          setFormName(application.form.name)
          setReference(application.reference)
        }
      })
    }

    const eventProps = {
      format: 'pdf',
      document_ID: applicationId,
      document_type: (application?.formType && stringSlugify(application.formType)) ?? null,
      document_subtype: (application?.state && stringSlugify(application.state)) ?? null
    }

    sendAmplitudeData('download_paid_document', eventProps)
    setAmplitudeIncrement('documents_downloaded', 1)
    sendAmplitudeData(DocumentActions.DOWNLOAD, { document_property: SITE_NAME })

    await downloadApplicationPdfUseCase({
      applicationId,
      successCallback: downloadApplicationPdfSuccess
    })
  }

  const getUploadedDocument = async (
    applicationId: string,
    documentAction: DocumentActions,
    fileName: string
  ): Promise<void> => {
    await getDocumentBlobUseCase({
      request: { documentId: applicationId, documentAction },
      successCallback: (documentBlob) => {
        openPreviewPDF(documentBlob, documentAction, fileName, isMobile)
      }
    })
  }

  const previewUploadedDocument = async (applicationId: string, fileName: string): Promise<void> => {
    await getUploadedDocument(applicationId, DocumentActions.PREVIEW, fileName)
  }

  const downloadUploadedDocument = async (applicationId: string, fileName: string): Promise<void> => {
    await getUploadedDocument(applicationId, DocumentActions.DOWNLOAD, fileName)
  }

  const editUploadedDocument = async (applicationId: string, fileName: string): Promise<void> => {
    await updateDocumentNameUseCase({
      request: { documentId: applicationId, name: fileName }
    })
  }

  React.useEffect(() => {
    if (applicationCookie?.isAutomaticallyDownload && applications.length > 0) {
      fetchAndDownloadPdf(applicationIdToDownload)
      setApplicationCookieIsAutomaticallyDownload(false)
      setApplicationCookieIdToDownload(undefined)
    }
  }, [applications, applicationCookie, applicationIdToDownload])

  const editClicked = React.useCallback((applicationId: string, position): void => {
    window.localStorage.setItem('isEditing', 'editing')
    const application = findApplicationById(applicationId)
    if (!application) return
    replaceApplicationCookie({
      id: applicationId,
      lastStep: undefined,
      fromEdit: true,
      form: {
        name: application.form.name,
        driveId: undefined
      }
    })
    const eventProps = {
      source: position,
      application_ID: applicationId,
      document_type: stringSlugify(application.form.type),
      document_subtype: (application?.state && stringSlugify(application.state)) ?? '',
      version: AMPLITUDE_TEST_AB_VERSION.PRODUCTION
    }
    sendAmplitudeData('edit_document', eventProps)
    if (application.form.url) navigate(application.form.url)
  }, [])

  const findApplicationById = (applicationId: string): Application | undefined => {
    return applications.find((application) => application.id === applicationId)
  }

  const getHtmlPreviewSuccess = (htmlPreview: string, applicationId: string): void => {
    const application = findApplicationById(applicationId)
    if (!application) return
    const documentStateOrSubType = application.state
    const documentType = application.form.type
    const eventProps = {
      document_property: SITE_NAME
    }
    sendAmplitudeData(DocumentActions.PREVIEW, eventProps)
    setPreviewModal({
      show: true,
      documentType,
      documentStateOrSubType,
      htmlPreview
    })
  }

  const previewClicked = React.useCallback(async (applicationId: string): Promise<void> => {
    await getHtmlPreviewByUuidUseCase({
      applicationId,
      successCallback: (htmlPreview: string) => {
        getHtmlPreviewSuccess(htmlPreview, applicationId)
      }
    })
  }, [])

  const deleteClicked = React.useCallback(
    (applicationId: string, document = false): void => {
      setDeleteFunction(() => () => {
        document ? deleteDocumentAction(applicationId) : deleteApplication(applicationId)
      })
      const documentName =
        document ?
          findDocumentByApplicationId(uploadedDocuments, applicationId)?.name
        : findApplicationById(applicationId)?.name
      updateDeleteModal({ show: true, documentName })
    },
    [uploadedDocuments]
  )

  const deleteApplicationSuccess = (applicationId: string): void => {
    let updatedApplications = [...applications]
    updatedApplications = updatedApplications.filter((application) => application.id !== applicationId)
    setApplications(updatedApplications)
    if (storedApplicationId === applicationId) {
      clearApplicationCookie()
    }
  }

  const deleteApplication = async (applicationId: string): Promise<void> => {
    updateDeleteModal({ show: false, documentName: '' })
    await deleteApplicationUseCase({
      applicationId,
      successCallback: () => {
        deleteApplicationSuccess(applicationId)
      }
    })
  }

  const deleteDocumentAction = async (applicationId: string): Promise<void> => {
    updateDeleteModal({ show: false, documentName: '' })
    await deleteDocumentUseCase({
      request: { documentId: applicationId },
      successCallback: () => {
        deleteDocument(applicationId)
      }
    })
  }

  const updateApplicationName = (applicationId: string, name: string): void => {
    const updatedApplications = [...applications]
    updatedApplications.forEach((application) => {
      if (application.id === applicationId) application.name = name
      return application
    })
    setApplications(updatedApplications)
  }

  const duplicateClicked = React.useCallback(async (applicationId: string): Promise<void> => {
    await duplicateApplicationUseCase({
      applicationId,
      successCallback: fetchAndSetApplications
    })
  }, [])

  const closeDeleteModal = (): void => {
    updateDeleteModal({ show: false, documentName: '' })
    setDeleteFunction(undefined)
  }

  const closePreviewModal = (): void => {
    setPreviewModal({ show: false, documentName: '' })
  }

  const changeCardListPage = (cardListIndex, pageDiff): void => {
    const cardListsLength = [completedApplications.length, inProgressApplications.length, uploadedDocuments.length]
    const currentLength = cardListsLength[cardListIndex]
    const currentMaxPage = currentLength / DOCS_PER_PAGE + 1

    if (currentPage[cardListIndex] + pageDiff > 0 && currentPage[cardListIndex] + pageDiff <= currentMaxPage) {
      const newCurrentPage: number[] = Object.assign([], currentPage)
      newCurrentPage[cardListIndex] = newCurrentPage[cardListIndex] + pageDiff
      setCurrentPage(newCurrentPage)
    }
  }

  const DocumentsCardList = ({ applications }: { applications: Application[] }): React.JSX.Element[] => {
    return applications.map((application) => (
      <DocumentCard
        key={application.id}
        applicationId={application.id}
        subscriptionId={application.subscriptionId}
        formType={application.form.type}
        name={application.name}
        formTypeName={application.form.name}
        updatedAt={application.updatedAt}
        state={application.state}
        status={application.status}
        isCompleted={application.isCompleted}
        actions={application.actions}
        canUpgradeAccount={customer.canUpgradeAccount}
        downloadPdf={() => {
          fetchAndDownloadPdf(application.id)
        }}
        editClicked={editClicked}
        deleteClicked={deleteClicked}
        onEditApplicationName={updateApplicationName}
        previewClicked={previewClicked}
        duplicateClicked={duplicateClicked}
        ratafiaClicked={ratafiaClicked}
        currentSubscription={currentSubscription}
        data={data}
      />
    ))
  }

  const UploadedDocumentsCardList = ({
    documents,
    previewAction,
    downloadAction,
    editAction
  }: {
    documents: Document[]
    previewAction: (uid: string, name: string) => Promise<void>
    downloadAction: (uid: string, name: string) => Promise<void>
    editAction: (uid: string, name: string) => Promise<void>
  }): React.JSX.Element[] => {
    return documents.map((document) => (
      <UploadedDocumentCard
        key={document.id}
        applicationId={document.id}
        name={document.name}
        updatedAt={document.createdAt}
        previewAction={previewAction}
        downloadAction={downloadAction}
        editAction={editAction}
        ratafiaClicked={ratafiaClicked}
        updateDocumentName={updateDocumentName}
        deleteClicked={deleteClicked}
        data={data}
      />
    ))
  }

  return (
    <>
      {deleteModal.show && (
        <DeleteDocumentModal
          closeFunction={closeDeleteModal}
          deleteFunction={deleteFunction}
          documentName={deleteModal.documentName}
          data={data}
        />
      )}

      {previewModal.show && (
        <Modal title={previewModal.documentType} closeFunction={closePreviewModal}>
          <ModalPreview
            documentStateOrSubType={previewModal.documentStateOrSubType ?? ''}
            documentType={previewModal.documentType ?? ''}
            htmlPreview={previewModal.htmlPreview ?? ''}
            allowCopy='false'
            data={data}
          />
        </Modal>
      )}

      {readyDocumentModal?.modalOpen && (
        <ReadyDocumentModal
          closeFunction={readyDocumentModal.closeModal}
          primaryActionText={data.strapiMyDocumentsPage?.documentsSection?.documentReadyModal?.primaryActionText ?? ''}
          title={data.strapiMyDocumentsPage?.documentsSection?.documentReadyModal?.title ?? ''}
          text={{
            html:
              data.strapiMyDocumentsPage?.documentsSection?.documentReadyModal?.text?.data?.childMarkdownRemark?.html ??
              ''
          }}
        />
      )}

      {newDocumentModal?.modalOpen && <NewDocumentModal closeFunction={newDocumentModal.closeModal} />}
      {RATAFIA_CONFIG.ENABLED && (
        <FunctionalityCardsSection
          {...cardFunctionalityFromStrapi({
            data: data?.strapiMyDocumentsPage?.functionalityCards as Queries.StrapiCardFunctionalityDataFragment[],
            handlerCreateDocument: () => {
              newDocumentModal.changeModalState()
            }
          })}
        />
      )}
      {completedApplications.length > 0 && (
        <DocumentsGroup
          title='Completed'
          page={currentPage[0]}
          total={completedApplications.length}
          prevPage={() => {
            changeCardListPage(0, -1)
          }}
          nextPage={() => {
            changeCardListPage(0, 1)
          }}
        >
          <DocumentsCardList
            applications={
              isMobile || isTablet ? completedApplications : (
                completedApplications.slice((currentPage[0] - 1) * DOCS_PER_PAGE, currentPage[0] * DOCS_PER_PAGE)
              )
            }
          />
        </DocumentsGroup>
      )}

      {inProgressApplications.length > 0 && (
        <DocumentsGroup
          title='In Progress'
          page={currentPage[1]}
          total={inProgressApplications.length}
          prevPage={() => {
            changeCardListPage(1, -1)
          }}
          nextPage={() => {
            changeCardListPage(1, 1)
          }}
        >
          <DocumentsCardList
            applications={
              isMobile || isTablet ? inProgressApplications : (
                inProgressApplications.slice((currentPage[1] - 1) * DOCS_PER_PAGE, currentPage[1] * DOCS_PER_PAGE)
              )
            }
          />
        </DocumentsGroup>
      )}

      {(uploadedDocuments.length > 0 || uploadingFile) && (
        <DocumentsGroup
          title='Uploaded'
          link='uploaded'
          page={currentPage[2]}
          total={uploadedDocuments.length}
          prevPage={() => {
            changeCardListPage(2, -1)
          }}
          nextPage={() => {
            changeCardListPage(2, 1)
          }}
        >
          {uploadingFile && (
            <UploadedDocumentCard
              key={uploadingFile}
              name={uploadingFile}
              updatedAt=''
              applicationId=''
              data={data}
              uploadingFile={uploadingFile}
            />
          )}
          {uploadedDocuments.length > 0 && (
            <UploadedDocumentsCardList
              documents={slicedDocumentsByCurrentPage(uploadedDocuments, currentPage[2], isMobile, isTablet)}
              previewAction={previewUploadedDocument}
              downloadAction={downloadUploadedDocument}
              editAction={editUploadedDocument}
            />
          )}
        </DocumentsGroup>
      )}

      {PA_UPLOAD_DOCUMENT_ENABLED && <UploadZone data={data} />}
    </>
  )
}
