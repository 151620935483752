import { logError, logInfo } from '@legal/shared/logger'
import { type Customer } from '../domain'
import { CustomerRepositoryApi } from '../infra'

export async function getCustomerUseCase({
  successCallback,
  errorCallback,
  finallyCallback
}: {
  successCallback: (response: Customer) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorCallback?: (error: any) => void
  finallyCallback?: () => void
}): Promise<void> {
  await CustomerRepositoryApi.get()
    .then((response) => {
      logInfo('GetCustomer')
      successCallback(response)
    })

    .catch((error: Error | undefined) => {
      logError('GetCustomer', undefined, error)
      errorCallback?.(error)
    })
    .finally(() => {
      finallyCallback?.()
    })
}
