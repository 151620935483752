import { logError, logInfo } from '@legal/shared/logger'
import { type ApiError } from '@legal/shared/HttpClient'
import { CustomerRepositoryApi } from '../infra'
import { UpdatePasswordRequest } from './interfaces'

export async function updatePasswordUseCase({
  successCallback,
  errorCallback,
  finallyCallback,
  request
}: {
  successCallback: (newToken: string) => void
  errorCallback?: (error?: ApiError) => void
  finallyCallback?: () => void
  request: UpdatePasswordRequest
}): Promise<void> {
  await CustomerRepositoryApi.updatePassword(request.oldPassword, request.newPassword)
    .then((newToken) => {
      logInfo('UpdatePasswordUseCase')
      successCallback(newToken)
    })
    .catch((error: ApiError | undefined) => {
      logError(`UpdatePasswordUseCase: ${error?.message}`)
      errorCallback?.(error)
    })
    .finally(() => {
      finallyCallback?.()
    })
}
