import { type Subscription } from '@legal/subscription'
import { replaceValues } from '../../../services/utils/replaceAll'
import { useAllSiteMetadata } from './useAllSiteMetadata'
import { useConfigs } from '@legal/core/configs'
import { useQuerySharedComponentsFromPaymentPage } from '../data/graphql'

export const useBenefitsListToShow = ({
  subscription
}: {
  subscription: Subscription | undefined
}): { benefitsListToShow: Queries.Maybe<Queries.STRAPI__COMPONENT_FORMS_SUBSCRIPTION[]> | undefined } => {
  const { IS_JONSNOW } = useConfigs()
  const { SITE_PHONE } = useAllSiteMetadata()
  const { subscriptions } = useQuerySharedComponentsFromPaymentPage()
  const subscriptionType = subscriptions?.find(
    (benefitList) => benefitList?.sku === subscription?.type.name || benefitList?.name === subscription?.type.name
  )
  const benefitsListToShow = subscriptionType?.benefitsBySubscriptions?.map((benefitBySubscription) =>
    replaceValues(benefitBySubscription?.benefit, { SITE_PHONE })
  ) as Queries.Maybe<Queries.STRAPI__COMPONENT_FORMS_SUBSCRIPTION[]> | undefined

  if (IS_JONSNOW && subscription?.isSevenDayAccess) {
    benefitsListToShow?.pop()
  }

  return {
    benefitsListToShow
  }
}
