/* eslint-disable react-compiler/react-compiler */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React from 'react'

import { FieldType } from '../../../../services/form/FieldType'
import { FormToTemplate } from '../../../../services/form/FormToTemplate'
import { TypeSelector } from '../../../../services/form/TypeSelector'
import { changeSelectStatePreform } from '../../../../services/utils/preForm/preForm'
import { getNewRenderedFields } from '../../../../services/form/getNewRenderedFields'
import { getNoRenderedFields } from '../../../../services/form/getNoRenderedFields'
import { mockStateSelectMessi } from '../../../../services/constants/statesStepMessi'

export const useActions = ({
  itemChangedKey,
  toggleGroupNames,
  formData,
  updateState,
  groupValidator,
  structure,
  form,
  oldNoRenderFields,
  state,
  resetCookies,
  applicationId,
  userCookie
}): {
  handleChange: (e: React.ChangeEvent) => void
  toggleFormRefresh: (newForm: any) => void
  disabledNextStep: boolean
} => {
  const [disabledNextStep, setDisabledNextStep] = React.useState<boolean>(false)
  const handleChange = (e): void => {
    const target = e.target
    if (target?.name === mockStateSelectMessi.name) {
      changeSelectStatePreform(
        structure,
        form,
        target?.value?.label,
        resetCookies,
        applicationId,
        userCookie.token,
        setDisabledNextStep
      )
    }

    const value = target.value
    const name = target.name
    const tempForm = form
    const field = tempForm[name]
    field.validate = true

    tempForm[name].value = FieldType(tempForm[name].component, value, target.checked)

    field.value = TypeSelector(field.component, value, target.checked) // This function sets the value of the field in the state, it's the one that's responsible for checking the type and set the value accordingly

    groupValidator(state.currentStep - 1)

    const tempNoRender = getNoRenderedFields(tempForm, structure)
    const newRenderedFields = getNewRenderedFields(
      tempNoRender,
      oldNoRenderFields.current,
      state.currentStructure,
      state.currentStep
    )
    oldNoRenderFields.current = tempNoRender
    updateState({
      form: tempForm,
      template: FormToTemplate(
        formData.form_template,
        form,
        tempNoRender,
        name,
        toggleGroupNames,
        itemChangedKey,
        newRenderedFields
      ),
      noRenderFields: tempNoRender
    })
  }

  const toggleFormRefresh = (newForm): void => {
    const tempNoRender = getNoRenderedFields(newForm, structure)
    const newRenderedFields = getNewRenderedFields(
      tempNoRender,
      oldNoRenderFields.current,
      structure,
      state.currentStep
    )
    updateState({
      form: newForm,
      template: FormToTemplate(
        formData.form_template,
        form,
        tempNoRender,
        toggleGroupNames,
        itemChangedKey,
        newRenderedFields
      ),
      noRenderFields: tempNoRender
    })
  }

  return {
    handleChange,
    toggleFormRefresh,
    disabledNextStep
  }
}
