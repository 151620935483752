import { SubscriptionStatus, SubscriptionTypeName } from '@legal/shared/data'
import { formatPrice, getRemainingDays } from '@legal/shared/utils'
import { CustomerBillingData } from '../dataObject'
import { CustomerSubscription } from '../../domain'

export function CustomerBillingDataToCustomerSubscriptions(data: CustomerBillingData): CustomerSubscription[] {
  if (data.subscriptions.length === 0) return []
  return data.subscriptions.map((subscription) => ({
    id: subscription.id ?? undefined,
    createdAt: new Date(subscription.createdAt),
    activatedAt: new Date(subscription.activatedAt),
    status: subscription.status as SubscriptionStatus,
    unsubscribeAt: subscription.unsubscribeAt ? new Date(subscription.unsubscribeAt) : undefined,
    unsubscribeRequestedAt:
      subscription.unsubscribeRequestedAt ? new Date(subscription.unsubscribeRequestedAt) : undefined,
    isActiveUnsubscribeRequest:
      subscription.unsubscribeAt !== null && subscription.unsubscribeRequestedAt !== null && subscription.isActive,
    remainingDays: subscription.unsubscribeAt ? getRemainingDays(new Date(subscription.unsubscribeAt)) : undefined,
    type: {
      name: subscription.subscriptionTypeName as SubscriptionTypeName
    },
    isActive: subscription.isActive,
    firstDocumentName: subscription.firstDocumentName,
    displayPrice: subscription.price.displayPrice,
    initialAmount: subscription.price.initialAmount,
    initialAmountFormated: formatPrice(subscription.price.initialAmount),
    displayPriceFormated: formatPrice(subscription.price.displayPrice),
    isCancelled:
      subscription.unsubscribeRequestedAt !== null ||
      (subscription.unsubscribeRequestedAt !== null && subscription.unsubscribeAt !== null && !subscription.isActive)
  }))
}
