import React from 'react'

import { CustomerPayment } from '../CustomerPayment'
import { formatDate } from '@legal/shared/utils'

export const useFilteredInvoices = (invoices: CustomerPayment[], collapsed: boolean) => {
  const initInvoices = (): CustomerPayment[] => {
    return invoices
      .filter((invoice) => invoice.filtered)
      .filter((_, index) => (collapsed ? index < 4 : true))
      .map((invoice) => {
        invoice.parsedDate = formatDate({ date: invoice.paidAt, format: 'MMMM, YYYY' })
        return invoice
      })
  }
  const [filteredInvoices, setFilteredInvoices] = React.useState<CustomerPayment[]>(initInvoices())
  React.useEffect(() => {
    setFilteredInvoices(initInvoices())
  }, [invoices, collapsed])

  return { filteredInvoices }
}
