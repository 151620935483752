import { Customer } from '../../domain'
import { CustomerToCustomerData } from '../adapters/CustomerToCustomerData'
import { ENDPOINTS } from './Endpoints'
import { put } from '@legal/shared/HttpClient'

export async function updateCustomer(data: Partial<Customer>): Promise<string> {
  const body = CustomerToCustomerData(data)
  const result = await put<{ token: string }>(ENDPOINTS.CUSTOMER, body, true)
  return result.token
}
