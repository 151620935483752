import { SubscriptionStatus, SubscriptionTypeName } from '@legal/shared/data'
import { CustomerSubscription } from '../CustomerSubscription'

export function getMostRecentInactiveSubscription(
  subscriptions: CustomerSubscription[]
): CustomerSubscription | undefined {
  return subscriptions.find(
    (subscription) =>
      subscription.status === SubscriptionStatus.UNSUBSCRIBED &&
      subscription.type.name !== SubscriptionTypeName.SINGLE_DOCUMENT
  )
}
