import { type CustomerAuthRequest, type CustomerAuthResponse } from './interfaces'
import { logError, logInfo } from '@legal/shared/logger'
import { type ApiError } from '@legal/shared/HttpClient'
import { CustomerRepositoryApi } from '../infra'
import { UserCookie } from '../../../services/storage/cookies/UserCookie'
import { setAmplitudeUserId } from '@legal/shared/amplitude'

export async function customerAuthUseCase({
  successCallback,
  errorCallback,
  finallyCallback,
  request
}: {
  successCallback: (response: CustomerAuthResponse) => void
  errorCallback?: (error?: ApiError) => void
  finallyCallback?: () => void
  request: CustomerAuthRequest
}): Promise<void> {
  const user = new UserCookie()
  if (user.token) {
    logError('CustomerAuthUseCase', { error: 'User already authenticated' })
    return
  }
  await CustomerRepositoryApi.auth(request)
    .then(async (response) => {
      if (response.customerId) setAmplitudeUserId(response.customerId)
      else await getCustomerToAmplitude(response.token)
      logInfo('CustomerAuthUseCase')
      successCallback(response)
    })

    .catch((error: ApiError | undefined) => {
      logError(`CustomerAuthUseCase: ${error?.message}`, undefined)
      errorCallback?.(error)
    })
    .finally(() => {
      finallyCallback?.()
    })
}

const getCustomerToAmplitude = async (token?: string): Promise<void> => {
  if (token) {
    const user = new UserCookie()
    user.token = token
    const dataCustomer = await CustomerRepositoryApi.get()
    if (dataCustomer.id) {
      setAmplitudeUserId(dataCustomer.id)
    }
  }
}
