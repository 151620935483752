import { type HeadProps, type PageProps, graphql } from 'gatsby'
import React from 'react'

import { FormTemplateDefault, FormTemplateMessi } from '../components/template'
import { Seo } from '@legal/seo'
import { getFormTitle } from '@legal/shared/utils'
import { useConfigs } from '@legal/core/configs'
import { useVersion } from '../services/testAB/hooks/useVersion'

const FormTemplate: React.FC<PageProps<Queries.FormPagePreformQuery>> = ({ data, uri, pageContext }) => {
  const { versionName, positionOfDynamicStates } = pageContext as {
    versionName?: string
    positionOfDynamicStates?: number
  }

  const { version } = useVersion(versionName) as { version: 'messi' | 'default' | undefined }
  const formTitle = getFormTitle(data.strapiForm?.title, data.strapiForm?.name)

  if (version === 'messi')
    return (
      <FormTemplateMessi
        data={data}
        uri={uri}
        positionOfDynamicStates={positionOfDynamicStates}
        title={formTitle}
        version={version}
      />
    )

  return <FormTemplateDefault data={data} uri={uri} title={formTitle} version={version} />
}

export default FormTemplate

export function Head({ location, data }: HeadProps<Queries.FormPagePreformQuery>): React.JSX.Element {
  const { IS_JONSNOW, APP_SUBDOMAIN } = useConfigs()
  const rel = !IS_JONSNOW && {
    rel: 'canonical'
  }

  return (
    <Seo title={getFormTitle(data.strapiForm?.title, data.strapiForm?.name)} hasNavbarMicroformat>
      <link {...rel} href={`${APP_SUBDOMAIN}/${location.pathname}`} />
      <body className='pageFormTemplate' />
    </Seo>
  )
}

export const pageQuery = graphql`
  query FormPagePreform($driveId: String) {
    strapiForm(driveId: { eq: $driveId }) {
      ...StrapiFormData
    }
  }
`
