import React from 'react'

import { DatePickerCustom, type DatePickerCustomProps } from '@npm_leadtech/legal-lib-components/DatePickerCustom'
import { useIsMobile } from '@legal/shared/hooks'

export const DatePicker = (props: DatePickerCustomProps) => {
  const isMobile = useIsMobile()

  return <DatePickerCustom isMobile={isMobile} {...props} />
}
