import { CustomerPayment } from '../CustomerPayment'

export function getLastCustomerPayment(payments: CustomerPayment[] = []): CustomerPayment | undefined {
  if (payments.length === 0) return
  const sortedPayments = sortPayments(payments)
  return sortedPayments[0]
}

function sortPayments(payments: CustomerPayment[]): CustomerPayment[] {
  return payments.sort((a, b) => {
    if (a.paidAt > b.paidAt) return -1
    if (a.paidAt < b.paidAt) return 1
    return 0
  })
}
