import { AddButton } from '@npm_leadtech/legal-lib-components/AddButton'
import { Checkbox } from '@npm_leadtech/legal-lib-components/Checkbox'
import { DatePicker } from './DatePicker'
import { Radio } from '@npm_leadtech/legal-lib-components/Radio'
import { RemoveButton } from '@npm_leadtech/legal-lib-components/RemoveButton'
import { SearchSelect } from '@npm_leadtech/legal-lib-components/SearchSelect'
import { TextArea } from '@npm_leadtech/legal-lib-components/TextArea'
import { TextInput } from './TextInput'

const FormComponents = {
  AddButton,
  RemoveButton,
  Radio,
  TextInput,
  SearchSelect,
  Check: Checkbox,
  DatePicker: DatePicker,
  TextArea
}

export default FormComponents
