import { CustomerCard, CustomerPayment } from '@legal/customer'
import { isCardExpired } from '@legal/creditCard'

export const hasPaymentFailed = (lastPaymentMessage: string): boolean => {
  return lastPaymentMessage === 'change_payment_card'
}

// If the last payment was made with an older card because our current one didn't work, return true.
export const oldCardWasUsedForPayment = (
  card: CustomerCard,
  lastPaymentMessage?: string,
  lastPayment?: CustomerPayment
): boolean => {
  if (!lastPayment) return false
  return lastPaymentMessage === 'payment_with_other_card' && card.createdAt < lastPayment.paidAt
}

export const isCardExpiredAndPaymentFailed = (card: CustomerCard, lastPaymentMessage: string): boolean => {
  return lastPaymentMessage === 'change_payment_card' && isCardExpired(card.expirationMonth, card.expirationYear)
}
