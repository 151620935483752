import React from 'react'

import phoneInfo from '../../../assets/images/svg/info_white_16px.svg'
import { useAllSiteMetadata } from '@legal/shared/hooks/useAllSiteMetadata'

export const ContactInfoContent = (): React.ReactElement => {
  const { sitePhone, siteScheduleMobile } = useAllSiteMetadata()

  return (
    <div className='contact-info-content'>
      <div className='contact-info-content__site-phone'>
        <img src={phoneInfo} alt='phone info' />
        {sitePhone}
      </div>
      <div className='contact-info-content__schedule-phone'>{siteScheduleMobile}</div>
    </div>
  )
}
