import { CustomerSubscription } from '../CustomerSubscription'
import { SubscriptionTypeName } from '@legal/shared/data'

export function getCustomerSubscriptionFilterBestValue(subscriptions: CustomerSubscription[]): CustomerSubscription[] {
  return subscriptions.filter(
    (subscription) =>
      subscription.type.name === SubscriptionTypeName.BEST_VALUE ||
      subscription.type.name === SubscriptionTypeName.ONE_YEAR_PRO_SUBSCRIPTION
  )
}
