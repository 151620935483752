import { logError, logInfo } from '@legal/shared/logger'
import { type CreateCustomerWithSubscriptionRequest } from './interfaces'
import { CustomerRepositoryApi } from '../infra'
import { UserCookie } from '../../../services/storage/cookies/UserCookie'
import { setAmplitudeUserId } from '@legal/shared/amplitude'

export async function createCustomerWithSubscriptionUseCase({
  successCallback,
  errorCallback,
  finallyCallback,
  request
}: {
  successCallback: (token: string) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorCallback?: (error: any) => void
  finallyCallback?: () => void
  request: CreateCustomerWithSubscriptionRequest
}): Promise<void> {
  const user = new UserCookie()
  if (user.token) {
    logError('CreateCustomerWithSubscription', { error: 'User already authenticated' })
    return
  }
  await CustomerRepositoryApi.createWithSubscription(request)
    .then((response) => {
      if (response.customerId) setAmplitudeUserId(response.customerId)
      logInfo('CreateCustomerWithSubscription')
      successCallback(response.token)
    })

    .catch((error: Error | undefined) => {
      logError('CreateCustomerWithSubscription', undefined, error)
      errorCallback?.(error)
    })
    .finally(() => {
      finallyCallback?.()
    })
}
