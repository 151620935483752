import { InfoBox } from '@npm_leadtech/legal-lib-components/InfoBox'
import React from 'react'
import { RichTextStrapi } from '@npm_leadtech/legal-lib-components/RichTextStrapi'

import { type PaymentErrorBoxProps } from './PaymentErrorBoxProps'

export const PaymentErrorBox: React.FC<PaymentErrorBoxProps> = ({ card, lastPaymentMessage, text }) => {
  if (card?.isExpired || lastPaymentMessage !== 'change_payment_card') return null

  return (
    <InfoBox type='error'>
      <RichTextStrapi html={text} />
    </InfoBox>
  )
}
