/* eslint-disable @typescript-eslint/strict-boolean-expressions */

/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { type GatsbyBrowser, Script } from 'gatsby'
import React from 'react'
import { datadogLogs } from '@datadog/browser-logs'

import { enableTestABCookie, setTestABCookie } from './src/services/utils/setTestABCookie'
import { CookieContextProvider } from './src/modules/shared/cookies/CookieContext'
import { SnackbarLayout } from './src/components/layout'
import { getConfigs } from './src/modules/core/configs'
import { getParamsUrl } from './src/services/utils/paramsUrl'
import { hotjarScript } from './src/services/utils/hotjar'
import { initAmplitude } from './src/modules/shared/amplitude'
import { setUtm } from './src/services/sem/setUtm'
import { useQueryChatContent } from '@legal/shared/data/graphql'
import { validateDateGmt } from './src/modules/shared/utils/validateDateGmt/validateDateGmt'

import version from './static/version.json'

const {
  DATADOG_CONFIG,
  FRESHCHAT_CONFIG,
  HOTJAR_ID,
  STRAPI_CONFIG: { LOCALE }
} = getConfigs()

interface Time {
  hour: number
  minute: number
}
interface ScheduleOfWeek {
  [dayOfWeek: string]: {
    from: Time
    to: Time
  }
}

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }): React.JSX.Element => (
  <CookieContextProvider>{element}</CookieContextProvider>
)

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element, props }) => {
  const { chatContent, businessHours } = useQueryChatContent()
  const SNACKBAR_PAGES = 'my-account'
  const LOADING_PAGE = 'loading-page'
  const snackbarLayoutOverflow = props.location.pathname.includes(SNACKBAR_PAGES) ? 'snackbar-layout-overflow' : ''

  const isLoadingPage = props.location.pathname.includes(LOADING_PAGE)

  const scheduleOfWeek: ScheduleOfWeek = (businessHours ?? []).reduce<ScheduleOfWeek>((acc, item) => {
    const dayOfWeek = item?.dayOfWeek ?? 'Monday'
    acc[dayOfWeek] = {
      from: {
        hour: item?.from?.hour ?? 0,
        minute: item?.from?.minute ?? 0
      },
      to: {
        hour: item?.to?.hour ?? 0,
        minute: item?.to?.minute ?? 0
      }
    }
    return acc
  }, {})

  return (
    <>
      <SnackbarLayout snackbarLayoutOverflow={snackbarLayoutOverflow} {...props}>
        {element}
      </SnackbarLayout>

      {FRESHCHAT_CONFIG.FRESHCHAT && !isLoadingPage && (
        <>
          <Script
            strategy='idle'
            id='fcPreChatForm'
            src='https://snippets.freshchat.com/js/fc-pre-chat-form-v2.min.js'
            onLoad={() => {
              window.preChatTemplate = {
                heading: chatContent?.heading,
                mainbgColor: '#078080',
                maintxColor: '#fff',
                textBanner: chatContent?.textBanner,
                SubmitLabel: chatContent?.submitLabel,
                fields: {
                  field1: {
                    error: chatContent?.inputName?.errorMessages?.error,
                    fieldId: 'name',
                    label: chatContent?.inputName?.label,
                    required: 'yes',
                    type: 'text'
                  },
                  field2: {
                    error: chatContent?.inputEmail?.errorMessages?.error,
                    fieldId: 'email',
                    label: chatContent?.inputEmail?.label,
                    required: 'yes',
                    type: 'email'
                  }
                }
              }
              window.fcSettings = {
                token: FRESHCHAT_CONFIG.TOKEN,
                host: FRESHCHAT_CONFIG.HOST,
                widgetUuid: FRESHCHAT_CONFIG.WIDGET_ID,
                config: {
                  content: {
                    actions: {
                      tab_chat: chatContent?.configActions
                    },
                    headers: {
                      chat: chatContent?.headersChat
                    }
                  },
                  cssNames: {
                    expanded: 'custom_fc_expanded',
                    widget: 'custom_fc_frame'
                  }
                },
                onInit: function () {
                  window.fcPreChatform.fcWidgetInit(window.preChatTemplate)
                }
              }
            }}
          />
          <Script
            strategy='idle'
            src='https://wchat.eu.freshchat.com/js/widget.js'
            onLoad={() => {
              window.fcWidget.on('widget:loaded', function (resp) {
                window.fcWidget.conversation.setConversationProperties({
                  cf_customer_url: window.location.href
                })
              })
              const businessInTime = validateDateGmt({ businessHoursConfig: scheduleOfWeek })
              if (!businessInTime) {
                document.body.classList.add('freshChat_hide')
              }
            }}
          />
        </>
      )}
    </>
  )
}

export const onClientEntry: GatsbyBrowser['onClientEntry'] = () => {
  enableTestABCookie('8814')
  setTestABCookie('19978')

  const itemsParamsUrl = getParamsUrl()
  itemsParamsUrl && setUtm({ itemsParamsUrl })
  initAmplitude()
  datadogLogs.init({
    clientToken: DATADOG_CONFIG.CLIENT_TOKEN,
    site: DATADOG_CONFIG.SITE,
    service: DATADOG_CONFIG.SERVICE,
    env: DATADOG_CONFIG.ENVIRONMENT,
    version: version.version,
    forwardErrorsToLogs: true,
    sampleRate: 10,
    trackInteractions: true,
    enabled: true
  })
  LOCALE === 'en-GB' && hotjarScript({ hotjarID: HOTJAR_ID })
}

/* Retrieve referrer URL in every Gatsby Page - using location prop */
export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = ({ location, prevLocation }) => {
  if (location) {
    const referrer = prevLocation ? prevLocation.pathname : null
    location = { ...location, state: { ...location.state, referrer } }
  }
}
