import React from 'react'

import phoneInfo from '../../../assets/images/svg/info_16px.svg'
import { useAllSiteMetadata } from '@legal/shared/hooks/useAllSiteMetadata'

export const TopBarMobileContent: React.FC = () => {
  const { sitePhone, siteScheduleMobile } = useAllSiteMetadata()
  return (
    <>
      <b>
        <a href={`tel:${sitePhone}`}>
          <img src={phoneInfo} alt='phone info' />
          {sitePhone} &emsp;
        </a>
      </b>
      {siteScheduleMobile}
    </>
  )
}
